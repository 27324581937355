<template>
	<div class="flex items-center ps-3">
		<template v-for="(page, i) in pages">
			<nuxt-link
				v-if="page.page"
				:key="'a' + i"
				class="flex items-center justify-center w-8 h-8 text-xs text-natural-dark rounded-xl me-3"
				:class="{
					'bg-black bg-opacity-5 font-bold pointer-events-none': page.page === current,
					'cursor-pointer bg-white': page.page !== current
				}"
				:to="createLink(page.page)"
			>
				{{ page.page }}
			</nuxt-link>
			<span v-else :key="'b' + i" class="flex items-center me-3">
				<span class="w-1 h-1 bg-black rounded-full me-1" />
				<span class="w-1 h-1 bg-black rounded-full me-1" />
				<span class="w-1 h-1 bg-black rounded-full" />
			</span>
		</template>
	</div>
</template>

<script setup lang="ts">
const route = useRoute()

const props = defineProps<{
	total: number
	limit: number
	current: number
	link: (page: number) => string
}>()

const max = computed(() => Math.ceil(props.total / props.limit))

const pages = computed(() => {
	const pages = Array(11)
		.fill("")
		.map((_, i) => ({
			page: (props.current + (i - 5)) as any
		}))
		.filter((v) => v.page > 0)
		.filter((v) => v.page <= max.value)
	if (!pages.length) return pages
	if (pages[0].page !== 1) pages[0].page = 1
	if (pages[pages.length - 1].page !== max.value) pages[pages.length - 1].page = max.value
	if (props.current < max.value - 4) pages[pages.length - 2].page = null
	if (props.current > 5) pages[1].page = null
	return pages
})

const createLink = (i: number) => ({
	path: props.link(i),
	query: route.query
})
</script>
