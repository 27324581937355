import { Buffer } from "buffer"

export default () => {
	const runtimeConfig = useRuntimeConfig()
	const app = useNuxtApp()

	const isEnabled = useState<boolean>("text-to-speech-enabled")
	const audioContext = useState<AudioContext>("text-to-speech-audio-context")
	const audioSource = useState<AudioBufferSourceNode | undefined>("text-to-speech-audio-source")
	const abortController = useState<AbortController | null>("text-to-speech-abort-controller")

	const init = () => {
		if (!audioContext.value) {
			audioContext.value = new AudioContext()
			audioContext.value.resume()
		}
	}

	const say = async (text: string) => {
		try {
			init()
			shutUp()
			abortController.value = new AbortController()
			isEnabled.value = true
			// if (!isEnabled.value) {
			// 	await new Promise((resolve) => {
			// 		window.responsiveVoice?.speak(" ", null, {
			// 			volume: 0,
			// 			onend: () => {
			// 				isEnabled.value = true
			// 				resolve(true)
			// 			}
			// 		})
			// 		if (!window.responsiveVoice?.speak) {
			// 			return resolve(false)
			// 		}
			// 	})
			// }
			const r = await $fetch<any>(app.$config.public.baseUrl + "/tools/tts", {
				signal: abortController.value?.signal,
				method: "POST",
				body: {
					text,
					language_code: app.$i18n.localeProperties.value.google_code
				}
			})
			let data = Buffer.from(r, "binary")
			const audioBuffer = data.buffer.slice(data.byteOffset, data.byteLength + data.byteOffset)
			const audio: AudioBuffer = await new Promise((resolve, reject) => {
				audioContext.value.decodeAudioData(
					audioBuffer,
					(buffer) => {
						resolve(buffer)
					},
					(e) => {
						reject(e)
					}
				)
			})
			audioSource.value = audioContext.value.createBufferSource()
			audioSource.value.buffer = audio
			audioSource.value.connect(audioContext.value.destination)
			audioSource.value.start(0)
			await audioContext.value.resume()
			await new Promise((resolve, reject) => {
				setTimeout(resolve, audio.duration * 1000)
				abortController.value?.signal?.addEventListener("abort", () =>
					reject({
						message: "The user aborted a request"
					})
				)
			})
			abortController.value = null
		} catch (e: any) {
			console.error(e)
			throw e
		}
	}

	const shutUp = () => {
		window.responsiveVoice?.cancel()
		abortController.value?.abort()
		abortController.value = null
		audioSource.value?.stop(0)
	}

	const captureUserInteraction = async () => {
		init()
		window.responsiveVoice?.enableWindowClickHook()
		const types = ["click", "mouseup", "mousedown"]
		types.forEach((type) => {
			window.addEventListener(
				type,
				() => {
					audioContext.value.resume()
				},
				{ once: true }
			)
		})
	}

	if (process.client) {
		// @ts-ignore
		window.say = say
	}

	return {
		say,
		captureUserInteraction,
		shutUp
	}
}
