<template>
	<AdminContainer selected="manage-test-requests" :loading="state.loading" :fetched="true" @tryAgain="fetchData">
		<div class="flex flex-col mx-8 my-6">
			<div class="flex items-center mb-6">
				<span class="text-xl font-bold text-natural-dark me-4">
					{{ $t("test_requests") }}
				</span>
			</div>
			<div class="flex items-center mt-4">
				<div class="flex items-center self-start px-3 py-1 bg-white border border-gray-200 rounded-md">
					<span class="text-xs border-gray-200 text-natural-dark pe-3 me-3 border-e">
						{{ $t("status") }}
					</span>
					<div class="flex flex-wrap items-center gap-2">
						<span
							v-for="(s, i) in statuses"
							:key="i"
							class="px-2 py-1 text-xs duration-300 border rounded-md cursor-pointer whitespace-nowrap"
							:class="{
								'border-opacity-100 bg-opacity-10': s.value === status.value,
								[status.borderClass]: s.value === status.value,
								[status.bgClass]: s.value === status.value,
								[status.txtClass]: s.value === status.value,
								'bg-opacity-0 border-opacity-0 text-natural-semidark ': s.value !== status.value
							}"
							@click="changeStatus(s.value)"
						>
							{{ s.name }}
						</span>
					</div>
				</div>
				<div class="flex-grow" />
			</div>
			<div class="flex flex-col gap-8 my-8">
				<TestOrderAdminCard
					v-for="order in state.orders"
					:key="order.id"
					:data="order"
					:detailed="state.targetOrder?.id === order.id"
					:admin="true"
					@status="openChangeStatusDialog(order)"
					@show-details="
						() =>
							order.type == 'genome' ? showGenomeOrderDetails(order) : showMetagenomeOrderDetails(order)
					"
					@edit-details="
						() =>
							order.type == 'genome'
								? openEditGenomeOrderDetails(order)
								: openEditMetagenomeOrderDetails(order)
					"
				/>
				<div
					v-if="!state.orders?.length"
					class="py-24 text-4xl font-black text-center text-gray-300 select-none"
				>
					{{ $t("nothing_found") }}
				</div>
			</div>
			<BasePagination
				:total="state.total"
				:limit="state.limit"
				:current="page"
				:link="$routeUrl.ManageShopOrdersPageUrl"
				class="self-center mt-4 mb-12"
			/>
			<TestOrderChangeStatusDialog
				:visible="state.isChangeStatusDialogVisible"
				:data.sync="state.targetOrder"
				@close="state.isChangeStatusDialogVisible = false"
				@select="fetchData"
			/>
			<TestShowGenomeDialog
				:visible="state.isGenomeDetailsDialogVisible"
				:order="state.targetOrder"
				@close="state.isGenomeDetailsDialogVisible = false"
			/>
			<TestEditGenomeDialog
				:visible="state.isEditGenomeDetailsDialogVisible"
				:order="state.targetOrder"
				@close="state.isEditGenomeDetailsDialogVisible = false"
				@done="
					(v) => {
						state.orders = state.orders.map((o) => (o.id == v.id ? v : o))
						state.isEditGenomeDetailsDialogVisible = false
					}
				"
			/>
			<TestShowMetagenomeDialog
				:visible="state.isMetagenomeDetailsDialogVisible"
				:order="state.targetOrder"
				@close="state.isMetagenomeDetailsDialogVisible = false"
			/>
			<TestEditMetagenomeDialog
				:visible="state.isEditMetagenomeDetailsDialogVisible"
				:order="state.targetOrder"
				@close="state.isEditMetagenomeDetailsDialogVisible = false"
				@done="
					(v) => {
						state.orders = state.orders.map((o) => (o.id == v.id ? v : o))
						state.isEditMetagenomeDetailsDialogVisible = false
					}
				"
			/>
		</div>
	</AdminContainer>
</template>

<script setup lang="ts">
import type { GenomeTestOrder } from "~/models/genome-test-order"
import { type ShopOrder } from "~/models/shop-order"

const genomeTestOrderStore = useGenomeTestOrderStore()
const route = useRoute()
const app = useNuxtApp()

definePageMeta({
	middleware: ["admin-auth"]
})

const state = reactive({
	loading: true,
	fetched: false,
	orders: [] as GenomeTestOrder[],
	total: 0,
	targetOrder: {} as GenomeTestOrder,
	isEditDialogVisible: false,
	isChangeStatusDialogVisible: false,
	isOrderImageDialogVisible: false,
	searchText: String(route.query.searchText || ""),
	limit: 15,
	isGenomeDetailsDialogVisible: false,
	isEditGenomeDetailsDialogVisible: false,
	isMetagenomeDetailsDialogVisible: false,
	isEditMetagenomeDetailsDialogVisible: false
})

watch(
	() => route.query,
	() => fetchData()
)

const page = computed(() => Math.max(Number(route.params.page) || 1, 1) || 1)

const statuses = computed(() => genomeTestOrderStore.statuses)

const qSearchText = computed(() => (route.query.searchText || "") as string)

const status = computed(() => statuses.value.find((v) => v.value == route.query.status) || statuses.value[0])

const fetchData = async () => {
	state.loading = true
	state.fetched = false
	try {
		const orders = await genomeTestOrderStore.getOrders({
			status: status.value.value as any
		})
		state.orders = orders
		state.fetched = true
	} catch (e) {
		app.$toastError(e)
	}
	state.loading = false
}

const search = () => {
	if (state.searchText != qSearchText.value || page.value != 1) {
		state.loading = true
		app.$router.replace({
			path: app.$routeUrl.ManageTestRequestsPageUrl(1),
			query: {
				searchText: state.searchText
			}
		})
	} else {
		fetchData()
	}
}

const openChangeStatusDialog = (order: GenomeTestOrder) => {
	state.targetOrder = JSON.parse(JSON.stringify(order))
	state.isChangeStatusDialogVisible = true
}

const changeStatus = (v: any) => {
	if (status.value.value == v) return
	app.$router.replace({
		path: app.$routeUrl.ManageTestRequestsPageUrl(1),
		query: {
			...route.query,
			status: v
		}
	})
}

const showGenomeOrderDetails = (order: GenomeTestOrder) => {
	state.targetOrder = JSON.parse(JSON.stringify(order))
	state.isGenomeDetailsDialogVisible = true
}

const openEditGenomeOrderDetails = (order: GenomeTestOrder) => {
	state.targetOrder = JSON.parse(JSON.stringify(order))
	state.isEditGenomeDetailsDialogVisible = true
}

const showMetagenomeOrderDetails = (order: GenomeTestOrder) => {
	state.targetOrder = JSON.parse(JSON.stringify(order))
	state.isMetagenomeDetailsDialogVisible = true
}

const openEditMetagenomeOrderDetails = (order: GenomeTestOrder) => {
	state.targetOrder = JSON.parse(JSON.stringify(order))
	state.isEditMetagenomeDetailsDialogVisible = true
}

onMounted(() => {
	fetchData()
})
</script>
