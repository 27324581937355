import { type Exercise } from "./../models/exercise"
import { defineStore } from "pinia"
import { type ScheduleDay } from "~/models/schedule-day"
import samples from "~/assets/samples"
import personSamples from "~/assets/person-samples"
import { type Database } from "~/types/supabase"
import { type TrackerExercise } from "~/models/tracker-exercise"

export const useExerciseStore = defineStore("exercise", () => {
	const questions = useQuestions()
	const infoStore = useInfoStore()
	const sampleStore = useSampleStore()
	const supabase = useSupabaseClient<Database>()
	const user = useSupabaseUser()
	const app = useNuxtApp()
	const openai = useOpenAI()

	const state = {
		schedule: ref<ScheduleDay[] | null>(),
		userComments: ref<string[] | null>(),
		agentComments: ref<string[] | null>(),
		dbRowId: ref<string | null>()
	}

	const info = computed(() => infoStore.info)
	const selectedSample = computed(() => samples.find((v) => v.sample_id === sampleStore.sampleId) || samples[0])
	const selectedPerson = computed(
		() => personSamples.find((v) => v.sample_id === sampleStore.personSampleId) || personSamples[0]
	)

	const selectedSamplePromptPart = computed(() =>
		selectedSample.value
			? Object.entries(selectedSample.value?.taxonomic_levels)
					.map(([name, levels]) => {
						let prompt = "Taxonomic Level		Percentage\n"
						prompt += "-----------------------------\n"
						prompt += name + "\n"
						prompt += Object.entries(levels)
							.map(([level, percent]: any) => `- ${level}		${percent.percent}`)
							.join("\n")
						return prompt
					})
					.join("\n\n")
			: null
	)

	const getSchedule = async () => {
		const schedule = (
			await supabase
				.from("user_exercises")
				.select()
				.eq("user_id", user.value?.id || "")
		).data?.[0]
		state.dbRowId.value = schedule?.id || null
		if (validateScheduleFormat(schedule?.schedule))
			state.schedule.value = fixScheduleFormat(schedule?.schedule || []) as ScheduleDay[]
		else state.schedule.value = []
		const agentComments = (schedule?.agent_comments || []) as string[]
		const userComments = (schedule?.user_comments || []) as string[]
		state.agentComments.value = agentComments
		state.userComments.value =
			userComments.length > agentComments.length ? userComments.slice(0, -1) : userComments
	}

	const fetchSchedule = async () => {
		if (state.schedule.value || !user.value?.id) return null
		return await getSchedule()
	}

	const fixScheduleFormat = (data: any) => {
		data = data.days || data
		if (data.schedule) {
			data = data.schedule
		}
		data = data.map((d: any) => (typeof d.day == "object" ? d.day || d : d))
		data = data.map((d: any) => ({
			...d,
			name: d.name || d.day
		}))
		return data
	}

	const validateScheduleFormat = (data: any) => {
		if (!Array.isArray(data)) {
			return false
		}
		if (data.some((v1) => typeof v1 != "object")) return false
		if (data.some((v1) => !v1.name)) return false
		if (data.some((v1) => !Array.isArray(v1.exercises))) return false
		if (data.some((v1) => v1.exercises.some((v2: any) => typeof v2 != "object"))) return false
		if (data.some((v1) => v1.exercises.some((v2: any) => !v2.name))) return false
		if (data.some((v1) => v1.exercises.some((v2: any) => !v2.description))) return false
		if (data.some((v1) => v1.exercises.some((v2: any) => !v2.time))) return false
		if (data.some((v1) => v1.exercises.some((v2: any) => !v2.duration))) return false
		if (
			data.some((v1) =>
				v1.exercises.some(
					(v2: any) =>
						!v2.counts || !Array.isArray(v2.counts) || v2.counts.some((d: any) => typeof d != "number")
				)
			)
		) {
			return false
		}
		return true
	}

	const generateSchedule = async (signal?: AbortSignal) => {
		const days = info.value?.["workout-days"] || []
		const newSchedule: ScheduleDay[] = await openai.chat({
			prompts: [
				{
					role: "system",
					content: [
						[
							"You are a workout planner. you will generate a weekly workout schedule for a person",
							"Output is in JSON format. an array of days",
							"Minify and compress output to reduce output length",
							`Workout schedule is in ${app.$i18n.localeProperties.value.name} English language`
						].join("\n"),
						["Output Object", "- fields: days", "- days is an array of Day"].join("\n"),
						[
							"Day Object:",
							"- fields: name, exercises",
							"- name is the name of the day in the week start of Saturday",
							"- exercises is an array of Exercise"
						].join(""),
						[
							"Exercise Object:",
							"- fields: name, description, time, duration, counts",
							"- name is the name of the exercise",
							"- description is some explanations about the exercise",
							"- time is the exercise start time in the day (example 00:00 AM)",
							"- counts is an array of number represents reps of the exercise in every set",
							"- duration is the exercise length in minutes"
						].join("\n")
					]
						.filter((v) => v)
						.join("\n\n")
				},
				{
					role: "user",
					content: [
						"generate my workout schedule",
						"The schedule shouldn't contain yoga or meditation exercises",
						days.length &&
							`The schedule should be in ${days.join(" and ").trim()}. other days are for rest`,
						sampleStore.selectedPerson?.description &&
							["My Profile:", "- " + sampleStore.selectedPerson?.description].filter((v) => v).join("\n"),
						infoStore.questionsPromptPart && ["My Info:", infoStore.questionsPromptPart].join("\n"),
						sampleStore.selectedSamplePromptPart &&
							["My Qiime2 Analysis (Taxonomic Level):", sampleStore.selectedSamplePromptPart].join("\n"),
						state.userComments.value?.length &&
							["My Comments About The Schedule:", ...state.userComments.value?.map((v) => "- " + v)].join(
								"\n"
							)
					]
						.filter((v) => v)
						.join("\n\n")
				}
			],
			temperature: 1,
			response_format: "json_object",
			model: "gpt-4",
			transform: fixScheduleFormat,
			validate: validateScheduleFormat,
			signal
		})
		await updateSchedule({
			schedule: newSchedule
		})
	}

	const addComment = async (comment: string, signal?: AbortSignal) => {
		try {
			const userComments = [...(state.userComments.value || []), comment]
			state.userComments.value = userComments
			await updateSchedule({
				user_comments: userComments
			})
			const days = info.value?.["workout-days"] || []
			const newSchedule: ScheduleDay[] = await openai.chat({
				prompts: [
					{
						role: "system",
						content: [
							[
								"You are a workout planner. you have to update a weekly workout schedule based on user last comment",
								"output is in JSON format. an array of days",
								"Minify and compress output to reduce output length",
								`Workout schedule is in ${app.$i18n.localeProperties.value.name} language`
							].join("\n"),
							["Output Object", "- fields: days", "- days is an array of Day"].join("\n"),
							[
								"Day Object:",
								"- fields: name, exercises",
								"- name is the name of the day in the week start of Saturday",
								"- exercises is an array of Exercise"
							].join(""),
							[
								"Exercise Object:",
								"- fields: name, description, time, duration, counts",
								"- name is the name of the exercise",
								"- description is some explanations about the exercise",
								"- time is the exercise start time in the day (example 00:00 AM)",
								"- counts is an array of number represents reps of the exercise in every set",
								"- duration is the exercise length in minutes"
							].join("\n")
						]
							.filter((v) => v)
							.join("\n\n")
					},
					{
						role: "user",
						content: [
							"update my workout schedule",
							"Base Workout Schedule (in json):",
							JSON.stringify(state.schedule.value),
							"The schedule shouldn't contain yoga or meditation exercises",
							days.length &&
								`The schedule should be in ${days.join(" and ").trim()}. other days are for rest`,
							sampleStore.selectedPerson?.description &&
								["My Profile:", "- " + sampleStore.selectedPerson?.description]
									.filter((v) => v)
									.join("\n"),
							infoStore.questionsPromptPart && ["My Info:", infoStore.questionsPromptPart].join("\n"),
							sampleStore.selectedSamplePromptPart &&
								["My Qiime2 Analysis (Taxonomic Level):", sampleStore.selectedSamplePromptPart].join(
									"\n"
								),
							userComments.length &&
								["My Comments About The Schedule:", ...userComments.map((v) => "- " + v)].join("\n")
						]
							.filter((v) => v)
							.join("\n\n")
					}
				],
				temperature: 1,
				response_format: "json_object",
				model: "gpt-4",
				transform: fixScheduleFormat,
				validate: validateScheduleFormat,
				signal
			})
			const oldSchedule: ScheduleDay[] = JSON.parse(JSON.stringify(state.schedule.value))
			const message = await openai.chat({
				prompts: [
					{
						role: "system",
						content: [
							"there is an old exercise schedule. user comment on it and a planner updated the schedule to new schedule",
							"you have to wright a short answer from the planner to user about what is changed",
							"output the answer without any title",
							"don't write more than 16 words.",
							`message is in ${app.$i18n.localeProperties.value.name} language`
						]
							.filter((v) => v)
							.join("\n\n")
					},
					{
						role: "user",
						content: [
							["My Comment:", userComments[userComments.length - 1]].join("\n"),
							["Old Exercise Schedule (in json):", JSON.stringify(oldSchedule)].join("\n"),
							["New Exercise Schedule (in json):", JSON.stringify(newSchedule)].join("\n")
						]
							.filter((v) => v)
							.join("\n\n")
					}
				],
				temperature: 1,
				response_format: "text",
				model: "gpt-4",
				signal
			})
			await updateSchedule({
				schedule: newSchedule,
				agent_comments: [...(state.agentComments.value || []), message]
			})
		} catch (e) {
			if ((state.userComments.value?.length || 0) > (state.agentComments.value?.length || 0)) {
				state.userComments.value = state.userComments.value?.slice(0, -1) || []
			}
			throw e
		}
	}

	const updateSchedule = async (data: {
		schedule?: ScheduleDay[]
		user_comments?: string[]
		agent_comments?: string[]
	}) => {
		if (state.dbRowId.value) {
			await supabase
				.from("user_exercises")
				.update(Object.fromEntries(Object.entries(data).filter((v) => v[1] !== undefined)))
				.eq("user_id", user.value?.id || "")
		} else {
			await supabase.from("user_exercises").insert({
				user_id: user.value?.id!,
				...Object.fromEntries(Object.entries(data).filter((v) => v[1] !== undefined))
			})
		}
		await getSchedule()
	}

	const getTrackerExercises = async (date: string) => {
		return (
			await supabase
				.from("user_tracker_exercises")
				.select()
				.eq("user_id", user.value?.id || "")
				.eq("completed_at", date)
				.eq("type", "physical")
		).data as TrackerExercise[]
	}

	const updateTrackerExercise = async (trackerExercise: TrackerExercise) => {
		if (trackerExercise.id) {
			const { error } = await supabase
				.from("user_tracker_exercises")
				.update({
					completed_at: trackerExercise.completed_at,
					is_completed: trackerExercise.is_completed
				})
				.eq("id", trackerExercise.id)
			if (error) throw error
		} else {
			const { error } = await supabase.from("user_tracker_exercises").insert({
				user_id: trackerExercise.user_id,
				type: trackerExercise.type,
				exercise: trackerExercise.exercise,
				is_completed: trackerExercise.is_completed,
				completed_at: trackerExercise.completed_at
			})
			if (error) throw error
		}
	}

	return {
		...state,
		generateSchedule,
		addComment,
		fetchSchedule,
		updateSchedule,
		getTrackerExercises,
		updateTrackerExercise
	}
})
