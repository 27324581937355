export default [
	{
		sample_id: "S1",
		description: `This individual has a genetic marker associated with lactose intolerance, specifically a variation in the LCT gene that affects lactase production. As a result, they may experience digestive issues when consuming dairy products.`,
		candidate_variants: ["LCT-13910T/C"]
	},
	{
		sample_id: "S2",
		description: `This person has a genetic marker linked to a higher risk of cardiovascular disease. It involves a variation in the APOE gene that is associated with elevated levels of LDL cholesterol, commonly known as "bad" cholesterol.`,
		candidate_variants: ["APOE4", "APOE3"]
	},
	{
		sample_id: "S3",
		description: `This individual carries a genetic marker associated with increased metabolism of caffeine. They have a specific variation in the CYP1A2 gene, which means they may metabolize caffeine more rapidly than average.`,
		candidate_variants: ["rs762551"]
	},
	{
		sample_id: "S4",
		description: `This person has a genetic marker related to gluten sensitivity or celiac disease. It involves variations in the HLA-DQ genes, which are associated with an increased risk of developing an autoimmune response to gluten.`,
		candidate_variants: ["HLA-DQ2.5", "HLA-DQ8"]
	},
	{
		sample_id: "S5",
		description: `This individual carries a genetic marker associated with obesity. They have a variation in the FTO gene, which has been linked to a higher predisposition for weight gain and increased body mass index.`,
		candidate_variants: ["rs9939609", "rs17817449", "rs1421085", "rs1121980", "rs3751812"]
	}
]
