export default defineNuxtRouteMiddleware(async (to, from) => {
	const app = useNuxtApp()
	const userStore = useUserStore()
	const infoStore = useInfoStore()
	const dietStore = useDietStore()
	const exerciseStore = useExerciseStore()
	const meditationStore = useMeditationStore()
	const userEventStore = useUserEventStore()
	const genomeTestOrderStore = useGenomeTestOrderStore()
	const runtimeConfig = useRuntimeConfig()
	const cookieLanguage = app.$i18n.getLocaleCookie()
	const supabase = useSupabaseClient()

	const { error } = await supabase?.auth.getSession()
	if (error) return createError(error)
	const user = useSupabaseUser().value

	try {
		if (!cookieLanguage) {
			let detectedLanguage = (await $fetch<any>(runtimeConfig.public.baseUrl + "/languages/detect")) || "en"
			if (app.$i18n.localeCodes.value.includes(detectedLanguage)) {
				app.$i18n.setLocaleCookie(detectedLanguage)
			}else {
				app.$i18n.setLocaleCookie('en')
			}
		}
		if (user) {
			await userStore.getProfile()
			await Promise.all([
				infoStore.fetchInfo(),
				dietStore.fetchDiet(),
				exerciseStore.fetchSchedule(),
				meditationStore.fetchSchedule(),
				userEventStore.fetchEvents(),
				genomeTestOrderStore.fetchOrders()
			])
		}
	} catch (e: any) {
		if (e.status == 401) {
			await supabase.auth.signOut({
				scope: "local"
			})
		} else {
			const simplifiedError = app.$simplifyError(e)
			return createError({
				message: simplifiedError.message
			})
		}
	}
})
