import { type ChatCompletionRequestMessage } from "openai"

export default () => {
	const app = useNuxtApp()

	const convertToJSON = (text: string) => {
		return JSON.parse(
			text
				.replace(/^[^\[\{]*/gs, "")
				.replace(/[^\]\}]*$/gs, "")
				.trim()
		)
	}

	const chat = async (options: {
		prompts: ChatCompletionRequestMessage[]
		max_tokens?: number
		temperature?: number
		response_format?: "text" | "json_object"
		model?: "gpt-4" | "gpt-3.5-turbo-16k"
		signal?: AbortSignal
		transform?: (v: any) => any
		validate?: (v: any) => boolean
	}) => {
		console.log(
			"Prompt:\n",
			options.prompts.map((message) => [message.role + ":", message.content].join("\n")).join("\n\n\n")
		)
		let data = null
		try {
			const r = await $fetch<any>(app.$config.public.baseUrl + "/tools/chat", {
				method: "POST",
				signal: options.signal,
				body: {
					prompts: options.prompts,
					max_tokens: options.max_tokens,
					temperature: options.temperature,
					model: options.model
				}
			})
			data = r.data
			if (options.response_format == "json_object") {
				data = convertToJSON(r.data)
			} else {
				data = r.data.trim()
			}
			if (options.transform) {
				data = options.transform?.(data)
			}
			if (options.validate && !options.validate?.(data)) {
				throw {
					message: "Wrong Format"
				}
			}
			console.log("Chat Response:\n", data)
			return data
		} catch (e) {
			console.log("Raw Chat Response:\n", data)
			throw e
		}
	}

	return {
		convertToJSON,
		chat
	}
}
