import revive_payload_client_4sVQNw7RlN from "/home/cgdev/vivai-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/cgdev/vivai-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/cgdev/vivai-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_I4Hv4qpJHd from "/home/cgdev/vivai-web/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/home/cgdev/vivai-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/cgdev/vivai-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/cgdev/vivai-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/cgdev/vivai-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/cgdev/vivai-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/home/cgdev/vivai-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/cgdev/vivai-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import auth_redirect_hxxEaFfrIx from "/home/cgdev/vivai-web/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import pwa_icons_Y6agwlnXZq from "/home/cgdev/vivai-web/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/home/cgdev/vivai-web/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/cgdev/vivai-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import day_u06Biro2xh from "/home/cgdev/vivai-web/plugins/day.ts";
import auto_animate_uTpJuTGsMC from "/home/cgdev/vivai-web/plugins/auto-animate.ts";
import dragscroll_U3dCpB8oXl from "/home/cgdev/vivai-web/plugins/dragscroll.ts";
import toast_ysMjUcU7eJ from "/home/cgdev/vivai-web/plugins/toast.ts";
import global_2XP5sjpyNs from "/home/cgdev/vivai-web/plugins/global.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  auth_redirect_hxxEaFfrIx,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  chunk_reload_client_UciE0i6zes,
  day_u06Biro2xh,
  auto_animate_uTpJuTGsMC,
  dragscroll_U3dCpB8oXl,
  toast_ysMjUcU7eJ,
  global_2XP5sjpyNs
]