<template>
	<div class="relative flex flex-col flex-grow lg:overflow-x-hidden">
		<div class="sticky top-0 z-20 flex items-center flex-shrink-0 h-16 shadow-lg hidden-print bg-slate-500">
			<div class="items-center self-stretch hidden w-64 px-6 lg:flex">
				<NuxtLink :to="$routeUrl.HomeUrl()" class="h-10">
					<img src="/img/app-logo.png" class="w-auto h-10 text-white" alt="" />
				</NuxtLink>
			</div>
			<div class="flex items-center self-stretch flex-grow bg-white bg-opacity-10">
				<div
					class="flex items-center justify-center h-16 px-6 duration-300 cursor-pointer lg:hidden"
					:class="state.isMenuOpen ? 'bg-black' : ''"
					@click="state.isMenuOpen = !state.isMenuOpen"
				>
					<div class="flex flex-col items-center justify-center w-auto h-16 duration-300 transform scale-75">
						<span
							class="w-10 h-1 mb-3 duration-300 transform bg-white rounded"
							:class="state.isMenuOpen ? 'rotate-45 translate-y-4' : ''"
						/>
						<span
							class="w-10 h-1 mb-3 duration-300 transform bg-white rounded"
							:class="state.isMenuOpen ? 'opacity-0' : 'opacity-100'"
						/>
						<span
							class="w-10 h-1 duration-300 transform bg-white rounded"
							:class="state.isMenuOpen ? '-rotate-45 -translate-y-4' : ''"
						/>
					</div>
				</div>
				<span class="text-lg font-bold text-white ms-6">
					<span>
						{{ $t("management") }}
					</span>
					<span class="text-xs text-slate-500 bg-white bg-opacity-90 rounded-md px-2 py-0.5 ms-2">
						{{ userTypeName }}
					</span>
				</span>
				<div class="flex-grow" />
				<Icon
					name="solar:logout-2-bold-duotone"
					class="w-8 h-8 text-3xl text-white duration-300 transform cursor-pointer me-6 hover:scale-90"
					@click="logout"
				/>
			</div>
		</div>
		<div class="relative flex flex-row flex-grow flex-shrink-0">
			<div
				class="fixed flex-shrink-0 w-64 top-safe bottom-safe overflow-y-auto duration-300 transform bg-gray-200 shadow-xl z-[12] hidden-print lg:shadow-lg lg:relative lg:h-auto overscroll-contain"
				:class="
					state.isMenuOpen
						? 'translate-x-0 opacity-100'
						: '-translate-x-64 opacity-0 lg:translate-x-0 lg:opacity-100'
				"
			>
				<div class="flex flex-col py-5">
					<NuxtLink
						v-for="(item, i) in menuItems"
						:key="i"
						:to="item.to"
						class="flex items-center px-6 py-5 duration-300"
						:class="{
							'bg-slate-500 bg-opacity-10 text-slate-500 font-medium pointer-events-none':
								selected === item.id,
							'text-natural-semidark hover:bg-opacity-10 hover:bg-gray-400 hover:text-natural-dark':
								selected !== item.id
						}"
					>
						<Icon :name="item.icon" class="w-6 h-6 me-6" />
						<span class="text-sm">{{ item.title }}</span>
						<div class="flex-grow" />
						<span v-if="item.yellow" class="w-3 h-3 bg-yellow-500 rounded-full shadow-md animate-bounce" />
					</NuxtLink>
				</div>
			</div>
			<div class="relative flex flex-col flex-grow overflow-x-hidden bg-gray-50">
				<slot v-if="state.preFetched && fetched"></slot>
				<div
					v-if="state.preFetched ? loading : state.preLoading"
					class="fixed top-safe bottom-safe left-safe right-safe z-10 flex items-center justify-center bg-white bg-opacity-40"
				>
					<span class="w-12 h-12 rounded-full bg-slate-500 animate-ping" />
				</div>
				<div
					v-else-if="state.preFetched ? !fetched : !state.preFetched"
					class="fixed top-safe bottom-safe left-safe right-safe z-10 flex items-center justify-center bg-white bg-opacity-40"
				>
					<span
						class="px-6 py-3 font-bold text-white rounded-lg shadow-lg cursor-pointer ripple-bg-yellow-500"
						@click="state.preFetched ? $emit('tryAgain') : preFetch()"
					>
						{{ $t("try_again") }}
					</span>
				</div>
			</div>
		</div>
		<div
			v-if="state.isMenuOpen"
			class="absolute top-0 bottom-0 left-0 right-0 bg-black hidden-print bg-opacity-30 z-[11]"
			@click="state.isMenuOpen = false"
			@wheel.prevent
			@touchmove.prevent
			@scroll.prevent
		/>
	</div>
</template>

<script setup lang="ts">
import { type AdminMenuItemIds } from "~/stores/panel"

const userStore = useUserStore()
const panelStore = usePanelStore()
const app = useNuxtApp()
const supabase = useSupabaseClient()
const user = useSupabaseUser()

const props = defineProps<{
	selected?: AdminMenuItemIds
	loading?: boolean
	fetched?: boolean
	error?: Error | null
}>()

const state = reactive({
	isMenuOpen: false,
	preLoading: true,
	preFetched: false
})

const menuItems = computed(() =>
	panelStore.adminMenuItems.filter((item) => item.roles.includes(userStore.profile?.type || ""))
)

const selectedMenuItem = computed(() => panelStore.adminMenuItems.find((v) => v.id === props.selected)!!)

const userTypeName = computed(() => {
	return app.$i18n.t("admin")
})

const logout = async () => {
	await supabase.auth.signOut()
	location.reload()
}

const preFetch = async () => {
	state.preFetched = false
	state.preLoading = true
	try {
		state.preFetched = true
	} catch (e: any) {
		app.$toastError(e)
	}
	state.preLoading = false
}

watch(
	() => props.error,
	() => props.error && app.$toastError(props.error)
)

onMounted(() => {
	props.error && app.$toastError(props.error)
	preFetch()
})

useHead({
	title: selectedMenuItem.value.title + " | " + app.$i18n.t("manager_panel")
})
</script>
