<template>
	<TransitionRoot :show="visible" as="template">
		<Dialog as="div" @close="close" class="relative z-50">
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed top-safe bottom-safe bg-black bg-opacity-25 left-safe right-safe" />
			</TransitionChild>
			<div class="fixed top-safe bottom-safe left-safe right-safe">
				<div :class="['flex items-center justify-center h-full text-center', pageMode ? 'p-0 lg:p-4' : 'p-4']">
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<DialogPanel
							:class="[
								'flex flex-col w-full max-h-full overflow-hidden text-start align-middle transition-all transform bg-white shadow-xl',
								pageMode ? 'rounded-none lg:rounded-2xl' : 'rounded-2xl',
								{
									[pageMode ? 'lg:max-w-sm' : 'max-w-sm']: size === 'sm',
									[pageMode ? 'lg:max-w-md' : 'max-w-md']: size === 'md',
									[pageMode ? 'lg:max-w-lg' : 'max-w-lg']: size === 'lg',
									[pageMode ? 'lg:max-w-xl' : 'max-w-xl']: size === 'xl',
									[pageMode ? 'lg:max-w-2xl' : 'max-w-2xl']: size === '2xl',
									[pageMode ? 'lg:max-w-3xl' : 'max-w-3xl']: size === '3xl',
									[pageMode ? 'lg:max-w-4xl' : 'max-w-4xl']: size === '4xl',
									[pageMode ? 'lg:max-w-5xl' : 'max-w-5xl']: size === '5xl',
									[pageMode ? 'lg:max-w-5xl' : 'max-w-6xl']: size === '6xl',
									[pageMode ? 'lg:max-w-5xl' : 'max-w-7xl']: size === '7xl'
								},
								pageMode
									? 'max-w-full'
									: {
											'max-w-sm': size === 'sm',
											'max-w-md': size === 'md',
											'max-w-lg': size === 'lg',
											'max-w-xl': size === 'xl',
											'max-w-2xl': size === '2xl',
											'max-w-3xl': size === '3xl',
											'max-w-4xl': size === '4xl',
											'max-w-5xl': size === '5xl',
											'max-w-6xl': size === '6xl',
											'max-w-7xl': size === '7xl',
											'w-full': size === 'full'
									  }
							]"
						>
							<DialogTitle
								v-if="!hideHeader"
								as="div"
								class="sticky top-0 flex justify-between flex-shrink-0 border-b border-gray-200"
							>
								<h4 class="m-5 my-4 text-lg font-bold fons text-natural-dark">
									{{ title }}
								</h4>
								<div class="flex">
									<div
										class="flex items-center justify-center p-3 border-gray-200 cursor-pointer border-s ripple-bg-white rounded-te-2xl"
										@click="close"
										:aria-label="$t('close_modal')"
									>
										<BaseIcon name="close" class="w-8 h-8" />
									</div>
								</div>
							</DialogTitle>
							<div class="flex-grow overflow-auto z-[0]">
								<slot></slot>
							</div>
							<slot name="footer" v-if="!hideFooter" class="sticky bottom-0 flex-shrink-0">
								<div class="relative flex">
									<div class="absolute top-0 w-full h-px -mt-px bg-gray-200 start-0 end-0" />
									<div class="flex-grow" />
									<button
										class="px-8 py-3 m-4 font-bold text-white ripple-bg-primary rounded-xl"
										@click="click"
									>
										{{ buttonName }}
									</button>
								</div>
							</slot>
							<div
								v-if="loading"
								class="absolute top-0 bottom-0 flex items-center justify-center bg-white bg-opacity-50 start-0 end-0"
							>
								<div class="w-8 h-8 rounded-full bg-primary animate-ping" />
							</div>
							<div
								v-if="!fetched && !loading"
								class="absolute top-0 bottom-0 flex items-center justify-center bg-white bg-opacity-50 start-0 end-0"
							>
								<span
									class="ripple-bg-yellow-500 text-white font-bold text-sm rounded-md px-4 py-2.5 shadow-lg cursor-pointer"
									@click="$emit('tryAgain')"
								>
									{{ $t("try_again") }}
								</span>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script setup lang="ts">
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue"

defineSlots<{
	default: any
	footer: any
}>()

const emit = defineEmits(["close", "button-click"])

const props = withDefaults(
	defineProps<{
		visible: boolean
		title?: string
		buttonName?: string
		size?: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" | "full"
		loading?: boolean
		fetched?: boolean
		pageMode?: boolean
		hideHeader?: boolean
		hideFooter?: boolean
	}>(),
	{
		fetched: true,
		visible: false
	}
)

const state = reactive({
	innerSize: false
})

const ctr = ref()

const close = () => {
	emit("close")
}
const clickOutside = (e: any) => {
	if (!ctr?.value?.contains(e.target) && e.target?.contains(ctr.value) && !props.loading) {
		close()
	}
}
const click = () => {
	emit("button-click")
}

onMounted(() => {
	if (process.client) {
		document.addEventListener("click", clickOutside)
	}
})

onUnmounted(() => {
	if (process.client) {
		document.removeEventListener("click", clickOutside)
	}
})
</script>

<style>
.scroll-area {
	position: relative;
}

.scroll-area > .ps__rail-y {
	background-color: #f2f2f2 !important;
	width: 9px !important;
	margin-top: 5px;
	left: auto !important;
	right: 0 !important;
	margin-bottom: 5px !important;
	margin-right: 5px !important;
	border-radius: 9px;
	overflow: hidden !important;
	padding: 0 !important;
}

.scroll-area > .ps__rail-y > .ps__thumb-y {
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
	width: 100% !important;
	right: 0 !important;
	left: 0 !important;
	@apply bg-primary;
}
</style>
