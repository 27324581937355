import { defineStore } from "pinia"
import { type IconType } from "~/types/base-icons"

export type AdminMenuItemIds = "dashboard" | "manage-shop-orders" | "manage-test-requests" | "manage-users" | "show-website"

export type AdminMenuItem = {
	id: AdminMenuItemIds
	title: string
	icon: string
	to: string
	color: string
	yellow?: boolean
	roles: string[]
}

export const usePanelStore = defineStore("panel", {
	state: () => {
		const { $i18n, $routeUrl } = useNuxtApp()
		return {
			adminMenuItems: [
				{
					id: "dashboard",
					title: $i18n.t("dashboard"),
					icon: "clarity:dashboard-solid",
					to: $routeUrl.AdminDashboardUrl(),
					color: "#455a64",
					roles: ["admin"]
				},
				{
					id: "manage-shop-orders",
					title: $i18n.t("shop_orders"),
					icon: "iconamoon:box-fill",
					to: $routeUrl.ManageShopOrdersUrl(),
					color: "#455a64",
					roles: ["admin"]
				},
				{
					id: "manage-test-requests",
					title: $i18n.t("test_requests"),
					icon: "majesticons:test-tube-filled",
					to: $routeUrl.ManageTestRequestsUrl(),
					color: "#455a64",
					roles: ["admin"]
				},
				{
					id: "manage-users",
					title: $i18n.t("users"),
					icon: "mdi:account-group",
					to: $routeUrl.ManageUsersUrl(),
					color: "#455a64",
					roles: ["admin"]
				},
				{
					id: "show-website",
					title: $i18n.t("show_website"),
					icon: "mdi:eye",
					to: $routeUrl.HomeUrl(),
					color: "#455a64",
					roles: ["admin"]
				}
			] as AdminMenuItem[]
		}
	}
})
