<template>
	<div
		class="flex flex-col flex-grow"
		:style="{
			borderColor: '#FCC16F',
			boxSizing: 'border-box',
			borderStyle: 'solid',
			borderTopWidth: 'env(safe-area-inset-top)',
			borderBottomWidth: 'env(safe-area-inset-bottom)',
			borderLeftWidth: 'env(safe-area-inset-left)',
			borderRightWidth: 'env(safe-area-inset-right)'
		}"
	>
		<VitePwaManifest />
		<NuxtLoadingIndicator />
		<NuxtPage />
		<ClientOnly>
			<Assistant v-if="user" />
			<AssistantTour v-if="user" />
		</ClientOnly>
	</div>
</template>

<script setup lang="ts">
import { useScreenSafeArea } from "@vueuse/core"
import { openDB } from "idb"

const textToSpeach = useTextToSpeech()
const app = useNuxtApp()
const runtimeConfig = useRuntimeConfig()

const user = useSupabaseUser()

const onBeforeInstallPrompt = (e: Event) => {
	e.preventDefault()
	window.beforeInstallPrompt = e
}

useHead({
	htmlAttrs: {
		translate: "no",
		dir: app.$i18n.localeProperties.value.dir,
		lang: app.$i18n.localeProperties.value.code
	},
	bodyAttrs: {},
	script: [
		{
			src: "https://code.responsivevoice.org/responsivevoice.js?key=6EPGib4H"
		},
		{
			innerHTML: `window.addEventListener("beforeinstallprompt", ${onBeforeInstallPrompt.toString()})`
		}
	],
	title: app.$i18n.t("app_name"),
	link: [
		{
			rel: "apple-touch-icon",
			sizes: "180x180",
			href: "/apple-touch-icon.png"
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "32x32",
			href: "/favicon-32x32.png"
		},
		{
			rel: "icon",
			type: "image/png",
			sizes: "16x16",
			href: "/favicon-16x16.png"
		},
		{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
		{
			rel: "mask-icon",
			href: "/safari-pinned-tab.svg",
			color: "#ff6801"
		}
	],
	meta: [
		{ charset: "utf-8" },
		{
			name: "viewport",
			content:
				"width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0, interactive-widget=resizes-content, viewport-fit=cover"
		},
		{ hid: "description", name: "description", content: app.$i18n.t("app_motto") },
		{ name: "format-detection", content: "telephone=no" },
		{ name: "msapplication-TileColor", content: "#ff6801" },
		{ name: "theme-color", content: "#ffffff" }
	]
})

watch(
	() => app.$pwa?.needRefresh,
	() => {
		app.$toast.info({
			message: app.$i18n.t("app_new_content_available_message"),
			buttons: [[app.$i18n.t("reload"), () => app.$pwa?.updateServiceWorker(), false]]
		})
	}
)

const screenSafeArea = useScreenSafeArea()

onMounted(async () => {
	if (app.$pwa?.offlineReady) {
		app.$toast.success({ message: app.$i18n.t("offline_ready_message") })
	}
	textToSpeach.captureUserInteraction()
})
</script>
