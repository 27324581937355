import { defineStore, skipHydrate } from "pinia"
import { useLocalStorage } from "@vueuse/core"
import { type Database } from "~/types/supabase"

type InfoType = {
	[key: string | number]: any[]
}

export const useInfoStore = defineStore("info", () => {
	const state = {
		info: ref<InfoType | null>(null)
	}

	const supabase = useSupabaseClient<Database>()
	const user = useSupabaseUser()
	const questions = useQuestions()

	const fixValues = (values: any[]) => {
		return values.map((v) => {
			if (v === "false") return false
			if (v === "true") return true
			if (!isNaN(v)) return +v
			return v
		})
	}

	const getInfo = async () => {
		const info = await supabase
			.from("question_answers")
			.select("question_name, values")
			.eq("user_id", user.value?.id || "")
		state.info.value = Object.fromEntries(info.data?.map((v) => [v.question_name, fixValues(v.values || [])]) || [])
		return state.info.value
	}

	const setInfo = async (name: string, values: any[]) => {
		try {
			const r = await supabase
				.from("question_answers")
				.upsert([
					{
						user_id: user.value?.id!,
						question_name: name,
						values: values
					}
				])
				.select()
			state.info.value = {
				...state.info.value,
				[name]: fixValues(r.data?.[0]?.values || values)
			}
			return r
		} catch (e) {
			throw e
		}
	}

	const fetchInfo = async () => {
		if (state.info.value || !user.value?.id) return
		return await getInfo()
	}

	const questionsPromptPart = computed(
		() =>
			questions.value
				.filter((question) => ![undefined, null, ""].includes(state.info.value?.[question.id]?.[0]))
				.map(
					(question) =>
						"- " +
						question.name +
						": " +
						(question.answer_type === "boolean"
							? state.info.value?.[question.id]?.[0]
								? "Yes"
								: "No"
							: state.info.value?.[question.id]
									?.map((v) => v || "")
									?.join(" ")
									?.trim() || "")
				)
				.join("\n") || null
	)

	return {
		...state,
		getInfo,
		fetchInfo,
		setInfo,
		questionsPromptPart
	}
})
