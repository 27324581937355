<template>
	<BaseModal :visible="visible" :title="$t('genome_test_details')" hide-footer size="lg" @close="emit('close')">
		<div class="grid grid-cols-1 gap-6 p-6 text-sm text-slate-800" :style="{ lineHeight: '2' }">
			<div class="flex items-start flex-wrap -me-3 -mb-3">
				<span class="font-bold text-slate-900 me-4 -mt-0.5 whitespace-nowrap">
					{{ $t("candidate_variations") + " :" }}
				</span>
				<span
					v-for="(variation, i) in order.candidate_variants"
					:key="i"
					class="me-3 mb-3 border border-slate-200 rounded px-1 text-xs py-0.5 bg-slate-50"
				>
					{{ variation }}
				</span>
			</div>
			<span class="border-b border-slate-200" />
			<span>
				{{ order.description }}
			</span>
		</div>
	</BaseModal>
</template>

<script setup lang="ts">
import type { GenomeTestOrder } from '~/models/genome-test-order';

const emit = defineEmits<{
	close: []
}>()

defineProps<{
    visible: boolean
    order: GenomeTestOrder
}>()
</script>
