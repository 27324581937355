import { watchDebounced } from "@vueuse/core"
// @ts-ignore
import { useSound } from "@vueuse/sound"
import dayjs from "dayjs"
import { type ChatCompletionRequestMessage } from "openai"
import buttonSound from "~/assets/audio/beep.mp3"
import { type Medicine } from "~/models/medicine"
import { type UserEvent } from "~/models/user-event"

export type AssistantMode = "off" | "talk" | "listen" | "pending"

export default () => {
	const infoStore = useInfoStore()
	const speechRecognition = useSpeechRecognition()
	const textToSpeech = useTextToSpeech()
	const app = useNuxtApp()

	const state = useVoiceAssistantStore()
	const { play: playBeepSound } = useSound(buttonSound)

	const conversations = computed(() => ({
		home: homeConversation(),
		main: mainConversation(),
		diet: dietConversation(),
		exercise: exerciseConversation(),
		meditation: meditationConversation()
	}))

	const currentConversation = computed<Conversation>(() => {
		return (conversations.value as any)[state.currentConversationId] || conversations.value["home"]
	})

	const currentNode = computed<{
		index: number
		node: VoiceAssistantNode
	}>(() => {
		let node = findNode(currentConversation.value, state.currentNodeId)
		if (node.index < 0) {
			node.index = 0
			node.node = currentConversation.value[0]
		}
		if (state.currentNodeId !== node?.node?.id) {
			state.currentNodeId = node?.node?.id!
		}
		return node as any
	})

	const speak = (text: string) => {
		return textToSpeech.say(text)
	}

	const checkNodeCondition = (node: VoiceAssistantNode | null, picked?: boolean) => {
		if (!node) return false
		const index = findNodeIndex(currentConversation.value, node.id!)
		let nodeRunTime = 0
		if (node.persistence === "session") {
			nodeRunTime = state.sessionNodeRunTimes?.[state.currentConversationId]?.[node.id!] || 0
		} else if (node.persistence === "forever") {
			nodeRunTime = state.foreverNodeRunTimes?.[state.currentConversationId]?.[node.id!] || 0
		}
		if (index < 0) return false
		if (node.mode === "pick" && !picked) return false
		if (node.mode === "once" && nodeRunTime > 0) return false
		if (node.condition && !node.condition?.()) return false
		return true
	}

	const getNextNode = (picked?: boolean) => {
		let { index } = currentNode.value
		for (let i = index + 1; i < currentConversation.value.length; i++) {
			let node = currentConversation.value[i]
			if (checkNodeCondition(node, picked)) {
				return node
			}
		}
	}

	const transcriptHumanVoice = async (file: File | null, signal?: AbortSignal) => {
		let answer = state.transcript
		if (!file) return answer
		if (currentNode.value.node.question?.id && file) {
			const prompt = currentNode.value.node.transcriptPrompt || ""
			answer = (await speechRecognition.transcribe(file, prompt, signal)) || answer
			state.transcript = answer
		}
		console.log("Whisper:", JSON.stringify(answer))
		return answer
	}

	const closeAssistant = async () => {
		if (!state.isLoaded) return
		if (state.mode !== "off") {
			state.mode = "off"
			return
		}
		state.preserveAnswer = false
		stopAssistant()
	}

	const listenAssistant = async () => {
		if (!state.isLoaded) return
		if (state.mode !== "listen") {
			state.mode = "listen"
			return
		}
		await changeListening(true)
	}

	const stopAssistant = async () => {
		if (!state.isLoaded) return
		if (!state.preserveAnswer) {
			state.transcript = ""
			state.answer = ""
			state.writedAnswer = ""
		}
		textToSpeech?.shutUp()
		state.abortController?.abort()
		state.abortController = null
		if (!currentNode.value?.node?.silence) {
			playBeepSound()
		}
		await changeListening(false)
	}

	const changeListening = async (isListening: boolean) => {
		if (!state.isLoaded) return
		if (isListening) {
			await state.recorder.start()
			if (!state.preserveAnswer) {
				state.transcript = ""
				state.answer = ""
				state.writedAnswer = ""
			}
			console.log("recorder started")
		} else {
			console.log("recorder stopped")
			await state.recorder.stop()
		}
	}

	const handleNode = async () => {
		if (!state.isLoaded) return
		let { node } = findNode(currentConversation.value, state.currentNodeId)
		console.log("Handle Node Id:", node?.id)
		if (!node) return

		console.log(state.recorder)

		state.preserveAnswer = false

		if (!state.answer) {
			console.log("handleNode 1")
			if (!checkNodeCondition(node, state.isNodePicked)) {
				const nextNode = getNextNode()
				if (nextNode?.id) {
					state.isNodePicked = false
					state.currentNodeId = nextNode.id
					return
				} else {
					state.mode = "off"
					return
				}
			}

			console.log("handleNode 2")

			await node.onStart?.(state.abortController)
			console.log("handleNode 3", node.id, state.currentNodeId)
			if (node?.id !== state.currentNodeId) {
				return
			}

			console.log("handleNode 3 1")

			const readBeforeSentences = async () => {
				for (let i = 0; i < (node?.beforeSentences?.()?.length || 0); i++) {
					await speak(node?.beforeSentences?.()?.[i] || "")
				}
			}

			if (node?.isBeforeSentencesBlocking) {
				await readBeforeSentences()
			} else {
				readBeforeSentences().catch()
			}

			console.log("handleNode 4")

			state.pending = true

			await node.onSentencesEnd?.()
			if (node?.id !== state.currentNodeId) return

			console.log("handleNode 5")

			if (node.question) {
				state.mode = "listen"
				return
			}
		}

		state.pending = true

		console.log("handleNode 6")

		if (state.answer && node.question) {
			const file = await state.recorder.getFile()
			console.log("file:", file)
			let answer: string | null = state.answer
			let preFixValue = null
			if (node.preFixAnswer) {
				preFixValue = await node.preFixAnswer(answer)
			}
			if (!preFixValue?.isCorrect && !state.writedAnswer) {
				answer = (await transcriptHumanVoice(file, state.abortController?.signal)) || answer
				state.answer = answer
			}
			if (!preFixValue?.isCorrect && node.validateAnswer) {
				if (node.preFixAnswer) {
					preFixValue = await node.preFixAnswer(answer)
				}
				if (!preFixValue?.isCorrect) {
					answer = await node.validateAnswer(answer, state.abortController?.signal)
				}
			}
			if (preFixValue?.isCorrect) {
				answer = preFixValue?.answer
			}
			if (answer !== null) state.answer = answer
			const isContinue = await node.handleAnswer?.(answer, state.abortController?.signal)
			if (!isContinue || node?.id !== state.currentNodeId) return
		}

		await node.doTask?.()

		if (node.persistence === "session") {
			if (!state.sessionNodeRunTimes[state.currentConversationId])
				state.sessionNodeRunTimes[state.currentConversationId] = {}
			state.sessionNodeRunTimes[state.currentConversationId][node.id!] =
				(state.sessionNodeRunTimes[state.currentConversationId][node.id!] || 0) + 1
		} else if (node.persistence === "forever") {
			if (!state.foreverNodeRunTimes[state.currentConversationId])
				state.foreverNodeRunTimes[state.currentConversationId] = {}
			state.foreverNodeRunTimes[state.currentConversationId][node.id!] =
				state.foreverNodeRunTimes[state.currentConversationId][node.id!] + 1 + 1
		}

		if (node?.id !== state.currentNodeId) return

		const readAfterSentences = async () => {
			for (let i = 0; i < (node?.afterSentences?.()?.length || 0); i++) {
				await speak(node?.afterSentences?.()?.[i] || "")
			}
		}

		if (node?.isAfterSentencesBlocking) {
			state.pending = false
			await readAfterSentences()
			state.pending = true
		} else {
			readAfterSentences().catch()
		}

		if (node?.end) {
			state.mode = "off"
		}

		await node.onEnd?.()
		if (node?.id !== state.currentNodeId) return

		if (node.end) {
			return
		}

		const pickedNodeId = node?.goTo?.()
		if (pickedNodeId) {
			state.isNodePicked = true
			state.currentNodeId = pickedNodeId
			return
		}

		const nextNode = getNextNode()
		if (nextNode?.id) {
			state.isNodePicked = false
			state.currentNodeId = nextNode.id
			return
		} else {
			state.mode = "off"
			return
		}
	}

	const init = async () => {
		state.recorder.onSpeaking = onSpeaking
		state.recorder.onStoppedSpeaking = onStoppedSpeaking

		state.bumblebee.stop()
		state.bumblebee.setWorkersPath("/bumblebee-workers")
		state.bumblebee.addHotword("jarvis")
		state.bumblebee.setSensitivity(0.5)

		state.bumblebee.on("hotword", onKeywordDetection)
		state.bumblebee.start()
		state.bumblebee.addListener("loaded", () => {
			console.log("sss")
		})
		state.isLoaded = true
	}

	const release = () => {
		state.bumblebee.stop()
	}

	const onSpeaking = () => {
		console.log("speaking detected")
	}

	const onStoppedSpeaking = async () => {
		console.log("stopped speaking detected")
		if (!state.isLoaded) return
		if (state.mode === "listen") {
			state.answer = "good"
			await changeListening(false)
			state.mode = "talk"
		}
	}

	const setConversation = (id: string) => {
		if (state.currentConversationId === id) return
		if (state.mode !== "off") stopAssistant()
		state.currentConversationId = id
	}

	const skipQuestion = async () => {
		if (!state.isLoaded) return
		const question = currentNode.value?.node?.question
		console.log(question)
		if (!question) return
		state.pending = true
		try {
			await infoStore.setInfo(question.id!, [""])
			state.mode = "talk"
		} catch (e) {
			app.$toastError(e)
		}
		state.pending = false
	}

	const write = async (text: string) => {
		if (!state.isLoaded) return
		if (state.mode === "off" && !state.isTourActive) {
			state.mode = "talk"
			state.isIntroduced = true
		} else if (state.mode == "listen") {
			await changeListening(false)
			state.answer = text
			state.writedAnswer = text
			state.mode = "talk"
		}
	}

	const onKeywordDetection = (keyword: string) => {
		if (!state.isLoaded) return
		if (keyword) {
			console.log("keyword:", keyword)
			if (state.mode === "off" && !state.isTourActive) {
				state.mode = "talk"
				state.isIntroduced = true
			}
		}
	}

	const beforeInit = () => {
		watch(
			() => state.currentNodeId,
			(newVal, oldVal) => {
				state.prevNodeId = oldVal
				if (newVal !== oldVal) {
					if (!state.preserveAnswer) {
						state.prevAnswer = state.answer
						state.transcript = ""
						state.answer = ""
						state.writedAnswer = ""
					}
				}
			}
		)

		watchDebounced(
			() => ({
				currentNodeId: state.currentNodeId,
				mode: state.mode
			}),
			async (newState, oldState) => {
				console.log(newState, oldState)
				const oldNode = currentNode.value?.node
				state.pending = false
				try {
					if (state.mode === "off" && oldState.mode !== "off") {
						await closeAssistant()
						state.currentNodeId = ""
						state.prevNodeId = ""
						state.flowConversations = {}
					} else if (state.mode === "listen") {
						await listenAssistant()
					} else if (state.mode === "talk") {
						if (oldState.mode === "talk" && newState.mode === "talk") {
							await stopAssistant()
						} else {
							await changeListening(false)
						}
						state.abortController = new AbortController()
						await handleNode()
						state.abortController = null
					}
				} catch (e: any) {
					if (oldNode?.id === currentNode.value?.node?.id) {
						if (e.message.startsWith("The user aborted a request")) {
							console.log("aborted")
							return
						}
						console.error(e)
						app.$toastError(e)
						await speak(app.$i18n.t("network_error"))
						closeAssistant()
					}
				}
				state.pending = false
			},
			{ debounce: 150 }
		)
	}

	return {
		transcriptHumanVoice,
		currentConversation,
		currentNode,
		init,
		beforeInit,
		release,
		stopAssistant,
		skipQuestion,
		speak,
		setConversation,
		write
	}
}

const findNode = (conversation: Conversation, id: string) => {
	return {
		index: findNodeIndex(conversation, id),
		node: conversation.find((c) => c.id === id)
	}
}

const findNodeIndex = (conversation: Conversation, id: string) => {
	return conversation.findIndex((c) => c.id === id)
}

const preFixBooleanQuestion = async (answer: string) => {
	let a = answer.toLowerCase().replace(/\.\,/g, "").trim()
	let isCorrect = false
	let decodedAnswer = null
	if (a.startsWith("no")) {
		isCorrect = true
		decodedAnswer = false
	} else if (a.startsWith("yes")) {
		isCorrect = true
		decodedAnswer = true
	} else {
		isCorrect = false
	}
	return {
		answer: decodedAnswer,
		isCorrect
	}
}

const validateQuestion = async (question: Question, answer: string, signal?: AbortSignal) => {
	if (!question) return null
	if (!answer) return null
	const app = useNuxtApp()
	let prompts: ChatCompletionRequestMessage[] = []
	if (question.answer_type === "number" && !question.units?.length) {
		prompts = [
			{
				role: "system",
				content: [
					"extract the answer from the question in the right form.",
					"the answer is a number in digit.",
					"if the answer is wrong return INVALID."
				].join("\n")
			},
			{
				role: "user",
				content: [("Q: " + question.title, "A: " + answer)].join("\n")
			}
		]
	} else if (question.answer_type === "number" && question.units?.length) {
		prompts = [
			{
				role: "system",
				content: [
					"extract the answer from the question in the right form.",
					"the answer is a number with a unit.",
					"the unit is one of these(case sensitive): " + question.units.join(", "),
					"# format: 23 " + question.units[0],
					"if the answer is wrong return INVALID."
				].join("\n")
			},
			{
				role: "user",
				content: [("Q: " + question.title, "A: " + answer)].join("\n")
			}
		]
	} else if (question.answer_type === "select") {
		prompts = [
			{
				role: "system",
				content: [
					"extract the answer from the question in the right form.",
					"the answer is correct if it sounds like one of the possible answers.",
					"the possible answer is one of these: " + question.options?.join(", "),
					"return the closest possible answer or INVALID with the threshold of 0.5",
					"trim ."
				].join("\n")
			},
			{
				role: "user",
				content: [("Q: " + question.title, "A: " + answer)].join("\n")
			}
		]
	} else if (question.answer_type === "boolean") {
		prompts = [
			{
				role: "system",
				content: [
					"extract the answer from the question in the right form.",
					"the answer is 1 or 0",
					"1 is equal to yes, true, ...",
					"0 is equal to no, false, ...",
					"if the answer is wrong return INVALID."
				].join("\n")
			},
			{
				role: "user",
				content: [("Q: " + question.title, "A: " + answer)].join("\n")
			}
		]
	}
	console.log(prompts)
	let r: any = await $fetch(app.$config.public.baseUrl + "/tools/chat", {
		method: "POST",
		signal: signal,
		body: {
			prompts
		}
	})
	console.log(r.data)
	if (question.answer_type === "number" && !question.units?.length) {
		console.log(r.data)
		r = Number(r.data?.trim())
		if (!isNaN(r)) {
			return r
		}
	} else if (question.answer_type === "number" && question.units?.length) {
		let [data1, data2] = r.data?.trim()?.split(" ") || []
		data1 = Number(data1?.trim())
		data2 = data2?.trim() || null
		if (!isNaN(data1) && question.units.includes(data2)) {
			r = data1 + " " + question.units_en?.[question.units?.indexOf(data2) || 0]
			return r
		}
	} else if (question.answer_type === "select") {
		r = question.options?.find((v) => v?.toLowerCase() === r.data?.trim()?.toLowerCase()) || null
		if (r) {
			return question.options_en?.[question.options?.indexOf(r) || 0]
		}
	} else if (question.answer_type === "boolean") {
		r = Number(r.data?.trim())
		if (!isNaN(r)) {
			return !!r
		}
	}
	return null
}

type Question = {
	id?: string
	title: string
	answer_type: "number" | "text" | "boolean" | "select"
	options_en?: string[]
	options?: string[]
	units_en?: string[]
	units?: string[]
	description?: string
	depends_on_prev?: boolean
	group?: string
	index?: number
	answers?: string[]
}

type Conversation = VoiceAssistantNode[]

type ConversationFunction = (options?: { questions?: ComputedRef<Question[]> }) => Conversation

type VoiceAssistantNode = {
	id?: string
	end?: boolean
	silence?: boolean
	beforeSentences?: () => string[]
	isBeforeSentencesBlocking?: boolean
	afterSentences?: () => string[]
	isAfterSentencesBlocking?: boolean
	onStart?: Function
	onEnd?: Function
	onSentencesEnd?: Function
	doTask?: (signal?: AbortSignal) => Promise<boolean>
	validateAnswer?: (answer: string, signal?: AbortSignal) => Promise<any>
	handleAnswer?: (answer: string | null, signal?: AbortSignal) => Promise<any>
	transcriptPrompt?: string
	question?: Question
	preFixAnswer?: (answer: string, signal?: AbortSignal) => Promise<{ answer: any | null; isCorrect: boolean }>
	goTo?: () => string
	goToConversation?: () => string
	mode?: "normal" | "once" | "pick"
	condition?: () => boolean
	persistence?: "off" | "session" | "forever"
}

type MainFlowAnswer = {
	message?: string | null
	add_event?: UserEvent | null
	edit_event?: UserEvent | null
	cancel_event?: UserEvent | null
	add_medication?: Medicine | null
	edit_medication?: Medicine | null
	cancel_medication?: Medicine | null
	send_email?: boolean
	medical_info?: {
		description?: string | null
		answer?: string | null
	} | null
	tour?: boolean | null
	help?: boolean | null
	exit?: boolean | null
	page?:
		| "diet tracker"
		| "physical exercise tracker"
		| "meditation tracker"
		| "shop"
		| "body insight"
		| "generate diet plan"
		| "generate exercise plan"
		| "generate meditation plan"
		| "expert"
		| "medication"
		| null
}

type EmailFlowAnswer = {
	message?: string | null
	send_email?: {
		subject?: string | null
		recipient?: string | null
		body?: string | null
	} | null
	need_exit?: boolean
}

const mainConversation: ConversationFunction = function () {
	const dietStore = useDietStore()
	const infoStore = useInfoStore()
	const userEventStore = useUserEventStore()
	const medicineStore = useMedicineStore()
	const user = useSupabaseUser()
	const app = useNuxtApp()
	const textToSpeech = useTextToSpeech()

	const state = useVoiceAssistantStore()

	const speak = (text: string) => {
		return textToSpeech.say(text)
	}

	const questions = computed<Question[]>(() => {
		return useQuestions()
			.value.map((question, i) => ({
				...question,
				index: i,
				answers: infoStore.info?.[question.id] || []
			}))
			.filter((q) => q.group === state.activeQuestionGroup) as any
	})

	const questionGroups = computed(() => [
		...new Set(
			useQuestions()
				.value.map((question) => question.group)
				.filter((v) => v)
		)
	])

	const nextQuestionGroup = computed(() => {
		return questionGroups.value[questionGroups.value?.indexOf(state.activeQuestionGroup) + 1]
	})

	const unAnsweredQuestion = computed(() =>
		questions?.value?.find((question, i) => {
			return !question.answers?.length && (!question.depends_on_prev || questions?.value?.[i - 1]?.answers?.[0])
		})
	)

	const prevAnswerObject = computed<MainFlowAnswer | null>(() => {
		try {
			return JSON.parse(state.prevAnswer)
		} catch (e) {
			return null
		}
	})

	const getAnswerObject = <T>(answer: string, extra?: T): T | null => {
		try {
			return {
				...extra,
				...JSON.parse(answer)
			}
		} catch (e) {
			return null
		}
	}

	return [
		{
			id: "hi",
			beforeSentences: () => [app.$i18n.t("hi") + " " + (user.value?.user_metadata?.["first_name"] || "") + "."],
			isBeforeSentencesBlocking: true,
			mode: "once",
			persistence: "session"
		},
		{
			id: "intro",
			beforeSentences: () => [app.$i18n.t("assistant_intro_message")],
			isBeforeSentencesBlocking: true,
			mode: "once",
			persistence: "forever"
		},
		{
			id: "main-flow",
			beforeSentences: () => [],
			isBeforeSentencesBlocking: true,
			afterSentences: () => {
				const answerObject = getAnswerObject<MainFlowAnswer>(state.answer)
				if (answerObject?.message) {
					return [answerObject?.message]
				} else if (
					answerObject?.add_event &&
					answerObject?.add_event?.title &&
					answerObject?.add_event?.date &&
					answerObject?.add_event?.time
				) {
					return ["Event added"]
				} else if (
					answerObject?.edit_event &&
					answerObject?.edit_event?.id &&
					answerObject?.edit_event?.title &&
					answerObject?.edit_event?.date &&
					answerObject?.edit_event?.time
				) {
					return ["Event edited"]
				} else if (answerObject?.cancel_event?.id) {
					return ["Event canceled"]
				} else if (
					answerObject?.add_medication &&
					answerObject?.add_medication?.name &&
					answerObject?.add_medication?.usage_amount &&
					answerObject?.add_medication?.usage_interval &&
					answerObject?.add_medication?.usage_start_date &&
					answerObject?.add_medication?.usage_start_time
				) {
					return ["Medication added"]
				} else if (
					answerObject?.edit_medication &&
					answerObject?.edit_medication?.id &&
					answerObject?.edit_medication &&
					answerObject?.edit_medication?.name &&
					answerObject?.edit_medication?.usage_amount &&
					answerObject?.edit_medication?.usage_interval &&
					answerObject?.edit_medication?.usage_start_date &&
					answerObject?.edit_medication?.usage_start_time
				) {
					return ["Medication edited"]
				} else if (answerObject?.cancel_medication?.id) {
					return ["Medication canceled"]
				} else {
					return [state.answer]
				}
			},
			isAfterSentencesBlocking: true,
			mode: "normal",
			question: {
				id: "main-flow",
				title: "",
				answer_type: "text"
			},
			validateAnswer: async (answer: string, signal?: AbortSignal) => {
				console.log("validateAnswer Answer:", answer)
				if (!answer) return null
				state.flowConversations = {
					...state.flowConversations,
					"main-flow": [
						...(state.flowConversations["main-flow"] || []),
						{
							role: "user",
							content: answer
						}
					]
				}
				const prompts: ChatCompletionRequestMessage[] = [
					{
						role: "system",
						content: [
							[
								"you are a personal voice assistant.",
								"your name is viva.",
								"you are a part of an app named VIVAI Health.",
								"only output/print in JSON object.",
								"what you want to say must be in 'message' field.",
								"today is " + dayjs().format("YYYY-MM-DD HH:mm"),
								"don't use today as default time. always ask the user.",
								"your output format is JSON object.",
								"your answers are short.",
								"i give you different conversation flows. that you should follow them if user want it.",
								"don't forget to output fields",
								"don't forget to output message.",
								"you answer always in step by step format."
							].join("\n"),
							[
								"User Events (id | title | date | time):",
								...(userEventStore.events?.map((event) => {
									return event.id + " | " + event.title + " | " + event.date + " | " + event.time
								}) || [])
							].join("\n"),
							[
								"User Medications (id | name | usage_interval | usage_amount | usage_start_date | usage_start_time):",
								...(medicineStore.medicines?.map((medicine) => {
									return (
										medicine.id +
										" | " +
										medicine.usage_interval +
										" | " +
										medicine.usage_amount +
										" | " +
										medicine.usage_start_date +
										" | " +
										medicine.usage_start_time
									)
								}) || [])
							].join("\n"),
							[
								"Object - Event:",
								"event/task/reminder/meeting is an object with id, title, time, date.",
								"event title is string",
								"event time is in HH:mm format",
								"event date is in YYYY-MM-DD format",
								"if event exist in events, id field is equal to the old id or it's null"
							].join("\n"),
							[
								"Object - Medication:",
								"medication is an object with id, name, usage_interval, usage_amount, usage_start_date, usage_start_time.",
								"medication name is string, name of the medicine",
								"medication usage_start_time is in HH:mm format. when user eat first doze.",
								"medication usage_start_date is in YYYY-MM-DD format. when user eat first doze.",
								"medication usage_interval is number. interval between every consumption of the medicine in hours.",
								"medication usage_amount is string. doze of medicine in every consumption.",
								"if medication exist in medications, id field is equal to the old id or it's null"
							].join("\n"),
							...[
								"Flow - event:",
								"you only can modify events with add_event, edit_event, cancel_event fields.",
								[
									"if user want's to add an event, after you get all the information, output the event information in add_event field."
								].join("\n"),
								[
									"if user want's to cancel an event and the event is in events, after you get all the information, output the event information in cancel_event field."
								].join("\n"),
								[
									"if user want's to edit an event and the event is in events, after you get all the information, output the event information in edit_event field."
								].join("\n")
							],
							...[
								"Flow - medication:",
								"you only can modify medications with add_medication, edit_medication, cancel_medication fields.",
								[
									"if user want's to add a medication, after you get all the information, output the medication information in add_medication field."
								].join("\n"),
								[
									"if user want's to cancel a medication and the medication is in medications, after you get all the information, output the medication information in cancel_medication field."
								].join("\n"),
								[
									"if user want's to edit a medication and the medication is in medications, after you get all the information, output the medication information in edit_medication field."
								].join("\n")
							],
							...[
								"Flow - email:",
								[
									"if user is talking about sending an email, send a signal by setting send_email field in the output to true.",
									"don't ask the user about the email details.",
									"don't assist user to send email, just set the send_email field."
								].join("\n")
							],
							...[
								"Flow - medical information:",
								[
									"if the user give a valid personal medical information, output the information in medical_info field.",
									"medical_info field is an object with description, answer.",
									"description is a string",
									"answer is a string"
								].join("\n")
							],
							...[
								"Flow - navigate in app:",
								[
									"if user wants to go to a page set the page name in page field",
									"pages: diet tracker, physical exercise tracker, meditation tracker, shop, body insight, generate diet plan, generate exercise plan, generate meditation plan, expert, medication"
								].join("\n")
							],
							...[
								"Flow - close app:",
								[
									"if user is done with assistant for now by saying goodbye or something like that set exit field to true."
								].join("\n")
							],
							...[
								"Flow - medical emergency:",
								[
									"if user has medical emergency set help field to true.",
									"if user wants to talk to an expert set help field to true."
								].join("\n")
							],
							...[
								"Flow - app tour:",
								[
									"if user wants to have a tour in the app set tour field to true with short message.",
									"if user wants introduction of different part of the app set tour field to true with short message.",
									"if user wants explanation about different part of the app set tour field to true with short message."
								].join("\n")
							],
							"output always has a follow up answer in message field."
						].join("\n\n")
					},
					{
						role: "assistant",
						content: JSON.stringify({
							message: "Hello"
						} as MainFlowAnswer)
					},
					...(state.flowConversations["main-flow"] || [])
				]
				console.log(console.log(prompts[0].content))
				console.log(console.log(prompts[prompts.length - 2].content))
				let r: any = await $fetch(app.$config.public.baseUrl + "/tools/chat", {
					method: "POST",
					signal: signal,
					body: {
						prompts
					},
					retry: 3
				})
				console.log(r.data)
				state.flowConversations = {
					...state.flowConversations,
					"main-flow": [
						...(state.flowConversations["main-flow"] || []),
						{
							role: "assistant",
							content:
								JSON.stringify(
									getAnswerObject<MainFlowAnswer>(r.data, {
										message: "How can i help you?"
									}) || undefined
								) || r.data
						}
					]
				}
				return r.data
			},
			handleAnswer: async (answer, signal?: AbortSignal) => {
				const answerObject = getAnswerObject<MainFlowAnswer>(state.answer)
				if (answerObject?.exit) {
					state.isNodePicked = true
					state.currentNodeId = "exit"
					return false
				} else if (answerObject?.exit) {
					state.isNodePicked = true
					state.preserveAnswer = true
					state.currentNodeId = "exit"
					return false
				} else if (
					answerObject?.add_event?.title &&
					answerObject?.add_event?.time &&
					answerObject?.add_event?.date
				) {
					await userEventStore.addEvent({
						user_id: user.value?.id!,
						title: answerObject?.add_event?.title || "",
						time: answerObject?.add_event?.time || "",
						date: answerObject?.add_event?.date || ""
					})
					state.flowConversations = {}
				} else if (
					answerObject?.edit_event?.id &&
					answerObject?.edit_event?.title &&
					answerObject?.edit_event?.time &&
					answerObject?.edit_event?.date
				) {
					await userEventStore.editEvent({
						id: answerObject?.edit_event?.id || "",
						user_id: user.value?.id!,
						title: answerObject?.edit_event?.title || "",
						time: answerObject?.edit_event?.time || "",
						date: answerObject?.edit_event?.date || ""
					})
					state.flowConversations = {}
				} else if (answerObject?.cancel_event?.id) {
					await userEventStore.deleteEvent(answerObject?.cancel_event?.id)
					state.flowConversations = {}
				} else if (
					answerObject?.add_medication?.name &&
					answerObject?.add_medication?.usage_interval &&
					answerObject?.add_medication?.usage_amount &&
					answerObject?.add_medication?.usage_start_date &&
					answerObject?.add_medication?.usage_start_time
				) {
					await medicineStore.addMedicine({
						user_id: user.value?.id!,
						name: answerObject?.add_medication?.name || "",
						name2: answerObject?.add_medication?.name || "",
						usage_interval: answerObject?.add_medication?.usage_interval || null,
						usage_amount: answerObject?.add_medication?.usage_amount || "",
						usage_start_date: answerObject?.add_medication?.usage_start_date || "",
						usage_start_time: answerObject?.add_medication?.usage_start_time || "",
						dosage: null,
						amount: null,
						description: null
					})
					state.flowConversations = {}
				} else if (
					answerObject?.edit_medication?.id &&
					answerObject?.edit_medication?.name &&
					answerObject?.edit_medication?.usage_interval &&
					answerObject?.edit_medication?.usage_amount &&
					answerObject?.edit_medication?.usage_start_date &&
					answerObject?.edit_medication?.usage_start_time
				) {
					await medicineStore.editMedicine({
						id: answerObject?.edit_medication?.id || "",
						user_id: user.value?.id!,
						name: answerObject?.edit_medication?.name || "",
						name2: answerObject?.edit_medication?.name || "",
						usage_interval: answerObject?.edit_medication?.usage_interval || null,
						usage_amount: answerObject?.edit_medication?.usage_amount || "",
						usage_start_date: answerObject?.edit_medication?.usage_start_date || "",
						usage_start_time: answerObject?.edit_medication?.usage_start_time || "",
						dosage: null,
						amount: null,
						description: null
					})
					state.flowConversations = {}
				} else if (answerObject?.cancel_medication?.id) {
					await medicineStore.deleteMedicine(answerObject?.cancel_medication?.id)
					state.flowConversations = {}
				} else if (answerObject?.send_email) {
					state.preserveAnswer = true
					state.isNodePicked = true
					state.currentNodeId = "email-flow"
					return false
				} else if (answerObject?.help) {
					state.isNodePicked = true
					state.currentNodeId = "talk-to-expert"
					return false
				} else if (answerObject?.tour) {
					state.isNodePicked = true
					state.currentNodeId = "go-to-tour"
					return false
				} else if (answerObject?.page) {
					const page = answerObject?.page
					if (page == "diet tracker") {
						await app.$router.push(app.$routeUrl.DietTrackerUrl())
					} else if (page == "physical exercise tracker") {
						await app.$router.push(app.$routeUrl.ExerciseTrackerUrl())
					} else if (page == "meditation tracker") {
						await app.$router.push(app.$routeUrl.MeditationTrackerUrl())
					} else if (page == "shop") {
						await app.$router.push(app.$routeUrl.ShopUrl())
					} else if (page == "body insight") {
						await app.$router.push(app.$routeUrl.InsightUrl())
					} else if (page == "generate diet plan") {
						await app.$router.push(app.$routeUrl.DietUrl())
					} else if (page == "generate exercise plan") {
						await app.$router.push(app.$routeUrl.ExerciseUrl())
					} else if (page == "generate meditation plan") {
						await app.$router.push(app.$routeUrl.MeditationUrl())
					} else if (page == "expert") {
						await app.$router.push(app.$routeUrl.ExpertsUrl())
					} else if (page == "medication") {
						await app.$router.push(app.$routeUrl.ManageMedicinesUrl())
					}
					state.isNodePicked = true
					state.currentNodeId = "exit"
					return false
				}
				return true
			}
		},
		{
			id: "refresh-main-flow",
			silence: true,
			goTo: () => state.prevNodeId || "",
			mode: "normal"
		},
		{
			id: "email-flow",
			beforeSentences: () => [],
			isBeforeSentencesBlocking: true,
			afterSentences: () => {
				const answerObject = getAnswerObject<EmailFlowAnswer>(state.answer)
				if (answerObject?.message) {
					return [answerObject?.message]
				} else if (
					answerObject?.send_email &&
					answerObject?.send_email?.subject &&
					answerObject?.send_email?.recipient &&
					answerObject?.send_email?.body
				) {
					return ["Sending email"]
				} else {
					return [state.answer]
				}
			},
			isAfterSentencesBlocking: true,
			mode: "normal",
			question: {
				id: "email-flow-loop",
				title: "",
				answer_type: "text"
			},
			validateAnswer: async (answer: string, signal?: AbortSignal) => {
				console.log("validateAnswer Answer:", answer)
				if (!answer) return null
				state.flowConversations = {
					...state.flowConversations,
					"email-flow": [
						...(state.flowConversations["email-flow"] || []),
						{
							role: "user",
							content: answer
						}
					]
				}
				const prompts: ChatCompletionRequestMessage[] = [
					{
						role: "system",
						content: [
							[
								"you are a personal voice assistant.",
								"your name is viva.",
								"you are a part of an app named VIVAI Health.",
								"only output/print in JSON object.",
								"what you want to say must be in 'message' field.",
								"today is " + dayjs().format("YYYY-MM-DD HH:mm"),
								"don't use today as default time. always ask the user.",
								"your output format is JSON object.",
								"your answers are short.",
								"don't forget to output fields",
								"output always has a follow up answer in message field.",
								"you answer always in step by step format.",
								"if you asked a question, user will answer it."
							].join("\n"),
							[
								"Object - Email:",
								"email is an object with subject, recipient, body.",
								"email subject is string. required.",
								"email recipient is valid email address. required.",
								"email body is string. required."
							].join("\n"),
							[
								"if what user said isn't about sending email set need_exit to true, otherwise set it to false."
							].join("\n"),
							[
								"you are an email sender. your task is to get info from the user and send email by filling out send_email field in output. send_email field value is an email object.",
								"before sending email read the email to the user and get confirmation.",
								"your other task is to set need_exit field in the output."
							].join("\n"),
							[
								"if what user said isn't about sending email set need_exit to true, otherwise set it to false."
							].join("\n")
						].join("\n\n")
					},
					{
						role: "assistant",
						content: JSON.stringify({
							message: "Hello"
						} as EmailFlowAnswer)
					},
					...(state.flowConversations["email-flow"] || [])
				]
				console.log(console.log(prompts?.[0]?.content))
				console.log(console.log(prompts[prompts.length - 2].content))
				let r: any = await $fetch(app.$config.public.baseUrl + "/tools/chat", {
					method: "POST",
					signal: signal,
					body: {
						prompts
					},
					retry: 3
				})
				console.log(r.data)
				state.flowConversations = {
					...state.flowConversations,
					"email-flow": [
						...(state.flowConversations["email-flow"] || []),
						{
							role: "assistant",
							content:
								JSON.stringify(
									getAnswerObject<EmailFlowAnswer>(r.data, {
										need_exit: false,
										message: "How can i help you?",
										send_email: null
									}) || undefined
								) || r.data
						}
					]
				}
				return r.data
			},
			handleAnswer: async (answer, signal?: AbortSignal) => {
				const answerObject = getAnswerObject<EmailFlowAnswer>(state.answer)
				if (
					answerObject?.send_email?.subject &&
					answerObject?.send_email?.recipient &&
					answerObject?.send_email?.body
				) {
					var recipient = answerObject?.send_email.recipient
					var subject = answerObject?.send_email.subject
					var body = answerObject?.send_email.body
					location.href = "mailto:" + recipient + "?subject=" + subject + "&body=" + body
					state.isNodePicked = true
					state.currentNodeId = "exit"
					return false
				} else if (answerObject?.need_exit) {
					state.preserveAnswer = true
					state.isNodePicked = true
					state.flowConversations = {
						...state.flowConversations,
						"email-flow": []
					}
					state.currentNodeId = "main-flow"
					return false
				}
				return true
			}
		},
		{
			id: "refresh-email-flow",
			silence: true,
			goTo: () => state.prevNodeId || "",
			mode: "normal"
		},
		{
			id: "talk-to-expert",
			beforeSentences: () => [app.$i18n.t("assistant_talk_to_expert_message")],
			isBeforeSentencesBlocking: true,
			onEnd: async () => {
				await app.$router.push(app.$routeUrl.ExpertsUrl())
			},
			mode: "pick",
			end: true
		},
		{
			id: "go-to-tour",
			beforeSentences: () => [app.$i18n.t("assistant_go_to_tour_message")],
			end: true,
			isBeforeSentencesBlocking: true,
			doTask: async () => {
				state.isTourActive = true
				return true
			},
			goToConversation: () => "tour"
		},
		{
			id: "wrong-answer",
			beforeSentences: () => [app.$i18n.t("assistant_wrong_answer_message")],
			isBeforeSentencesBlocking: true,
			goTo: () => state.prevNodeId || "",
			mode: "pick"
		},
		{
			id: "exit",
			silence: true,
			mode: "pick",
			end: true
		}
	]
}

const homeConversation: ConversationFunction = function () {
	const dietStore = useDietStore()
	const infoStore = useInfoStore()
	const user = useSupabaseUser()
	const app = useNuxtApp()

	const state = useVoiceAssistantStore()

	const questions = computed<Question[]>(() => {
		return useQuestions()
			.value.map((question, i) => ({
				...question,
				index: i,
				answers: infoStore.info?.[question.id] || []
			}))
			.filter((q) => q.group === state.activeQuestionGroup) as any
	})

	const questionGroups = computed(() => [
		...new Set(
			useQuestions()
				.value.map((question) => question.group)
				.filter((v) => v)
		)
	])

	const nextQuestionGroup = computed(() => {
		return questionGroups.value[questionGroups.value?.indexOf(state.activeQuestionGroup) + 1]
	})

	const unAnsweredQuestion = computed(() =>
		questions?.value?.find((question, i) => {
			return !question.answers?.length && (!question.depends_on_prev || questions?.value?.[i - 1]?.answers?.[0])
		})
	)

	return [
		{
			id: "hi",
			beforeSentences: () => [app.$i18n.t("hi") + " " + (user.value?.user_metadata?.["first_name"] || "") + "."],
			isBeforeSentencesBlocking: true,
			mode: "once",
			persistence: "session"
		},
		{
			id: "intro",
			beforeSentences: () => [app.$i18n.t("assistant_intro_message")],
			isBeforeSentencesBlocking: true,
			mode: "once",
			persistence: "forever"
		},
		{
			id: "today-feeling",
			beforeSentences: () => [app.$i18n.t("assistant_today_feeling_message")],
			isBeforeSentencesBlocking: false,
			mode: "once",
			question: {
				id: "today-feeling",
				title: app.$i18n.t("assistant_today_feeling_message"),
				answer_type: "text"
			},
			transcriptPrompt: app.$i18n.t("assistant_today_feeling_message"),
			persistence: "session",
			validateAnswer: async (answer: string, signal?: AbortSignal) => {
				if (!answer) return null
				const prompts: ChatCompletionRequestMessage[] = [
					{
						role: "system",
						content: [
							"based on the answer, from 1 to 10 does this person need help from an expert? 1 is no and 10 is yes.",
							"# format: number"
						].join("\n")
					},
					{
						role: "user",
						content: "A: " + answer
					}
				]
				console.log(prompts)
				let r: any = await $fetch(app.$config.public.baseUrl + "/tools/chat", {
					method: "POST",
					signal: signal,
					body: {
						prompts
					},
					retry: 3
				})
				console.log(r.data)
				r = Number(r.data?.trim())
				if (!isNaN(r)) {
					return r
				}
				return null
			},
			handleAnswer: async (answer, signal?: AbortSignal) => {
				if (answer === null) {
					state.isNodePicked = true
					state.currentNodeId = "wrong-answer"
					return false
				} else if (answer) {
					state.todayFeelingScore = +answer
					if ((state.todayFeelingScore || 0) >= 7) {
						state.isNodePicked = true
						state.currentNodeId = "talk-to-expert"
						return false
					}
				}
				return true
			}
		},
		{
			id: "talk-to-expert",
			beforeSentences: () => [app.$i18n.t("assistant_talk_to_expert_message")],
			isBeforeSentencesBlocking: true,
			onEnd: async () => {
				await app.$router.push(app.$routeUrl.ExpertsUrl())
				state.mode = "off"
			},
			mode: "pick"
		},
		{
			id: "more-question",
			beforeSentences: () => [app.$i18n.t("assistant_more_question_message")],
			isBeforeSentencesBlocking: false,
			condition: () => !unAnsweredQuestion.value && !!nextQuestionGroup.value,
			question: {
				id: "more-question",
				title: app.$i18n.t("assistant_more_question_message"),
				answer_type: "boolean"
			},
			persistence: "session",
			transcriptPrompt: "the transcript means no or yes.",
			preFixAnswer: async (answer: string, signal?: AbortSignal) => {
				return await preFixBooleanQuestion(answer)
			},
			validateAnswer: function (answer: string, signal?: AbortSignal) {
				return validateQuestion(this.question!, answer, signal)
			},
			handleAnswer: async (answer, signal?: AbortSignal) => {
				if (answer === null) {
					state.isNodePicked = true
					state.currentNodeId = "wrong-answer"
					return false
				} else if (answer && nextQuestionGroup.value) {
					console.log(answer, !!answer)
					state.activeQuestionGroup = nextQuestionGroup.value
				}
				return true
			}
		},

		{
			id: "wrong-answer",
			beforeSentences: () => [app.$i18n.t("assistant_wrong_answer_message")],
			isBeforeSentencesBlocking: true,
			goTo: () => state.prevNodeId || "",
			mode: "pick"
		},
		{
			id: "you-answered-all",
			beforeSentences: () => [app.$i18n.t("assistant_you_answered_all_message")],
			isBeforeSentencesBlocking: true
		},
		{
			id: "generate-diet-schedule",
			beforeSentences: () => [app.$i18n.t("assistant_generate_diet_schedule_message")],
			isBeforeSentencesBlocking: true,
			afterSentences: () => [app.$i18n.t("assistant_generate_diet_schedule_message_after")],
			isAfterSentencesBlocking: true,
			condition: () => dietStore.needRegenerate,
			doTask: async (signal?: AbortSignal) => {
				await dietStore.generateDiet(signal)
				await dietStore.generateThreats(signal)
				return true
			}
		},
		{
			id: "go-to-tour",
			beforeSentences: () => [app.$i18n.t("assistant_go_to_tour_message")],
			end: true,
			isBeforeSentencesBlocking: true,
			doTask: async () => {
				state.isTourActive = true
				return true
			},
			goToConversation: () => "tour"
		}
	]
}

const dietConversation: ConversationFunction = () => {
	const state = useVoiceAssistantStore()
	const dietStore = useDietStore()
	const app = useNuxtApp()

	return [
		{
			id: "comment",
			beforeSentences: () => [app.$i18n.t("assistant_diet_comment_message")],
			end: true,
			question: {
				id: "has-question-about-diet",
				title: app.$i18n.t("assistant_diet_comment_message"),
				answer_type: "text"
			},
			transcriptPrompt: app.$i18n.t("assistant_diet_comment_message"),
			validateAnswer: async (answer: string, signal?: AbortSignal) => {
				if (!answer.trim()) return null
				return answer
			},
			handleAnswer: async (answer, signal?: AbortSignal) => {
				if (!answer) return
				try {
					await dietStore.addComment(answer, signal)
					return true
				} catch (e: any) {
					throw e
				}
				return false
			},
			afterSentences: () => [app.$i18n.t("assistant_diet_comment_message_after")],
			isAfterSentencesBlocking: true
		},
		{
			id: "wrong-answer",
			beforeSentences: () => [app.$i18n.t("assistant_wrong_answer_message")],
			isBeforeSentencesBlocking: true,
			goTo: () => state.prevNodeId || "",
			mode: "pick"
		}
	]
}

const exerciseConversation: ConversationFunction = () => {
	const state = useVoiceAssistantStore()
	const exerciseStore = useExerciseStore()
	const app = useNuxtApp()

	return [
		{
			id: "comment",
			beforeSentences: () => [app.$i18n.t("assistant_exercise_comment_message")],
			end: true,
			question: {
				id: "has-question-about-schedule",
				title: app.$i18n.t("assistant_exercise_comment_message"),
				answer_type: "text"
			},
			transcriptPrompt: app.$i18n.t("assistant_exercise_comment_message"),
			validateAnswer: async (answer: string, signal?: AbortSignal) => {
				if (!answer.trim()) return null
				return answer
			},
			handleAnswer: async (answer, signal?: AbortSignal) => {
				if (!answer) return
				try {
					await exerciseStore.addComment(answer, signal)
					return true
				} catch (e: any) {
					throw e
				}
				return false
			},
			afterSentences: () => [app.$i18n.t("assistant_exercise_comment_message_after")],
			isAfterSentencesBlocking: true
		},
		{
			id: "wrong-answer",
			beforeSentences: () => [app.$i18n.t("assistant_wrong_answer_message")],
			isBeforeSentencesBlocking: true,
			goTo: () => state.prevNodeId || "",
			mode: "pick"
		}
	]
}

const meditationConversation: ConversationFunction = () => {
	const state = useVoiceAssistantStore()
	const meditationStore = useMeditationStore()
	const app = useNuxtApp()

	return [
		{
			id: "comment",
			beforeSentences: () => [app.$i18n.t("assistant_meditation_comment_message")],
			end: true,
			question: {
				id: "has-question-about-schedule",
				title: app.$i18n.t("assistant_meditation_comment_message"),
				answer_type: "text"
			},
			transcriptPrompt: app.$i18n.t("assistant_meditation_comment_message"),
			validateAnswer: async (answer: string, signal?: AbortSignal) => {
				if (!answer.trim()) return null
				return answer
			},
			handleAnswer: async (answer, signal?: AbortSignal) => {
				if (!answer) return
				try {
					await meditationStore.addComment(answer, signal)
					return true
				} catch (e: any) {
					throw e
				}
				return false
			},
			afterSentences: () => [app.$i18n.t("assistant_meditation_comment_message_after")],
			isAfterSentencesBlocking: true
		},
		{
			id: "wrong-answer",
			beforeSentences: () => [app.$i18n.t("assistant_wrong_answer_message")],
			isBeforeSentencesBlocking: true,
			goTo: () => state.prevNodeId || "",
			mode: "pick"
		}
	]
}
