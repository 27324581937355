import validate from "/home/cgdev/vivai-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import fetch_45global from "/home/cgdev/vivai-web/middleware/fetch.global.ts";
import manifest_45route_45rule from "/home/cgdev/vivai-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  fetch_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-auth": () => import("/home/cgdev/vivai-web/middleware/admin-auth.ts"),
  "admin-noauth": () => import("/home/cgdev/vivai-web/middleware/admin-noauth.ts"),
  auth: () => import("/home/cgdev/vivai-web/middleware/auth.ts"),
  noauth: () => import("/home/cgdev/vivai-web/middleware/noauth.ts")
}