import { defineStore } from "pinia"
import { type UserEvent } from "~/models/user-event"
import { type Database } from "~/types/supabase"

export const useUserEventStore = defineStore("user-event", () => {
	const state = {
		events: ref<UserEvent[] | null>(null)
	}

	const supabase = useSupabaseClient<Database>()
	const user = useSupabaseUser()

	const getEvents = async () => {
		const events =
			(
				await supabase
					.from("user_events")
					.select()
					.order("date", { ascending: true })
					.order("time", { ascending: true })
					.eq("user_id", user.value?.id || '')
			).data || []
		state.events.value = events
		return events
	}

	const fetchEvents = async () => {
		if (state.events.value || !user.value?.id) return null
		return await getEvents()
	}

	const getEvent = async (id: string) => {
		let event = state.events.value?.find((v) => v.id === id) || null
		if (!event) {
			event = (await supabase.from("user_events").select().eq("id", id).single()).data
		}
		return event
	}

	const addEvent = async (formData: UserEvent) => {
		await supabase.from("user_events").insert({
			user_id: user.value?.id!,
			title: formData.title,
			date: formData.date,
			time: formData.time
		})
		await getEvents()
	}

	const editEvent = async (formData: UserEvent) => {
		await supabase
			.from("user_events")
			.update({
				title: formData.title,
				date: formData.date,
				time: formData.time
			})
			.eq("id", formData.id || '')
		await getEvents()
	}

	const deleteEvent = async (id: string) => {
		await supabase.from("user_events").delete().eq("id", id)
		await getEvents()
	}

	return {
		...state,
		getEvents,
		getEvent,
		addEvent,
		editEvent,
		deleteEvent,
		fetchEvents
	}
})
