<template>
	<BaseModal
		:visible="visible"
		:title="$t('genome_test_details')"
		:button-name="$t('save')"
		size="lg"
		:loading="state.loading"
		@button-click="save"
		@close="emit('close')"
	>
		<div class="grid grid-cols-1 gap-5 p-6 text-sm text-slate-800" :style="{ lineHeight: '2' }">
			<div class="flex flex-col">
				<span class="font-bold text-slate-900 me-4 whitespace-nowrap">
					{{ $t("candidate_variations") + " :" }}
				</span>
				<div v-auto-animate class="flex flex-col mt-3 space-y-3">
					<span
						v-for="variation in state.candidateVariants"
						:key="variation.id"
						class="flex rounded-lg overflow-hidden border border-slate-300 flex-shrink-0"
					>
						<input
							:value="variation.text"
							@input="(e: any) => editCandidateVariant(variation.id, e.target.value)"
							class="flex-grow px-3 py-2 text-sm text-slate-700 outline-none"
						/>
						<span
							class="ripple-bg-red-500 text-white flex-shrink-0 cursor-pointer"
							@click="deleteCandidateVariant(variation.id)"
						>
							<Icon name="mdi:delete" class="w-full h-full p-2" />
						</span>
					</span>
					<span
						class="flex w-10 h-10 ripple-bg-green-500 text-white cursor-pointer rounded-full mx-auto"
						@click="addCandidateVariant"
					>
						<Icon name="mdi:plus" class="w-full h-full p-2" />
					</span>
				</div>
			</div>
			<span class="border-b border-slate-200" />
			<BaseTextInput :label="$t('description')" v-model="state.description" type="textarea" class="" />
		</div>
	</BaseModal>
</template>

<script setup lang="ts">
import { v4 as uuid } from "uuid"
import type { GenomeTestOrder } from "~/models/genome-test-order"

const app = useNuxtApp()
const genomeTestOrderStore = useGenomeTestOrderStore()

const emit = defineEmits<{
	close: []
	done: [GenomeTestOrder]
}>()

const props = defineProps<{
	visible: boolean
	order: GenomeTestOrder
}>()

const state = reactive({
	loading: false,
	description: "",
	candidateVariants: [] as {
		id: string
		text: string
	}[]
})

watch(
	() => props.visible,
	() => {
		if (props.visible) {
			state.description = props.order.description || ""
			state.candidateVariants = [
				...(props.order.candidate_variants?.map((v) => ({
					id: uuid(),
					text: v
				})) || [])
			]
		}
	},
	{
		immediate: true
	}
)

const editCandidateVariant = (id: string, text: string) => {
	state.candidateVariants = state.candidateVariants.map((v) =>
		v.id == id
			? {
					...v,
					text
			  }
			: v
	)
}

const deleteCandidateVariant = (id: string) => {
	state.candidateVariants = state.candidateVariants.filter((v) => v.id != id)
}

const addCandidateVariant = () => {
	state.candidateVariants = [
		...state.candidateVariants,
		{
			id: uuid(),
			text: ""
		}
	]
}

const save = async () => {
	const description = state.description.trim()
	state.description = description
	const candidateVariants = state.candidateVariants.map((v) => ({ ...v, text: v.text.trim() })).filter((v) => v.text)
	state.candidateVariants = candidateVariants
	if (!description) {
		app.$toast.error({ message: app.$i18n.t("error_empty_description") })
		return
	}
	if (!candidateVariants.length) {
		app.$toast.error({ message: app.$i18n.t("error_empty_candidate_variation") })
		return
	}
	state.loading = true
	try {
		const r = await genomeTestOrderStore.updateOrder({
			id: props.order.id,
			status: props.order.status,
			taxonomic_levels: props.order.taxonomic_levels,
			description: description,
			candidate_variants: candidateVariants.map((v) => v.text)
		})
		app.$toast.success({ message: app.$i18n.t("genome_test_details_changed") })
		emit("done", r)
	} catch (e) {
		app.$toastError(e)
	}
	state.loading = false
}
</script>
