<template>
	<span @click="(e: any) => emit('click', e)">
		<component :is="iconComponents['my-' + props.name]" :class="iconClass" />
	</span>
</template>

<script setup lang="ts">
import { type IconType, icons } from "~/types/base-icons"

const iconComponents = {} as any
icons.forEach((name) => {
	const cName = name.replace(/-/g, "_")
	iconComponents[`my-${name}`] = defineAsyncComponent(() => import(`../../assets/icons/ic_${cName}.svg`))
})

type Props = {
	name: IconType
	iconClass?: unknown
}

const emit = defineEmits(["click"])

const props = defineProps<Props>()

onMounted(() => {
	if (!icons.includes(props.name)) {
		throw new Error(`icon with name '${props.name}' is not exist`)
	}
})
</script>
