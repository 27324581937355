export default () => {
	const app = useNuxtApp()

	const transcribe = async (file?: File | null, prompt?: string, signal?: AbortSignal) => {
		try {
			const formData = new FormData()
			if (file) {
				formData.set("file", file)
			}
			if (prompt) {
				formData.set("prompt", prompt)
			}
			formData.set("language_code", app.$i18n.localeProperties.value.code)
			const r = await $fetch<any>(app.$config.public.baseUrl + "/tools/transcribe", {
				method: "POST",
				signal: signal,
				body: formData
			})
			return String(r.data?.trim() || "")
		} catch (e: any) {
			app.$toastError(e)
			return null
		}
	}

	return {
		transcribe
	}
}
