<template>
	<div>
		<div
			class="relative flex flex-col overflow-hidden bg-white border border-grey-300 rounded-xl"
			:class="{}"
			@click="emit('click')"
			v-auto-animate
		>
			<div class="flex flex-col p-5">
				<div class="flex items-center mb-5">
					<div
						class="flex items-center rounded-md bg-opacity-10 p-0.5 duration-300"
						:class="{
							[status.bgClass]: true,
							'cursor-pointer hover:bg-opacity-20': admin
						}"
						@click.stop="emit('status')"
					>
						<Icon
							name="majesticons:test-tube-filled"
							class="w-5 h-5 text-white me-1.5 rounded-md p-0.5"
							:class="{ [status.bgClass]: true }"
						/>
						<span class="text-xs font-semibold me-1" :class="[status.txtClass]">
							{{ status.name || "~" }}
						</span>
						<Icon v-if="admin" name="mdi:pencil" class="w-5 h-5 ms-1.5 p-0.5" :class="[status.txtClass]" />
					</div>
					<div class="flex-grow" />
				</div>
				<div class="flex flex-wrap items-center -mb-2 -me-3">
					<div class="flex items-center mb-2 me-3">
						<span class="text-sm font-medium text-slate-400">
							{{ createdString }}
						</span>
					</div>
					<div class="flex flex-wrap items-center mb-2 me-3">
						<span class="w-1 h-1 rounded-full opacity-25 me-3 bg-slate-400" />
						<span class="text-xs text-slate-400 me-1.5 whitespace-nowrap">
							{{ $t("order_id") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.id }}
						</span>
					</div>
				</div>
			</div>
			<div class="flex flex-col">
				<div class="mx-5 border-b border-gray-200" />
				<div class="grid grid-cols-1 gap-5 p-5 sm:grid-cols-2">
					<div class="flex items-center">
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("first_name") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.user?.first_name || "~" }}
						</span>
					</div>
					<div class="flex items-center">
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("last_name") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.user?.last_name || "~" }}
						</span>
					</div>
					<div class="flex items-center">
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("email") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.user?.email || "~" }}
						</span>
					</div>
					<div class="flex items-center">
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("address") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.address || "~" }}
						</span>
					</div>
				</div>
			</div>
			<div class="flex flex-col">
				<div class="mx-5 border-b border-gray-200" />
				<div class="grid grid-cols-1 gap-5 p-5 sm:grid-cols-2">
					<div class="flex items-center">
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("type") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ type.title || "~" }}
						</span>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 gap-5 px-5 pb-5">
				<div class="flex items-center">
					<span class="text-xs text-slate-400">
						{{ $t("details") }}
					</span>
					<div class="border-t border-gray-200 flex-grow mx-5"></div>
					<span
						v-if="
							data?.description ||
							data?.candidate_variants?.length ||
							Object.keys(data?.taxonomic_levels || {}).length
						"
						class="me-3"
						@click="emit('show-details')"
					>
						<Icon
							name="mdi:eye"
							class="text-teal-500 bg-teal-500/10 hover:bg-teal-500/20 rounded-lg w-7 h-7 p-1 border border-teal-500 cursor-pointer duration-300"
						/>
					</span>
					<span @click="emit('edit-details')">
						<Icon
							name="mdi:pencil"
							class="text-orange-500 bg-orange-500/10 hover:bg-orange-500/20 rounded-lg w-7 h-7 p-1 border border-orange-500 cursor-pointer duration-300"
						/>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { type GenomeTestOrder } from "~/models/genome-test-order"

const app = useNuxtApp()
const genomeTestOrderStore = useGenomeTestOrderStore()

const emit = defineEmits(["click", "cancel", "more", "less", "status", "show-details", "edit-details"])
const props = defineProps<{
	data?: GenomeTestOrder
	detailed?: boolean
	admin?: boolean
}>()

const orderStatuses = computed(() => genomeTestOrderStore.statuses)

const status = computed(() => orderStatuses.value.find((v) => v.value === props.data?.status) || orderStatuses.value[0])

const createdString = computed(() => dayjs(props.data?.created_at, "YYYY/MM/DD").format("DD MMMM YYYY"))

const types = computed(() => [
	{
		id: "genome",
		title: app.$i18n.t("genome_test")
	},
	{
		id: "metagenome",
		title: app.$i18n.t("metagenome_test")
	}
])

const type = computed(() => types.value.find((v) => v.id == props.data?.type) || types.value[0])
</script>
