<template>
	<div class="flex flex-col"></div>
</template>

<script setup lang="ts">
import Shepherd from "shepherd.js"

import "shepherd.js/dist/css/shepherd.css"

const runtimeConfig = useRuntimeConfig()
const app = useNuxtApp()
const voiceAssistant = useVoiceAssistant()
const voiceAssistantStore = useVoiceAssistantStore()
const textToSpeech = useTextToSpeech()

const state = reactive({
	isIntroShown: false
})

const mainTour = ref<Shepherd.Tour>()
const introTour = ref<Shepherd.Tour>()
const steps = computed(() => [])

const initMainTour = () => {
	mainTour.value = new Shepherd.Tour({
		useModalOverlay: true,
		defaultStepOptions: {
			classes: "shadow-md bg-purple-dark",
			scrollTo: true
		}
	})
	mainTour.value.addStep({
		text: app.$i18n.t("assistant_tour_personalized_diet"),
		scrollTo: {
			block: "center"
		},
		canClickTarget: false,
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#your-diet-home-button")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say("click this button, and I will create a personalized diet program for you.")
			},
			complete: async () => {
				await app.$router.push(app.$routeUrl.DietUrl())
				mainTour.value?.next()
			}
		},
		attachTo: {
			element: "#your-diet-home-button",
			on: "bottom"
		},
		buttons: [
			{
				text: app.$i18n.t("create_diet"),
				action: async () => {
					await app.$router.push(app.$routeUrl.DietUrl())
					mainTour.value?.next()
				}
			}
		]
	})

	mainTour.value.addStep({
		text: app.$i18n.t("assistant_tour_personalized_diet_creation"),
		scrollTo: {
			block: "center"
		},
		canClickTarget: false,
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#diet-page")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say(app.$i18n.t("assistant_tour_personalized_diet_creation"))
			}
		},
		buttons: [
			{
				text: "Next",
				action: async () => {
					mainTour.value?.next()
				}
			}
		]
	})

	mainTour.value.addStep({
		text: app.$i18n.t("assistant_tour_diet_comment"),
		scrollTo: {
			block: "end"
		},
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#diet-page-chat-footer")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		canClickTarget: false,
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say(app.$i18n.t("assistant_tour_diet_comment"))
			}
		},
		attachTo: {
			element: "#diet-page-chat-footer",
			on: "top"
		},
		buttons: [
			{
				text: "Finish",
				action: async () => {
					mainTour.value?.next()
					await app.$router.push(app.$routeUrl.HomeUrl())
				}
			}
		]
	})

	mainTour.value.addStep({
		text: app.$i18n.t("assistant_tour_diet_tracker"),
		scrollTo: {
			block: "center"
		},
		canClickTarget: false,
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#your-diet-tracker-home-button")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say(app.$i18n.t("assistant_tour_diet_tracker"))
			}
		},
		attachTo: {
			element: "#your-diet-tracker-home-button",
			on: "bottom"
		},
		buttons: [
			{
				text: app.$i18n.t("diet_tracker"),
				action: async () => {
					await app.$router.push(app.$routeUrl.DietTrackerUrl())
					mainTour.value?.next()
				}
			}
		]
	})

	mainTour.value.addStep({
		text: app.$i18n.t("assistant_tour_diet_add"),
		scrollTo: {
			block: "center"
		},
		canClickTarget: false,
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#diet-tracker-page")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say(app.$i18n.t("assistant_tour_diet_add"))
			}
		},
		buttons: [
			{
				text: "Ok",
				action: async () => {
					textToSpeech.shutUp()
					await app.$router.push(app.$routeUrl.HomeUrl())
					mainTour.value?.next()
				}
			}
		]
	})

	mainTour.value.addStep({
		text: app.$i18n.t("assistant_tour_onboarding"),
		scrollTo: {
			block: "center"
		},
		canClickTarget: false,
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#health-information-home-button")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say(app.$i18n.t("assistant_tour_onboarding"))
			}
		},
		attachTo: {
			element: "#health-information-home-button",
			on: "bottom"
		},
		buttons: [
			{
				text: app.$i18n.t("health_information"),
				action: async () => {
					mainTour.value?.next()
					await app.$router.push(app.$routeUrl.OnboardingUrl())
				}
			}
		]
	})

	mainTour.value.addStep({
		text: app.$i18n.t("assistant_tour_blood_test"),
		scrollTo: {
			block: "center"
		},
		canClickTarget: false,
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#onboarding-item-blood-test")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say(app.$i18n.t("assistant_tour_blood_test"))
			}
		},
		attachTo: {
			element: "#onboarding-item-blood-test",
			on: "bottom"
		},
		buttons: [
			{
				text: app.$i18n.t("blood_test"),
				action: async () => {
					await app.$router.push(app.$routeUrl.ManageBloodTestsUrl())
					mainTour.value?.next()
				}
			}
		]
	})

	mainTour.value.addStep({
		text: app.$i18n.t("assistant_tour_bood_test_add"),
		scrollTo: {
			block: "center"
		},
		canClickTarget: false,
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#manage-blood-tests-page")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say(app.$i18n.t("assistant_tour_bood_test_add"))
			}
		},
		buttons: [
			{
				text: app.$i18n.t("ok"),
				action: async () => {
					await app.$router.push(app.$routeUrl.HomeUrl())
					mainTour.value?.next()
				}
			}
		]
	})

	const endEvents = ["complete", "cancel"]
	endEvents.forEach((event) =>
		mainTour.value?.on(event, () => {
			voiceAssistantStore.isTourActive = false
			if (event === "cancel") {
				textToSpeech.shutUp()
			}
		})
	)

	if (app.$config.public.dev) {
		// mainTour.value?.start()
		// app.$router.push(app.$routeUrl.HomeUrl())
	}
}

const initIntroTour = () => {
	introTour.value = new Shepherd.Tour({
		useModalOverlay: true,
		defaultStepOptions: {
			classes: "shadow-md bg-purple-dark",
			scrollTo: true
		}
	})

	introTour.value.addStep({
		text: app.$i18n.t("assistant_tour_welcome_text"),
		scrollTo: {
			block: "center"
		},
		canClickTarget: false,
		beforeShowPromise: () =>
			new Promise((resolve) => {
				const interval = setInterval(() => {
					if (document.querySelector("#home-page")) {
						clearInterval(interval)
						resolve(1)
					}
				}, 100)
			}),
		when: {
			show: async () => {
				textToSpeech.shutUp()
				await textToSpeech.say(app.$i18n.t("assistant_tour_welcome_text"))
			},
			complete: async () => {
				state.isIntroShown = true
			}
		},
		buttons: [
			{
				text: "Ok",
				action: async () => {
					introTour.value?.next()
				}
			}
		]
	})

	if (!voiceAssistantStore.isIntroduced) {
		introTour.value?.start()
	}
}

watch(
	() => voiceAssistantStore.isTourActive,
	(isTourActive) => {
		if (isTourActive) {
			setTimeout(() => {
				mainTour.value?.start()
				app.$router.push(app.$routeUrl.HomeUrl())
			}, 300)
		}
	}
)

watch(
	() => voiceAssistantStore.isIntroduced,
	(isIntroduced) => {
		if (isIntroduced) {
			introTour.value?.cancel()
		}
	}
)

onMounted(() => {
	initMainTour()
	initIntroTour()
})

onUnmounted(() => {
	mainTour.value?.cancel()
	introTour.value?.cancel()
})
</script>
