import { defineStore } from "pinia"
import { type Database } from "~/types/supabase"
import type { GenomeTestOrder } from "~/models/genome-test-order"
import type { User } from "~/models/user"

export const useGenomeTestOrderStore = defineStore("genome-test-order", () => {
	const supabase = useSupabaseClient<Database>()
	const app = useNuxtApp()

	const state = {
		orders: ref<GenomeTestOrder[] | null>(null),
		selectedCategoryId: ref<string | null>(null),
		statuses: ref([
			{
				name: app.$i18n.t("genome_test_order_status_created"),
				value: "created",
				borderClass: "border-gray-500",
				bgClass: "bg-gray-500",
				txtClass: "text-gray-500"
			},
			{
				name: app.$i18n.t("genome_test_order_status_processing"),
				value: "processing",
				borderClass: "border-yellow-500",
				bgClass: "bg-yellow-500",
				txtClass: "text-yellow-500"
			},
			{
				name: app.$i18n.t("genome_test_order_status_rejected"),
				value: "rejected",
				borderClass: "border-red-500",
				bgClass: "bg-red-500",
				txtClass: "text-red-500"
			},
			{
				name: app.$i18n.t("genome_test_order_status_done"),
				value: "done",
				borderClass: "border-green-500",
				bgClass: "bg-green-500",
				txtClass: "text-green-500"
			}
		])
	}

	const user = useSupabaseUser()

	const createOrder = async (data: { user_id: string; type: GenomeTestOrder["type"]; address: string }) => {
		const { error, data: result } = await supabase
			.from("genome_test_orders")
			.insert({
				user_id: data.user_id,
				status: "created",
				type: data.type,
				address: data.address
			})
			.select()
			.single()
		if (error) throw error
		if (data.user_id == user.value?.id) {
			state.orders.value = [result as GenomeTestOrder, ...(state.orders.value || [])]
		}
		return result as GenomeTestOrder
	}

	const updateOrder = async (data: {
		id: string
		candidate_variants: GenomeTestOrder["candidate_variants"]
		taxonomic_levels: GenomeTestOrder["taxonomic_levels"]
		description: GenomeTestOrder["description"]
		status: GenomeTestOrder["status"]
	}) => {
		const { error, data: result } = await supabase
			.from("genome_test_orders")
			.update({
				status: data.status,
				candidate_variants: data.candidate_variants,
				taxonomic_levels: data.taxonomic_levels,
				description: data.description
			})
			.eq("id", data.id)
			.select()
			.single()
		if (error) throw error
		return result as GenomeTestOrder
	}

	const fetchOrders = async () => {
		if (state.orders.value || !user.value?.id) return null
		return await getAllOrders({
			user_id: user.value?.id
		})
	}

	const getOrders = async (data: { user_id?: string; page?: number; limit?: number; status?: string }) => {
		const query = supabase.from("genome_test_orders").select(`
			*,
			profiles(
				id,
				first_name,
				last_name,
				email,
				type
			)
		`)
		if (data.user_id) query.eq("user_id", data.user_id)
		if (data.status) query.eq("status", data.status)
		if (data.page && data.limit) query.range((data.page - 1) * data.limit, data.page * data.limit)
		const { error, data: result } = await query
		if (error) throw error
		return result.map((v) => {
			const { profiles, ...order } = v
			return { ...order, user: v.profiles as User }
		}) as GenomeTestOrder[]
	}

	const getAllOrders = async (data: { user_id: string }) => {
		const query = supabase.from("genome_test_orders").select()
		if (data.user_id) query.eq("user_id", data.user_id)
		query.order("created_at", { ascending: false })
		const { error, data: result } = await query
		if (error) throw error
		state.orders.value = result as GenomeTestOrder[]
		return result as GenomeTestOrder[]
	}

	return {
		...state,
		fetchOrders,
		createOrder,
		updateOrder,
		getOrders,
		getAllOrders
	}
})
