<template>
	<AdminContainer selected="manage-shop-orders" :loading="state.loading" :fetched="true" @tryAgain="fetchData">
		<div class="flex flex-col mx-8 my-6">
			<div class="flex items-center mb-6">
				<span class="text-xl font-bold text-natural-dark me-4">
					{{ $t("shop_orders") }}
				</span>
				<div class="flex-grow" />
				<form @submit.prevent="search" class="relative">
					<input
						class="w-56 py-2 text-sm duration-300 bg-white border-2 border-gray-300 rounded-full outline-none appearance-none ps-4 pe-12 focus:border-secondary text-natural-dark"
						:placeholder="$t('search')"
						v-model="state.searchText"
					/>
					<BaseIcon
						name="search"
						class="absolute top-0 bottom-0 w-6 h-6 my-auto duration-300 cursor-pointer text-natural-mute end-0 me-3 hover:text-secondary"
						@click="search"
					/>
				</form>
			</div>
			<div class="flex items-center mt-4">
				<div class="flex items-center self-start px-3 py-1 bg-white border border-gray-200 rounded-md">
					<span class="text-xs border-gray-200 text-natural-dark pe-3 me-3 border-e">
						{{ $t("status") }}
					</span>
					<div class="flex flex-wrap items-center gap-2">
						<span
							v-for="(s, i) in statuses"
							:key="i"
							class="px-2 py-1 text-xs duration-300 border rounded-md cursor-pointer whitespace-nowrap"
							:class="{
								'border-opacity-100 bg-opacity-10': s.value === status.value,
								[status.borderClass]: s.value === status.value,
								[status.bgClass]: s.value === status.value,
								[status.txtClass]: s.value === status.value,
								'bg-opacity-0 border-opacity-0 text-natural-semidark ': s.value !== status.value
							}"
							@click="changeStatus(s.value)"
						>
							{{ s.name }}
						</span>
					</div>
				</div>
				<div class="flex-grow" />
			</div>
			<div class="flex flex-col gap-8 my-8">
				<ShopOrderCard
					v-for="order in state.orders"
					:key="order.id"
					:data="order"
					:detailed="state.targetOrder?.id === order.id"
					:admin="true"
					@status="openChangeStatusDialog(order)"
					@more="state.targetOrder = order"
					@less="state.targetOrder = null"
				/>
				<div
					v-if="!state.orders?.length"
					class="py-24 text-4xl font-black text-center text-gray-300 select-none"
				>
					{{ $t("nothing_found") }}
				</div>
			</div>
			<BasePagination
				:total="state.total"
				:limit="state.limit"
				:current="page"
				:link="$routeUrl.ManageShopOrdersPageUrl"
				class="self-center mt-4 mb-12"
			/>
			<ShopOrderChangeStatusDialog
				:visible="state.isChangeStatusDialogVisible"
				:data.sync="state.targetOrder"
				@close="state.isChangeStatusDialogVisible = false"
				@select="fetchData"
			/>
		</div>
	</AdminContainer>
</template>

<script setup lang="ts">
import { type ShopOrder } from "~/models/shop-order"

const shopStore = useShopStore()
const route = useRoute()
const app = useNuxtApp()

definePageMeta({
	middleware: ["admin-auth"]
})

const state = reactive({
	loading: true,
	fetched: false,
	orders: [] as ShopOrder[],
	total: 0,
	targetOrder: null as ShopOrder | null,
	isEditDialogVisible: false,
	isChangeStatusDialogVisible: false,
	isOrderImageDialogVisible: false,
	searchText: String(route.query.searchText || ""),
	limit: 15
})

watch(
	() => route.query,
	() => fetchData()
)

const page = computed(() => Math.max(Number(route.params.page) || 1, 1) || 1)

const statuses = computed(() => shopStore.statuses?.filter((v) => !["canceled"].includes(v.value)))

const qSearchText = computed(() => (route.query.searchText || "") as string)

const status = computed(() => statuses.value.find((v) => v.value == route.query.status) || statuses.value[0])

const fetchData = async () => {
	state.loading = true
	state.fetched = false
	try {
		const orders = await shopStore.getOrders({
			status: status.value.value as any
		})
		state.orders = orders
		state.fetched = true
	} catch (e) {
		app.$toastError(e)
	}
	state.loading = false
}

const search = () => {
	if (state.searchText != qSearchText.value || page.value != 1) {
		state.loading = true
		app.$router.replace({
			path: app.$routeUrl.ManageShopOrdersPageUrl(1),
			query: {
				searchText: state.searchText
			}
		})
	} else {
		fetchData()
	}
}

const openChangeStatusDialog = (order: ShopOrder) => {
	state.targetOrder = JSON.parse(JSON.stringify(order))
	state.isChangeStatusDialogVisible = true
}

const changeStatus = (v: any) => {
	if (status.value.value == v) return
	app.$router.replace({
		path: app.$routeUrl.ManageShopOrdersPageUrl(1),
		query: {
			...route.query,
			status: v
		}
	})
}

onMounted(() => {
	fetchData()
})
</script>
