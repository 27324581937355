import { type ShopOrder } from "./../models/shop-order"
import { defineStore } from "pinia"
import { type ShopProduct } from "~/models/shop-product"
import { type ShopCategory } from "~/models/shop-category"
import { type Database } from "~/types/supabase"

export const useShopStore = defineStore("shop", () => {
	const supabase = useSupabaseClient<Database>()
	const app = useNuxtApp()

	const state = {
		categories: ref<ShopCategory[] | null>(null),
		selectedCategoryId: ref<string | null>(null),
		statuses: ref([
			{
				name: app.$i18n.t("product_order_status_created"),
				value: "created",
				borderClass: "border-gray-500",
				bgClass: "bg-gray-500",
				txtClass: "text-gray-500"
			},
			{
				name: app.$i18n.t("product_order_status_processing"),
				value: "processing",
				borderClass: "border-yellow-500",
				bgClass: "bg-yellow-500",
				txtClass: "text-yellow-500"
			},
			{
				name: app.$i18n.t("product_order_status_packaging"),
				value: "packaging",
				borderClass: "border-purple-500",
				bgClass: "bg-purple-500",
				txtClass: "text-purple-500"
			},
			{
				name: app.$i18n.t("product_order_status_shipping"),
				value: "shipping",
				borderClass: "border-blue-500",
				bgClass: "bg-blue-500",
				txtClass: "text-blue-500"
			},
			{
				name: app.$i18n.t("product_order_status_canceled"),
				value: "canceled",
				borderClass: "border-red-500",
				bgClass: "bg-red-500",
				txtClass: "text-red-500"
			},
			{
				name: app.$i18n.t("product_order_status_rejected"),
				value: "rejected",
				borderClass: "border-red-500",
				bgClass: "bg-red-500",
				txtClass: "text-red-500"
			},
			{
				name: app.$i18n.t("product_order_status_delivered"),
				value: "delivered",
				borderClass: "border-green-500",
				bgClass: "bg-green-500",
				txtClass: "text-green-500"
			}
		])
	}

	const getCategories = async () => {
		let r1 = await $fetch<any>("https://www.themealdb.com/api/json/v1/1/categories.php", {
			method: "GET"
		})
		const categories = r1.categories?.map((v: any) => ({
			id: v.idCategory,
			title: v.strCategory,
			image: v.strCategoryThumb
		})) as ShopCategory[]
		state.categories.value = categories
		return categories || []
	}

	const getProducts = async (query?: { category_title?: string }) => {
		let r1 = await $fetch<any>("https://www.themealdb.com/api/json/v1/1/filter.php", {
			method: "GET",
			query: {
				c: query?.category_title
			}
		})
		const products = r1.meals?.map((v: any) => ({
			id: v.idMeal,
			title: v.strMeal,
			image: v.strMealThumb,
			unit_price: 1.8
		})) as ShopProduct[]
		return products || []
	}

	const getProduct = async (productId: string) => {
		let r = await $fetch<{
			meals: ShopProduct[]
		}>("https://www.themealdb.com/api/json/v1/1/lookup.php?i=" + productId, {
			method: "GET"
		})
		const products = r.meals?.map((v: any) => ({
			id: v.idMeal,
			title: v.strMeal,
			image: v.strMealThumb,
			category_title: v.strCategory,
			instructions: v.strInstructions,
			unit_price: 1.8
		})) as ShopProduct[]
		return products?.[0] || null
	}

	const createOrder = async (data: {
		user_id: string
		address: string
		mobile: string
		email: string
		items: ShopProduct[]
	}) => {
		const { error, data: result } = await supabase
			.from("shop_orders")
			.insert({
				user_id: data.user_id,
				status: "created",
				address: data.address,
				mobile: data.mobile,
				email: data.email,
				items: data.items
			})
			.select()
			.single()
		if (error) throw error
		return result
	}

	const updateOrder = async (data: {
		id: string
		status: Database["public"]["Tables"]["shop_orders"]["Row"]["status"]
	}) => {
		const { error, data: result } = await supabase
			.from("shop_orders")
			.update({
				status: data.status
			})
			.eq("id", data.id)
			.select()
			.single()
		if (error) throw error
		return result
	}

	const getOrders = async (data: { user_id?: string; page?: number; limit?: number; status?: string }) => {
		const query = supabase.from("shop_orders").select()
		if (data.user_id) query.eq("user_id", data.user_id)
		if (data.status) query.eq("status", data.status)
		if (data.page && data.limit) query.range((data.page - 1) * data.limit, data.page * data.limit)
		const { error, data: result } = await query
		if (error) throw error
		return result as ShopOrder[]
	}

	return {
		...state,
		getCategories,
		getProducts,
		getProduct,
		createOrder,
		updateOrder,
		getOrders
	}
})
