<template>
	<BaseModal
		:visible="visible"
		:title="$t('metagenome_test_details')"
		:button-name="$t('save')"
		size="lg"
		:loading="state.loading"
		@button-click="save"
		@close="emit('close')"
	>
		<div class="grid grid-cols-1 gap-5 p-6 text-sm text-slate-800" :style="{ lineHeight: '2' }">
			<template v-for="(type, i) in types" :key="i">
				<div class="flex flex-col">
					<span class="font-bold text-slate-900 me-4 whitespace-nowrap">
						{{ type.title + " :" }}
					</span>
					<div v-auto-animate class="flex flex-col mt-3 space-y-3">
						<span
							v-for="level in type.levels"
							:key="level.id"
							class="flex rounded-lg overflow-hidden border border-slate-300 flex-shrink-0"
						>
							<div class="grid grid-cols-2 flex-grow bg-slate-300 gap-px">
								<input
									:value="level.name"
									:placeholder="$t('name')"
									class="px-3 py-2 text-sm text-slate-700 outline-none col-span-1 text-center"
									@input="(e: any) => editLevel(type.id, {...level,  name: e.target.value })"
								/>

								<div class="relative flex items-center">
									<input
										:value="level.percent"
										:placeholder="$t('percent')"
										class="px-3 py-2 text-sm text-slate-700 outline-none w-full text-center"
										@input="(e: any) => editLevel(type.id, {...level,  percent: e.target.value })"
									/>
								</div>
								<input
									:value="level.normal_range_min"
									:placeholder="$t('min_normal')"
									class="px-3 py-2 text-sm text-slate-700 outline-none text-center"
									@input="(e: any) => editLevel(type.id, {...level,  normal_range_min: e.target.value })"
								/>
								<div class="relative flex items-center">
									<Icon
										name="mdi:arrow-right"
										class="rtl:rotate-180 absolute text-slate-400 w-4 h-4 bg-white -ms-2"
									/>
									<input
										:value="level.normal_range_max"
										:placeholder="$t('max_normal')"
										class="px-3 py-2 text-sm text-slate-700 outline-none w-full text-center"
										@input="(e: any) => editLevel(type.id, {...level,  normal_range_max: e.target.value })"
									/>
								</div>
							</div>
							<span
								class="ripple-bg-red-500 text-white flex-shrink-0 cursor-pointer"
								@click="deleteLevel(type.id, level.id)"
							>
								<Icon name="mdi:delete" class="w-10 h-full p-2" />
							</span>
						</span>
						<span
							class="flex w-10 h-10 ripple-bg-green-500 text-white cursor-pointer rounded-full mx-auto"
							@click="addLevel(type.id)"
						>
							<Icon name="mdi:plus" class="w-full h-full p-2" />
						</span>
					</div>
				</div>
				<span v-if="types.length !== i + 1" class="border-b border-slate-200" />
			</template>
		</div>
	</BaseModal>
</template>

<script setup lang="ts">
import { v4 as uuid } from "uuid"
import type { GenomeTestOrder } from "~/models/genome-test-order"

const app = useNuxtApp()
const genomeTestOrderStore = useGenomeTestOrderStore()

const emit = defineEmits<{
	close: []
	done: [GenomeTestOrder]
}>()

const props = defineProps<{
	visible: boolean
	order: GenomeTestOrder
}>()

const state = reactive({
	loading: false,
	description: "",
	taxonomicPhylumLevels: [] as {
		id: string
		name: string
		percent: string
		normal_range_min: string
		normal_range_max: string
	}[],
	taxonomicGenusLevels: [] as {
		id: string
		name: string
		percent: string
		normal_range_min: string
		normal_range_max: string
	}[]
})

const types = computed(
	() =>
		[
			{
				id: "phylum",
				title: app.$i18n.t("phylum"),
				levels: state.taxonomicPhylumLevels
			},
			{
				id: "genus",
				title: app.$i18n.t("genus"),
				levels: state.taxonomicGenusLevels
			}
		] as const
)

watch(
	() => props.visible,
	() => {
		if (props.visible) {
			state.taxonomicPhylumLevels = Object.keys(props.order.taxonomic_levels?.Phylum || {}).map((k) => ({
				id: uuid(),
				name: k,
				percent: props.order.taxonomic_levels?.Phylum?.[k]?.percent || "",
				normal_range_min: props.order.taxonomic_levels?.Phylum?.[k]?.normal_range?.min || "",
				normal_range_max: props.order.taxonomic_levels?.Phylum?.[k]?.normal_range?.max || ""
			}))
			state.taxonomicGenusLevels = Object.keys(props.order.taxonomic_levels?.Genus || {}).map((k) => ({
				id: uuid(),
				name: k,
				percent: props.order.taxonomic_levels?.Genus?.[k]?.percent || "",
				normal_range_min: props.order.taxonomic_levels?.Genus?.[k]?.normal_range?.min || "",
				normal_range_max: props.order.taxonomic_levels?.Genus?.[k]?.normal_range?.max || ""
			}))
		}
	},
	{
		immediate: true
	}
)

const editLevel = (
	type: "phylum" | "genus",
	item: {
		id: string
		name: string
		percent: string
		normal_range_min: string
		normal_range_max: string
	}
) => {
	if (type == "phylum") {
		state.taxonomicPhylumLevels = state.taxonomicPhylumLevels.map((v) => (v.id == item.id ? item : v))
	} else if (type == "genus") {
		state.taxonomicGenusLevels = state.taxonomicGenusLevels.map((v) => (v.id == item.id ? item : v))
	}
}

const deleteLevel = (type: "phylum" | "genus", id: string) => {
	if (type == "phylum") {
		state.taxonomicPhylumLevels = state.taxonomicPhylumLevels.filter((v) => v.id != id)
	} else if (type == "genus") {
		state.taxonomicGenusLevels = state.taxonomicGenusLevels.filter((v) => v.id != id)
	}
}

const addLevel = (type: "phylum" | "genus") => {
	if (type == "phylum") {
		state.taxonomicPhylumLevels = [
			...state.taxonomicPhylumLevels,
			{
				id: uuid(),
				name: "",
				percent: "",
				normal_range_min: "",
				normal_range_max: ""
			}
		]
	} else if (type == "genus") {
		state.taxonomicGenusLevels = [
			...state.taxonomicGenusLevels,
			{
				id: uuid(),
				name: "",
				percent: "",
				normal_range_min: "",
				normal_range_max: ""
			}
		]
	}
}

const save = async () => {
	if (
		(state.taxonomicPhylumLevels.length &&
			!state.taxonomicPhylumLevels?.filter((level) => level.name?.trim())?.length) ||
		(state.taxonomicGenusLevels.length &&
			!state.taxonomicGenusLevels?.filter((level) => level.name?.trim())?.length)
	) {
		app.$toast.error({ message: app.$i18n.t("error_empty_taxonomic_level") })
		return
	}
	state.loading = true
	try {
		const r = await genomeTestOrderStore.updateOrder({
			id: props.order.id,
			status: props.order.status,
			description: props.order.description,
			candidate_variants: props.order.candidate_variants,
			taxonomic_levels: {
				Phylum: Object.fromEntries(
					state.taxonomicPhylumLevels.map((level) => [
						level.name,
						{
							percent: level.percent,
							normal_range: { min: level.normal_range_min, max: level.normal_range_max }
						}
					])
				),
				Genus: Object.fromEntries(
					state.taxonomicGenusLevels.map((level) => [
						level.name,
						{
							percent: level.percent,
							normal_range: { min: level.normal_range_min, max: level.normal_range_max }
						}
					])
				)
			}
		})
		app.$toast.success({ message: app.$i18n.t("genome_test_details_changed") })
		emit("done", r)
	} catch (e) {
		app.$toastError(e)
	}
	state.loading = false
}
</script>
