<template>
	<BaseModal
		:visible="visible"
		:title="$t('change_n', { name: $t('status') })"
		size="2xl"
		@close="emit('close')"
		:loading="state.loading"
		@button-click="submit"
		:button-name="$t('save')"
	>
		<div class="flex flex-col">
			<div class="flex flex-col px-6 pt-6 pb-2">
				<div
					v-for="(status, i) in statuses"
					:key="i"
					class="flex items-center px-6 py-3 mb-4 text-sm duration-300 bg-opacity-0 border-2 rounded-lg cursor-pointer hover:bg-opacity-10"
					@click="select(status.value)"
					:class="{
						[status.bgClass]: status.value === state.selectedStatus,
						[status.borderClass]: status.value === state.selectedStatus,
						[status.txtClass]: status.value === state.selectedStatus,
						'border-gray-300 bg-gray-300 text-gray-500': status.value !== state.selectedStatus
					}"
				>
					{{ status.name }}
				</div>
			</div>
		</div>
	</BaseModal>
</template>

<script setup lang="ts">
import type { GenomeTestOrder } from "~/models/genome-test-order"

const genomeTestOrderStore = useGenomeTestOrderStore()
const app = useNuxtApp()

const emit = defineEmits(["close", "select"])

const props = defineProps<{
	visible: boolean
	data: GenomeTestOrder | null
}>()

const state = reactive({
	loading: false,
	selectedStatus: ""
})

const statuses = computed(() => genomeTestOrderStore.statuses)

watch(
	() => props.visible,
	() => (state.selectedStatus = props.data?.status || "")
)

watch(
	() => props.data,
	() => (state.selectedStatus = props.data?.status || "")
)

const select = (status: string) => {
	state.selectedStatus = status
}

const submit = async () => {
	state.loading = true
	try {
		await genomeTestOrderStore.updateOrder({
			id: props.data?.id!!,
			status: state.selectedStatus as any,
			description: props.data?.description || "",
			candidate_variants: props.data?.candidate_variants || [],
			taxonomic_levels: props.data?.taxonomic_levels || {
				Phylum: {},
				Genus: {}
			}
		})
		app.$toast.success({
			message: app.$i18n.t("n_changed", {
				name: app.$i18n.t("status")
			})
		})
		emit("select", state.selectedStatus)
		emit("close")
	} catch (e) {
		app.$toastError(e)
	}
	state.loading = false
}

onMounted(() => {
	state.selectedStatus = props.data?.status || ""
})
</script>
