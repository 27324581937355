import { type PostgrestBuilder } from "@supabase/postgrest-js"
import { type Database } from "~/types/supabase"

export default () => {
	const supabase = useSupabaseClient<Database>()
	return {
		refreshMiddleware: async <Result, ResultOne = Result extends (infer ResultOne)[] ? ResultOne : never>(
			query: PostgrestBuilder<ResultOne>
		) => {
			let r = await query
			if (r.status == 401) {
				const { error } = await supabase.auth.refreshSession()
				if (error) {
					return {
						...r,
						error
					}
				}
				r = await query
			}
			return r
		}
	}
}
