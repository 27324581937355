import { defineStore } from "pinia"
import { type Meal } from "~/models/meal"
import { type DiseaseThreat } from "~/models/disease-threat"
import { type IconType } from "~/types/base-icons"
import { type Database } from "~/types/supabase"
import { type TrackerFood } from "~/models/tracker-food"

export const useDietStore = defineStore("diet", () => {
	const state = {
		dbRowId: ref<string | null>(null),
		meals: ref<Meal[] | null>(null),
		needRegenerate: ref<boolean>(true),
		diseaseThreats: ref<DiseaseThreat[] | null>(null),
		userComments: ref<string[] | null>(null),
		agentComments: ref<string[] | null>(null),
		dayEvents: [
			{
				title: "Morning",
				icon: "sunrise",
				from: 6,
				to: 12
			},
			{
				title: "Noon",
				icon: "afternoon",
				from: 12,
				to: 14
			},
			{
				title: "Evening",
				icon: "sunset",
				from: 14,
				to: 20
			},
			{
				title: "Night",
				icon: "night",
				from: 20,
				to: 6
			}
		] as {
			title: string
			icon: IconType
			from: number
			to: number
		}[]
	}

	const questions = useQuestions()
	const infoStore = useInfoStore()
	const sampleStore = useSampleStore()
	const supabase = useSupabaseClient<Database>()
	const user = useSupabaseUser()
	const app = useNuxtApp()
	const openai = useOpenAI()

	const info = computed(() => infoStore.info)

	const getDiet = async () => {
		const diet = (
			await supabase
				.from("user_meals")
				.select()
				.eq("user_id", user.value?.id || "")
		).data?.[0]
		state.dbRowId.value = diet?.id || null
		state.meals.value = (diet?.meals || []) as Meal[]
		state.diseaseThreats.value = (diet?.disease_threats || []) as DiseaseThreat[]
		const agentComments = (diet?.agent_comments || []) as string[]
		const userComments = (diet?.user_comments || []) as string[]
		state.agentComments.value = agentComments
		state.userComments.value = userComments.length > agentComments.length ? userComments.slice(0, -1) : userComments
		if (diet?.meals?.length && diet?.disease_threats?.length) {
			state.needRegenerate.value = [null, undefined].includes(diet?.need_regenerate as any)
				? true
				: diet?.need_regenerate || false
		} else {
			state.needRegenerate.value = true
		}
	}

	const fetchDiet = async () => {
		if (state.meals.value || !user.value?.id) return null
		return await getDiet()
	}

	const fixDietData = (data: any) => {
		data = data.meals || data
		return data
	}

	const generateDiet = async (signal?: AbortSignal) => {
		const newDiet: Meal[] = await openai.chat({
			prompts: [
				{
					role: "system",
					content: [
						[
							"You are a personalized diet planner. you will generate a diet program for a person based on genetic/genes mutation, gut microbiome analysis, medical profile, info and comments",
							"output is in JSON format. an array of meals of the day",
							"Minify and compress output to reduce output length",
							`Diet program is in ${app.$i18n.localeProperties.value.name} language`
						].join("\n"),
						["Output Object:", "- fields: meals", "- meals is an array of Meal"].join("\n"),
						[
							"Meal Object:",
							"- fields: name, range, foods",
							"- name is the name of the meal",
							"- range is the range of the meal period. it's an array of two number. start and end",
							"- foods is an array of Foods in the meal"
						].join("\n"),
						[
							"Food Object:",
							"- fields: name, ingredients",
							"- name is the name of the food",
							"- ingredients is an array of Ingredients in the food"
						].join("\n"),
						[
							"Ingredient Object:",
							"- fields name, amount_value, amount_unit, calories",
							"- name is the name of the ingredient",
							"- amount_value is the amount of ingredient that is used",
							"- amount_unit is the unit of the amount of ingredient that is used",
							"- calories is the calories of the ingredient"
						].join("\n")
					]
						.filter((v) => v)
						.join("\n\n")
				},
				{
					role: "user",
					content: [
						"generate a personalized diet program for me based on gut microbiome analysis, medical profile, info and comments",
						infoStore.questionsPromptPart && ["My Info:", infoStore.questionsPromptPart].join("\n"),
						sampleStore.selectedPerson?.description &&
							["My Medical Profile:", "- " + sampleStore.selectedPerson?.description]
								.filter((v) => v)
								.join("\n"),
						sampleStore.selectedSamplePromptPart &&
							[
								"My Gut Microbiome Analysis (Taxonomic Level):",
								sampleStore.selectedSamplePromptPart
							].join("\n"),
						state.userComments.value?.length &&
							["My Comments About The Diet:", ...state.userComments.value?.map((v) => "- " + v)].join(
								"\n"
							)
					]
						.filter((v) => v)
						.join("\n\n")
				}
			],
			temperature: 1,
			response_format: "json_object",
			model: "gpt-4",
			transform: fixDietData,
			signal
		})
		await updateDiet({
			meals: newDiet,
			need_regenerate: false
		})
	}

	const fixThreatsData = (data: any) => {
		data = data.disease_threats || data.diseases || data
		return data
	}

	const generateThreats = async (signal?: AbortSignal) => {
		const diseaseThreats: DiseaseThreat[] = await openai.chat({
			prompts: [
				{
					role: "system",
					content: [
						[
							"You are a doctor. you will give a list of disease threats for a patient",
							"output is in JSON format. an array of diseases",
							"Minify and compress output to reduce output length",
							`Disease Threats are in ${app.$i18n.localeProperties.value.name} language`
						].join("\n"),
						["Output Object:", "- fields: diseases", "- diseases is an array of Disease"].join("\n"),
						[
							"Disease Object:",
							"- fields: name, description, reason",
							"- name is the name of the disease threat",
							"- description is the description of the disease threat",
							"- reason is the reason for the threat"
						].join("\n")
					]
						.filter((v) => v)
						.join("\n\n")
				},
				{
					role: "user",
					content: [
						"give list of my disease threats",
						infoStore.questionsPromptPart && ["My Info:", infoStore.questionsPromptPart].join("\n"),
						sampleStore.selectedPerson?.description &&
							["My Medical Profile:", "- " + sampleStore.selectedPerson?.description]
								.filter((v) => v)
								.join("\n"),
						sampleStore.selectedSamplePromptPart &&
							[
								"My Gut Microbiome Analysis (Taxonomic Level):",
								sampleStore.selectedSamplePromptPart
							].join("\n")
					]
						.filter((v) => v)
						.join("\n\n")
				}
			],
			temperature: 1,
			response_format: "json_object",
			model: "gpt-4",
			transform: fixThreatsData,
			signal
		})
		await updateDiet({
			disease_threats: diseaseThreats
		})
	}

	const addComment = async (comment: string, signal?: AbortSignal) => {
		try {
			const userComments = [...(state.userComments.value || []), comment]
			state.userComments.value = userComments
			await updateDiet({
				user_comments: userComments
			})
			const newMeals: Meal[] = await openai.chat({
				prompts: [
					{
						role: "system",
						content: [
							[
								"You are a diet planner. you have to update a diet program based on user last comment",
								"output is in JSON format. an array of meals of the day",
								"Minify and compress output to reduce output length",
								`Diet program is in ${app.$i18n.localeProperties.value.name} language`
							].join("\n"),
							["Output Object:", "- fields: meals", "- meals is an array of Meal"].join("\n"),
							[
								"Meal Object:",
								"- fields: name, range, foods",
								"- name is the name of the meal",
								"- range is the range of the meal period. it's an array of two number. start and end",
								"- foods is an array of Foods in the meal"
							].join("\n"),
							[
								"Food Object:",
								"- fields: name, ingredients",
								"- name is the name of the food",
								"- ingredients is an array of Ingredients in the food"
							].join("\n"),
							[
								"Ingredient Object:",
								"- fields name, amount_value, amount_unit, calories",
								"- name is the name of the ingredient",
								"- amount_value is the amount of ingredient that is used",
								"- amount_unit is the unit of the amount of ingredient that is used",
								"- calories is the calories of the ingredient"
							].join("\n")
						]
							.filter((v) => v)
							.join("\n\n")
					},
					{
						role: "user",
						content: [
							"update my personalized diet program",
							"Base Diet Program (in json):",
							JSON.stringify(state.meals.value),
							infoStore.questionsPromptPart && ["My Info:", infoStore.questionsPromptPart].join("\n"),
							sampleStore.selectedPerson?.description &&
								["My Medical Profile:", "- " + sampleStore.selectedPerson?.description]
									.filter((v) => v)
									.join("\n"),
							sampleStore.selectedSamplePromptPart &&
								[
									"My Gut Microbiome Analysis (Taxonomic Level):",
									sampleStore.selectedSamplePromptPart
								].join("\n"),
							userComments.length &&
								["My Comments About The Diet:", ...userComments.map((v) => "- " + v)].join("\n")
						]
							.filter((v) => v)
							.join("\n\n")
					}
				],
				temperature: 1,
				response_format: "json_object",
				model: "gpt-4",
				transform: fixDietData,
				signal
			})
			const oldMeals: Meal[] = JSON.parse(JSON.stringify(state.meals.value))
			const message = await openai.chat({
				prompts: [
					{
						role: "system",
						content: [
							"there is an old diet plan. user comment on it and a planner updated the diet to new plan",
							"you have to wright a short answer from the planner to user about what is changed",
							"output the answer without any title",
							"don't write more than 16 words.",
							`message is in ${app.$i18n.localeProperties.value.name} language`
						]
							.filter((v) => v)
							.join("\n\n")
					},
					{
						role: "user",
						content: [
							["My Comment:", userComments[userComments.length - 1]].join("\n"),
							["Old Diet Program (in json):", JSON.stringify(oldMeals)].join("\n"),
							["New Diet Program (in json):", JSON.stringify(newMeals)].join("\n")
						]
							.filter((v) => v)
							.join("\n\n")
					}
				],
				temperature: 1,
				response_format: "text",
				model: "gpt-4",
				signal
			})
			await updateDiet({
				meals: newMeals,
				agent_comments: [...(state.agentComments.value || []), message]
			})
		} catch (e) {
			if ((state.userComments.value?.length || 0) > (state.agentComments.value?.length || 0)) {
				state.userComments.value = state.userComments.value?.slice(0, -1) || []
			}
			throw e
		}
	}

	const updateDiet = async (data: {
		meals?: Meal[]
		need_regenerate?: boolean
		disease_threats?: DiseaseThreat[]
		user_comments?: string[]
		agent_comments?: string[]
	}) => {
		if (state.dbRowId.value) {
			await supabase
				.from("user_meals")
				.update(Object.fromEntries(Object.entries(data).filter((v) => v[1] !== undefined)))
				.eq("user_id", user.value?.id || "")
		} else {
			await supabase.from("user_meals").insert({
				user_id: user.value?.id!,
				...Object.fromEntries(Object.entries(data).filter((v) => v[1] !== undefined))
			})
		}
		await getDiet()
	}

	const getTrackerFoods = async (date: string) => {
		return (
			await supabase
				.from("user_tracker_foods")
				.select()
				.eq("user_id", user.value?.id || "")
				.eq("eaten_at", date)
		).data as TrackerFood[]
	}

	const updateTrackerFood = async (trackerFood: TrackerFood) => {
		if (trackerFood.id) {
			const { error } = await supabase
				.from("user_tracker_foods")
				.update({
					eaten_amount: trackerFood.eaten_amount,
					eaten_amount_unit: trackerFood.eaten_amount_unit,
					eaten_calories: trackerFood.eaten_calories,
					eaten_at: trackerFood.eaten_at,
					eaten_at_time: trackerFood.eaten_at_time
				})
				.eq("id", trackerFood.id)
			if (error) throw error
		} else {
			const { error } = await supabase.from("user_tracker_foods").insert({
				user_id: trackerFood.user_id,
				food: trackerFood.food,
				eaten_amount: trackerFood.eaten_amount,
				eaten_amount_unit: trackerFood.eaten_amount_unit,
				eaten_calories: trackerFood.eaten_calories,
				eaten_at: trackerFood.eaten_at,
				eaten_at_time: trackerFood.eaten_at_time
			})
			if (error) throw error
		}
	}

	return {
		...state,
		getDiet,
		fetchDiet,
		generateDiet,
		generateThreats,
		addComment,
		updateDiet,
		getTrackerFoods,
		updateTrackerFood
	}
})
