export default {
	LandingUrl: () => "/",
	StartUrl: () => "/vivai",
	TestUrl: () => "/test",
	HomeUrl: () => "/home",
	ManageTestsUrl: () => "/manage-tests",
	OnboardingUrl: () => "/onboarding",
	DietUrl: () => "/diet",
	DietTrackerUrl: () => "/diet-tracker",
	ExerciseUrl: () => "/exercise",
	ExerciseTrackerUrl: () => "/activity/exercise-tracker",
	ExerciseDoUrl: () => "/exercise-do",
	MeditationUrl: () => "/meditation",
	WorkoutUrl: () => "/workout",
	WorkoutQuestionsUrl: () => "/workout",
	MeditationTrackerUrl: () => "/activity/meditation-tracker",
	ExpertsUrl: () => "/experts",
	ExpertsSearchUrl: () => "/experts/search",
	EditExpertProfileUrl: () => "/experts/edit-profile",
	ExpertUrl: (expertId: string) => `/experts/${expertId}`,
	ManageBloodTestsUrl: () => "/manage-blood-tests",
	AddBloodTestUrl: () => "/manage-blood-tests/add",
	ManageMedicinesUrl: () => "/manage-medicines",
	AddMedicinetUrl: () => "/manage-medicines/add",
	RequestNewTestUrl: () => "/request-new-test",
	LoginUrl: () => "/auth/login",
	RegisterUrl: () => "/auth/register",
	ProfileUrl: () => "/profile",
	NotificationsUrl: () => "/notifications",
	IntegrationUrl: () => "/integration",
	InsightUrl: () => "/insight",
	ShopUrl: () => "/shop",
	ShopPaymentUrl: () => "/shop/payment",
	ShopOrderConfirmationUrl: () => "/shop/order-confirmation",
	ShopCartUrl: () => "/shop/cart",
	ShopOrdersUrl: () => "/shop/orders",
	ShopProductUrl: (productId: string) => `/shop/product/${productId}`,
	ShopOrderDoneUrl: () => `/shop/order-done`,
	PrivacyPolicyUrl: () => `/page/privacy-policy`,
	TermsOfServiceUrl: () => `/page/terms-of-service`,
	// events
	ManageEventsUrl: () => "/home?page=1",
	AddEventUrl: () => "/manage-events/add",
	// admin
	AdminLoginUrl: () => `/admin/login`,
	AdminDashboardUrl: () => `/admin`,
	// admin users
	ManageUsersUrl: () => `/admin/users`,
	ManageUsersPageUrl: (page: number) => `/admin/users/page/${page}`,
	// admin shop orders
	ManageShopOrdersUrl: () => `/admin/shop-orders`,
	ManageShopOrdersPageUrl: (page: number) => `/admin/shop-orders/page/${page}`,
	// admin test requests
	ManageTestRequestsUrl: () => `/admin/test-requests`,
	ManageTestRequestsPageUrl: (page: number) => `/admin/test-requests/page/${page}`
}
