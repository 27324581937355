export default [
	{
		id: "age",
		name: { en: "Age", tr: "İsim", es: "Nombre", ar: "الاسم" },
		title: { en: "What is your age?", tr: "Yaşınız nedir?", es: "¿Cuál es tu edad?", ar: "ما هو عمرك؟" },
		answer_type: "number",
		group: "onboarding",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "gender",
		name: { en: "Gender", tr: "Cinsiyet", es: "Género", ar: "الجنس" },
		title: { en: "What is your gender?", tr: "Cinsiyetiniz nedir?", es: "¿Cuál es tu género?", ar: "ما هو جنسك؟" },
		answer_type: "select",
		options: { en: ["male", "female"], tr: ["erkek", "kadın"], es: ["hombre", "mujer"], ar: ["ذكر", "أنثى"] },
		group: "onboarding"
	},
	{
		id: "had-diabetes",
		name: {
			en: "Diagnosed With Diabetes",
			tr: "Diyabet Tanısı Konuldu",
			es: "Diagnóstico de Diabetes",
			ar: "تم تشخيصك بداء السكري"
		},
		title: {
			en: "Have you ever been diagnosed with diabetes?",
			tr: "Diyabet tanısı konuldu mu?",
			es: "¿Alguna vez ha sido diagnosticado con diabetes?",
			ar: "هل تم تشخيصك بداء السكري؟"
		},
		answer_type: "boolean",
		group: "onboarding",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "diabetes-type",
		name: { en: "Diabetes Type", tr: "Diyabet Tipi", es: "Tipo de Diabetes", ar: "نوع السكري" },
		title: {
			en: "If yes, what type of diabetes were you diagnosed with?",
			tr: "Evet ise, hangi tip diyabet teşhisi konuldu?",
			es: "Si es así, ¿qué tipo de diabetes le diagnosticaron?",
			ar: "إذا كان الجواب بنعم، ما نوع السكري الذي تم تشخيصك به؟"
		},
		answer_type: "select",
		options: {
			en: ["type 1", "type 2", "gestational diabetes"],
			tr: ["tip 1", "tip 2", "gestasyonel diyabet"],
			es: ["tipo 1", "tipo 2", "diabetes gestacional"],
			ar: ["النوع 1", "النوع 2", "السكري التكيسي"]
		},
		depends_on_prev: true,
		group: "onboarding"
	},
	{
		id: "weight",
		name: { en: "Weight", tr: "Ağırlık", es: "Peso", ar: "الوزن" },
		title: {
			en: "What is your current weight (in pounds or kilograms)?",
			tr: "Şu anki ağırlığınız nedir (pound veya kilogram cinsinden)?",
			es: "¿Cuál es su peso actual (en libras o kilogramos)?",
			ar: "ما هو وزنك الحالي (بالجنيهات أو الكيلوجرامات)؟"
		},
		answer_type: "number",
		units: {
			en: ["Pounds", "Kilograms"],
			tr: ["Paunt", "Kilogram"],
			es: ["Libras", "Kilogramos"],
			ar: ["رطل", "كيلوجرام"]
		},
		group: "onboarding",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "height",
		name: { en: "Height", tr: "Boy", es: "Altura", ar: "الطول" },
		title: {
			en: "What is your current height (in inches or centimeters)?",
			tr: "Şu anki boyunuz nedir (inç veya santimetre cinsinden)?",
			es: "¿Cuál es su altura actual (en pulgadas o centímetros)?",
			ar: "ما هو طولك الحالي (بالبوصات أو السنتيمترات)؟"
		},
		answer_type: "number",
		units: {
			en: ["Inches", "Centimeters"],
			tr: ["İnç", "Santimetre"],
			es: ["Pulgadas", "Centímetros"],
			ar: ["بوصة", "سنتيمتر"]
		},
		group: "onboarding",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "physical-activity-level",
		name: {
			en: "Physical Activity Level",
			tr: "Fiziksel Aktivite Düzeyi",
			es: "Nivel de Actividad Física",
			ar: "مستوى النشاط البدني"
		},
		title: {
			en: "How would you describe your physical activity level?",
			tr: "Fiziksel aktivite düzeyinizi nasıl tanımlarsınız?",
			es: "¿Cómo describiría su nivel de actividad física?",
			ar: "كيف تصف مستوى نشاطك البدني؟"
		},
		answer_type: "select",
		options: {
			en: ["low", "moderate", "high"],
			tr: ["düşük", "orta", "yüksek"],
			es: ["bajo", "moderado", "alto"],
			ar: ["منخفض", "متوسط", "عالي"]
		},
		group: "onboarding"
	},
	{
		id: "sleep-hours",
		name: {
			en: "Average Hours Of Sleep",
			tr: "Ortalama Uyku Saatleri",
			es: "Horas Promedio de Sueño",
			ar: "متوسط ساعات النوم"
		},
		title: {
			en: "On average, how many hours of sleep do you get each night?",
			tr: "Ortalama olarak, her gece kaç saat uyuyorsunuz?",
			es: "En promedio, ¿cuántas horas de sueño tiene cada noche?",
			ar: "في المتوسط، كم ساعة من النوم تحصل عليها كل ليلة؟"
		},
		answer_type: "select",
		options: {
			en: ["less than 7 hours", "7-8 hours", "more than 8 hours"],
			tr: ["7 saatten az", "7-8 saat", "8 saatten fazla"],
			es: ["menos de 7 horas", "7-8 horas", "más de 8 horas"],
			ar: ["أقل من 7 ساعات", "7-8 ساعات", "أكثر من 8 ساعات"]
		},
		group: "onboarding"
	},
	{
		id: "use-tobacco",
		name: {
			en: "Tobacco Use Or Smoking",
			tr: "Tütün Kullanımı veya Sigara İçme",
			es: "Uso de Tabaco o Fumar",
			ar: "استخدام التبغ أو التدخين"
		},
		title: {
			en: "Do you smoke cigarettes or use tobacco products?",
			tr: "Sigara içiyor musunuz veya tütün ürünleri kullanıyor musunuz?",
			es: "¿Fuma cigarrillos o usa productos de tabaco?",
			ar: "هل تدخن السجائر أو تستخدم منتجات التبغ؟"
		},
		answer_type: "boolean",
		group: "onboarding",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "family-had-diabetes",
		name: {
			en: "Family Diagnosed With Diabetes",
			tr: "Diyabet Tanılı Aile Üyeleri",
			es: "Familiares Diagnosticados con Diabetes",
			ar: "العائلة التي تم تشخيصها بالسكري"
		},
		title: {
			en: "Have any of your close family members (Parents, Siblings, Children, Grandparents, Grandchildren) been diagnosed with diabetes?",
			tr: "Yakın aile üyelerinizden (ebeveynler, kardeşler, çocuklar, büyükanne ve büyükbabalar) herhangi birinin diyabet teşhisi konuldu mu?",
			es: "¿Alguno de sus familiares cercanos (padres, hermanos, hijos, abuelos, nietos) ha sido diagnosticado con diabetes?",
			ar: "هل تم تشخيص أي من أفراد عائلتك المقربين (الوالدين، الأشقاء، الأطفال، الأجداد، الأحفاد) بمرض السكري؟"
		},
		answer_type: "boolean",
		group: "onboarding",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "unusual-thirst",
		name: { en: "Unusual Thirst", tr: "Olağandışı Susuzluk", es: "Sed Inusual", ar: "العطش غير المعتاد" },
		title: {
			en: "Have you experienced any unusual thirst lately?",
			tr: "Son zamanlarda olağandışı bir susuzluk yaşadınız mı?",
			es: "¿Ha experimentado sed inusual últimamente?",
			ar: "هل شعرت بالعطش غير المعتاد في الآونة الأخيرة؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "unusual-hunger",
		name: { en: "Unusual Hunger", tr: "Olağandışı Açlık", es: "Hambre Inusual", ar: "الجوع غير المعتاد" },
		title: {
			en: "Have you experienced any unusual hunger lately?",
			tr: "Son zamanlarda olağandışı bir açlık yaşadınız mı?",
			es: "¿Ha experimentado hambre inusual últimamente?",
			ar: "هل شعرت بالجوع غير المعتاد في الآونة الأخيرة؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "unusual-weight-loss",
		name: {
			en: "Unusual Weight Loss",
			tr: "Olağandışı Kilo Kaybı",
			es: "Pérdida de Peso Inusual",
			ar: "فقدان الوزن غير المعتاد"
		},
		title: {
			en: "Have you experienced any unusual weight loss lately?",
			tr: "Son zamanlarda olağandışı bir kilo kaybı yaşadınız mı?",
			es: "¿Ha experimentado pérdida de peso inusual últimamente?",
			ar: "هل شعرت بفقدان وزن غير معتاد في الآونة الأخيرة؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "frequent-urination",
		name: { en: "Frequent Urination", tr: "Sık Sık İdrara Çıkma", es: "Micción Frecuente", ar: "التبول المتكرر" },
		title: {
			en: "Have you experienced any frequent urination lately?",
			tr: "Son zamanlarda sık sık idrara çıkma sorunu yaşadınız mı?",
			es: "¿Ha experimentado micción frecuente últimamente?",
			ar: "هل شعرت بالتبول المتكرر في الآونة الأخيرة؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "blurred-vision",
		name: { en: "Blurred Vision", tr: "Bulanık Görme", es: "Visión Borrosa", ar: "الرؤية الغير واضحة" },
		title: {
			en: "Have you experienced any blurred vision lately?",
			tr: "Son zamanlarda bulanık görme sorunu yaşadınız mı?",
			es: "¿Ha experimentado visión borrosa últimamente?",
			ar: "هل شعرت برؤية غير واضحة في الآونة الأخيرة؟"
		},
		answer_type: "boolean",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "tingling",
		name: {
			en: "Numbness Or Tingling In Your Hands Or Feet",
			tr: "Ellerinizde veya Ayaklarınızda Uyuşma veya Karıncalanma",
			es: "Entumecimiento o Hormigueo en sus Manos o Pies",
			ar: "خدر أو وخز في يديك أو قدميك"
		},
		title: {
			en: "Have you experienced any numbness or tingling in your hands or feet lately?",
			tr: "Son zamanlarda ellerinizde veya ayaklarınızda uyuşma veya karıncalanma yaşadınız mı?",
			es: "¿Ha experimentado entumecimiento o hormigueo en sus manos o pies últimamente?",
			ar: "هل شعرت بالخدر أو الوخز في يديك أو قدميك في الآونة الأخيرة؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "blood-sugar-test",
		name: {
			en: "Recent Blood Sugar Test",
			tr: "Son Kan Şekeri Testi",
			es: "Prueba Reciente de Azúcar en Sangre",
			ar: "اختبار سكر الدم الأخير"
		},
		title: {
			en: "Have you had any recent blood tests for diabetes?",
			tr: "Son zamanlarda diyabet için herhangi bir kan testi yaptırdınız mı?",
			es: "¿Ha realizado alguna prueba de azúcar en sangre reciente para la diabetes?",
			ar: "هل أجريت أي اختبارات دم لسكر الدم مؤخرًا؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "blood-sugar-test-result",
		name: {
			en: "Result Of Last Blood Sugar Test",
			tr: "Son Kan Şekeri Testi Sonucu",
			es: "Resultado del último análisis de azúcar en sangre",
			ar: "نتيجة اختبار سكر الدم الأخير"
		},
		title: {
			en: "If yes, what were the results of your last blood sugar test?",
			tr: "Evet ise, son kan şekerinizin test sonuçları neydi?",
			es: "Si es así, ¿cuáles fueron los resultados de su último análisis de azúcar en sangre?",
			ar: "إذا كانت الإجابة بنعم، ما كانت نتائج اختبار سكر الدم الأخير؟"
		},
		answer_type: "select",
		options: {
			en: ["normal", "pre-diabetes", "diabetes"],
			tr: ["normal", "pre-diyabet", "diyabet"],
			es: ["normal", "pre-diabetes", "diabetes"],
			ar: ["طبيعي", "ما قبل السكري", "السكري"]
		},
		depends_on_prev: true,
		group: "health-information"
	},
	{
		id: "cbc-test",
		name: { en: "Recent CBC Test", tr: "Son CBC Testi", es: "Prueba Reciente de CBC", ar: "اختبار CBC الأخير" },
		title: {
			en: "Have you had any recent CBC tests?",
			tr: "Son zamanlarda herhangi bir CBC testi yaptırdınız mı?",
			es: "¿Ha realizado alguna prueba reciente de CBC?",
			ar: "هل أجريت أي اختبارات CBC مؤخرًا؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "cbc-test-result",
		name: {
			en: "Result Of Last CBC Test",
			tr: "Son CBC Testi Sonucu",
			es: "Resultado del último análisis de CBC",
			ar: "نتيجة اختبار CBC الأخير"
		},
		title: {
			en: "If yes, what were the results of your last CBC test?",
			tr: "Evet ise, son CBC testinizin sonuçları neydi?",
			es: "Si es así, ¿cuáles fueron los resultados de su último análisis de CBC?",
			ar: "إذا كانت الإجابة بنعم، ما كانت نتائج اختبار CBC الأخيرة؟"
		},
		answer_type: "number",
		depends_on_prev: true,
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "family-heart-disease-history",
		name: {
			en: "Family With History Of Heart Disease",
			tr: "Kalp Hastalığı Hikayesi Olan Aile",
			es: "Familia con Antecedentes de Enfermedades Cardíacas",
			ar: "العائلة لديها تاريخ من أمراض القلب"
		},
		title: {
			en: "Do you have a family history of heart disease?",
			tr: "Ailenizde kalp hastalığı hikayesi var mı?",
			es: "¿Tiene antecedentes familiares de enfermedades cardíacas?",
			ar: "هل لديك تاريخ عائلي بأمراض القلب؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "family-high-blood-pressure",
		name: {
			en: "Family With History Of High Blood Pressure",
			tr: "Yüksek Tansiyon Geçmişi Olan Aile",
			es: "Familia con Antecedentes de Presión Arterial Alta",
			ar: "العائلة لديها تاريخ ضغط الدم العالي"
		},
		title: {
			en: "Do you have a family history of high blood pressure?",
			tr: "Yüksek tansiyon geçmişiniz var mı?",
			es: "¿Tiene antecedentes familiares de presión arterial alta?",
			ar: "هل لديك تاريخ عائلي بارتفاع ضغط الدم؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "family-high-cholesterol",
		name: {
			en: "Family With History Of High Cholesterol",
			tr: "Yüksek Kolesterol Geçmişi Olan Aile",
			es: "Familia con Antecedentes de Colesterol Alto",
			ar: "العائلة لديها تاريخ زيادة الكوليسترول"
		},
		title: {
			en: "Do you have a family history of high cholesterol?",
			tr: "Yüksek kolesterol geçmişiniz var mı?",
			es: "¿Tiene antecedentes familiares de colesterol alto?",
			ar: "هل لديك تاريخ عائلي بزيادة الكوليسترول؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "sugary-food",
		name: {
			en: "Frequency of sugary or high-carbohydrate food consumption",
			tr: "Şekerli veya yüksek karbonhidratlı yiyecek tüketiminin sıklığı",
			es: "Frecuencia de consumo de alimentos azucarados o ricos en carbohidratos",
			ar: "تردد تناول الطعام الغني بالسكر أو الكربوهيدرات"
		},
		title: {
			en: "How often do you eat sugary or high-carbohydrate foods?",
			tr: "Ne sıklıkla şekerli veya yüksek karbonhidratlı yiyecekler tüketiyorsunuz?",
			es: "¿Con qué frecuencia consume alimentos azucarados o ricos en carbohidratos?",
			ar: "كم مرة تتناول الطعام الغني بالسكر أو الكربوهيدرات؟"
		},
		answer_type: "select",
		options: {
			en: ["often", "sometimes", "rarely"],
			tr: ["sıklıkla", "bazen", "nadiren"],
			es: ["a menudo", "a veces", "raramente"],
			ar: ["غالبًا", "أحيانًا", "نادرا"]
		},
		group: "health-information"
	},
	{
		id: "eat-fruits-vegetables",
		name: {
			en: "Frequency of fruit and vegetable consumption",
			tr: "Meyve ve sebze tüketiminin sıklığı",
			es: "Frecuencia de consumo de frutas y verduras",
			ar: "تردد تناول الفاكهة والخضروات"
		},
		title: {
			en: "How often do you eat fruits and vegetables?",
			tr: "Ne sıklıkla meyve ve sebze tüketiyorsunuz?",
			es: "¿Con qué frecuencia consume frutas y verduras?",
			ar: "كم مرة تتناول الفواكه والخضروات؟"
		},
		answer_type: "select",
		options: {
			en: ["often", "sometimes", "rarely"],
			tr: ["sıklıkla", "bazen", "nadiren"],
			es: ["a menudo", "a veces", "raramente"],
			ar: ["غالبًا", "أحيانًا", "نادرا"]
		},
		group: "health-information"
	},
	{
		id: "exercise",
		name: {
			en: "Exercise Frequency",
			tr: "Egzersiz Sıklığı",
			es: "Frecuencia de Ejercicio",
			ar: "تردد ممارسة التمارين الرياضية"
		},
		title: {
			en: "How often do you exercise for at least 30 minutes per day?",
			tr: "Günde en az 30 dakika boyunca ne sıklıkla egzersiz yapıyorsunuz?",
			es: "¿Con qué frecuencia haces ejercicio durante al menos 30 minutos al día?",
			ar: "كم مرة تمارس التمارين الرياضية لمدة 30 دقيقة على الأقل في اليوم؟"
		},
		answer_type: "select",
		options: {
			en: ["often", "sometimes", "rarely"],
			tr: ["sıklıkla", "bazen", "nadiren"],
			es: ["a menudo", "a veces", "raramente"],
			ar: ["غالبًا", "أحيانًا", "نادرا"]
		},
		group: "health-information"
	},
	{
		id: "antibiotics",
		name: {
			en: "Antibiotics Taken In The Past Year",
			tr: "Geçen Yıl Alınan Antibiyotikler",
			es: "Antibióticos Tomados En El Último Año",
			ar: "المضادات الحيوية المتناولة في العام الماضي"
		},
		title: {
			en: "Have you taken antibiotics in the past year?",
			tr: "Geçen yıl içinde antibiyotik kullandınız mı?",
			es: "¿Ha tomado antibióticos en el último año?",
			ar: "هل تناولت المضادات الحيوية في العام الماضي؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "food-allergies",
		name: {
			en: "Food Allergies Or Intolerances",
			tr: "Gıda Alerjileri veya Hoşgörüsüzlükleri",
			es: "Alergias o Intolerancias Alimentarias",
			ar: "الحساسية الغذائية أو عدم التحمل"
		},
		title: {
			en: "Do you have any food allergies or intolerances?",
			tr: "Herhangi bir gıda alerjiniz veya hoşgörüsüzlüğünüz var mı?",
			es: "¿Tienes alguna alergia o intolerancia alimentaria?",
			ar: "هل لديك أي حساسية أو عدم تحمل للطعام؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "do-taking-medications",
		name: {
			en: "Currently Taking Medications",
			tr: "Şu Anda İlaç Kullanıyor musunuz?",
			es: "Medicamentos Que Está Tomando Actualmente",
			ar: "هل تتناول أي أدوية حاليًا؟"
		},
		title: {
			en: "Are you currently taking any medications?",
			tr: "Şu anda herhangi bir ilaç kullanıyor musunuz?",
			es: "¿Está tomando actualmente algún medicamento?",
			ar: "هل تتناول أي أدوية حاليًا؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "what-medications",
		name: { en: "Medications Type", tr: "İlaç Türü", es: "Tipo de Medicamentos", ar: "نوع الأدوية" },
		title: {
			en: "If yes, what medications are you taking?",
			tr: "Evet ise, hangi ilaçları kullanıyorsunuz?",
			es: "Si es así, ¿qué medicamentos está tomando?",
			ar: "إذا كانت الإجابة بنعم، فما هي الأدوية التي تتناولها؟"
		},
		answer_type: "select",
		options: {
			en: ["diabetes medications", "antibiotics", "other medications"],
			tr: ["diyabet ilaçları", "antibiyotikler", "diğer ilaçlar"],
			es: ["medicamentos para la diabetes", "antibióticos", "otros medicamentos"],
			ar: ["أدوية السكري", "المضادات الحيوية", "غيرها من الأدوية"]
		},
		depends_on_prev: true,
		group: "health-information"
	},
	{
		id: "has-other-medical-conditions",
		name: {
			en: "Diagnosed With Other Medical Conditions",
			tr: "Diğer Tıbbi Durumlarla Teşhis Edildiniz mi?",
			es: "¿Ha sido diagnosticado con otras condiciones médicas?",
			ar: "هل تم تشخيصك بحالات طبية أخرى؟"
		},
		title: {
			en: "Have you ever been diagnosed with any other medical conditions?",
			tr: "Herhangi başka tıbbi durumlarla teşhis edildiniz mi?",
			es: "¿Ha sido diagnosticado con alguna otra condición médica?",
			ar: "هل تم تشخيصك بأي حالات طبية أخرى؟"
		},
		answer_type: "select",
		options: {
			en: ["diabetes medications", "antibiotics", "other medications", "no"],
			tr: ["diyabet ilaçları", "antibiyotikler", "diğer ilaçlar", "hayır"],
			es: ["medicamentos para la diabetes", "antibióticos", "otros medicamentos", "no"],
			ar: ["أدوية السكري", "المضادات الحيوية", "أدوية أخرى", "لا"]
		},
		group: "health-information"
	},
	{
		id: "do-monitor-blood-sugar-home",
		name: {
			en: "Home Blood Sugar Monitoring",
			tr: "Evde Kan Şekerini Düzenli Olarak İzleme",
			es: "Monitoreo Regular del Azúcar en Sangre en Casa",
			ar: "مراقبة مستوى سكر الدم في المنزل بانتظام"
		},
		title: {
			en: "Do you regularly monitor your blood sugar levels at home?",
			tr: "Evde kan şekerinizi düzenli olarak izliyor musunuz?",
			es: "¿Monitorea regularmente sus niveles de azúcar en sangre en casa?",
			ar: "هل تراقب مستوى سكر الدم الخاص بك في المنزل بانتظام؟"
		},
		answer_type: "select",
		options: {
			en: ["regularly", "sometimes", "never"],
			tr: ["sık sık", "bazen", "hiç"],
			es: ["regularmente", "a veces", "nunca"],
			ar: ["عادة", "أحيانا", "أبدا"]
		},
		group: "health-information"
	},
	{
		id: "diabetes-hospitalized",
		name: {
			en: "Diabetes Related Hospitalization History",
			tr: "Diyabetle İlgili Hastaneye Yatış Geçmişi",
			es: "Historial de Hospitalización Relacionada con la Diabetes",
			ar: "تاريخ إجراءات الاستشفاء المتعلقة بالسكري"
		},
		title: {
			en: "Have you ever been hospitalized due to diabetes-related complications?",
			tr: "Diyabetle ilgili komplikasyonlar nedeniyle hiç hastaneye yattınız mı?",
			es: "¿Alguna vez ha sido hospitalizado debido a complicaciones relacionadas con la diabetes?",
			ar: "هل تم إجراء توقيف في المستشفى بسبب مضاعفات السكري؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "visit-physician-diabetes",
		name: {
			en: "Diabetes Management Visit Frequency",
			tr: "Diyabet Yönetimi Ziyaret Sıklığı",
			es: "Frecuencia de Visitas para el Manejo de la Diabetes",
			ar: "تكرار زيارات إدارة السكري"
		},
		title: {
			en: "How often do you visit your primary care physician or endocrinologist for diabetes management?",
			tr: "Diyabet yönetimi için aile hekiminizi veya endokrinologunuzu ne sıklıkta ziyaret ediyorsunuz?",
			es: "¿Con qué frecuencia visita a su médico de atención primaria o endocrinólogo para el manejo de la diabetes?",
			ar: "كم مرة تقوم بزيارة طبيبك الأساسي أو اختصاصي الغدد الصماء لإدارة السكري؟"
		},
		answer_type: "select",
		options: {
			en: ["at least once every 3 months", "at least once every 6 months", "less than once every 6 months"],
			tr: ["her 3 ayda en az bir kez", "her 6 ayda en az bir kez", "6 aydan daha az"],
			es: ["al menos una vez cada 3 meses", "al menos una vez cada 6 meses", "menos de una vez cada 6 meses"],
			ar: ["مرة واحدة على الأقل كل 3 أشهر", "مرة واحدة على الأقل كل 6 أشهر", "أقل من مرة واحدة كل 6 أشهر"]
		},
		group: "health-information"
	},
	{
		id: "participated-diabetes-education",
		name: {
			en: "Participation In Diabetes Education Program",
			tr: "Diyabet Eğitimi Programına Katılım",
			es: "Participación en Programa de Educación sobre la Diabetes",
			ar: "المشاركة في برنامج التثقيف حول السكري"
		},
		title: {
			en: "Have you ever participated in a diabetes education program?",
			tr: "Diyabet eğitimi programına hiç katıldınız mı?",
			es: "¿Ha participado alguna vez en un programa de educación sobre la diabetes?",
			ar: "هل شاركت يوماً ما في برنامج التثقيف حول السكري؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "diabetic-retinopathy",
		name: {
			en: "Diabetic Retinopathy Or Related Eye Issues",
			tr: "Diyabetik Retinopati veya Diğer Göz Problemleri",
			es: "Retinopatía Diabética u Otros Problemas Oculares Relacionados con la Diabetes",
			ar: "تصلب الشبكية السكري أو مشاكل العين المرتبطة بالسكري"
		},
		title: {
			en: "Have you ever experienced diabetic retinopathy or other eye problems related to diabetes?",
			tr: "Diyabetik retinopati veya diyabetle ilgili diğer göz problemleri yaşadınız mı?",
			es: "¿Ha experimentado retinopatía diabética u otros problemas oculares relacionados con la diabetes?",
			ar: "هل سبق لك أن عانيت من تصلب الشبكية السكري أو مشاكل أخرى في العين المرتبطة بالسكري؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "diabetic-neuropathy",
		name: {
			en: "Diabetic Neuropathy Or Related Nerve Issues",
			tr: "Diyabetik Nöropati veya Diğer Sinir Problemleri",
			es: "Neuropatía Diabética u Otros Problemas Nerviosos Relacionados con la Diabetes",
			ar: "الأعصاب المصابة بالسكري أو المشاكل الأعصابية المرتبطة بالسكري"
		},
		title: {
			en: "Have you ever experienced diabetic neuropathy or other nerve problems related to diabetes?",
			tr: "Diyabetik nöropati veya diyabetle ilgili diğer sinir problemleri yaşadınız mı?",
			es: "¿Ha experimentado neuropatía diabética u otros problemas nerviosos relacionados con la diabetes?",
			ar: "هل سبق لك أن عانيت من الأعصاب المصابة بالسكري أو مشاكل أعصابية أخرى مرتبطة بالسكري؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "foot-ulcers",
		name: {
			en: "Diabetes-Related Foot Ulcers Or Foot Problems",
			tr: "Diyabetle İlgili Ayak Ülserleri veya Ayak Problemleri",
			es: "Úlceras o Problemas en los Pies Relacionados con la Diabetes",
			ar: "قرح قدم مرتبطة بالسكري أو مشاكل أخرى في القدم بسبب السكري"
		},
		title: {
			en: "Have you ever had foot ulcers or other foot problems related to diabetes?",
			tr: "Diyabetle ilişkili ayak ülserleri veya diğer ayak problemleri yaşadınız mı?",
			es: "¿Ha tenido úlceras en los pies u otros problemas en los pies relacionados con la diabetes?",
			ar: "هل سبق لك أن كانت لديك قرح قدم أو مشاكل أخرى في القدم مرتبطة بالسكري؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "diabetic-ketoacidosis",
		name: {
			en: "Diagnosed With DKA Or HHS",
			tr: "DKA veya HHS Teşhisi Kondu mu?",
			es: "¿Ha sido diagnosticado con cetoacidosis diabética (DKA) o estado hiperglucémico hiperosmolar (HHS)?",
			ar: "هل تم تشخيص حمض الكيتون السكري (DKA) أو الحالة السكرية فوق الأسمولارية الهايبرغليسمية (HHS) من قبل؟"
		},
		title: {
			en: "Have you ever been diagnosed with diabetic ketoacidosis (DKA) or hyperosmolar hyperglycemic state (HHS)?",
			tr: "Diyabetik ketoasidoz (DKA) veya hiperosmolar hiperglisemik durum (HHS) teşhisi konuldu mu?",
			es: "¿Alguna vez ha sido diagnosticado con cetoacidosis diabética (DKA) o estado hiperglucémico hiperosmolar (HHS)?",
			ar: "هل تم تشخيص حمض الكيتون السكري (DKA) أو الحالة السكرية فوق الأسمولارية الهايبرغليسمية (HHS) من قبل؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "insulin-pump",
		name: {
			en: "Used Insulin Pump Or Continuous Glucose Monitoring",
			tr: "İnsülin Pompası veya Sürekli Glukoz İzleme Sistemi Kullandınız mı?",
			es: "¿Ha utilizado alguna vez una bomba de insulina o un sistema de monitoreo continuo de glucosa?",
			ar: "هل سبق لك استخدام مضخة الأنسولين أو نظام مراقبة الجلوكوز المستمر؟"
		},
		title: {
			en: "Have you ever used an insulin pump or continuous glucose monitoring system?",
			tr: "Daha önce insülin pompası veya sürekli glukoz izleme sistemi kullandınız mı?",
			es: "¿Ha utilizado alguna vez una bomba de insulina o un sistema de monitoreo continuo de glucosa?",
			ar: "هل سبق لك استخدام مضخة الأنسولين أو نظام مراقبة الجلوكوز المستمر؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "diagnosed-prediabetes",
		name: {
			en: "Diagnosed With Prediabetes",
			tr: "Prediyabet Tanısı Kondu mu?",
			es: "¿Ha sido diagnosticado con prediabetes?",
			ar: "هل تم تشخيصك بالسكري المسبق؟"
		},
		title: {
			en: "Have you ever been diagnosed with prediabetes?",
			tr: "Prediyabet tanısı konuldu mu?",
			es: "¿Ha sido diagnosticado con prediabetes?",
			ar: "هل تم تشخيصك بالسكري المسبق؟"
		},
		answer_type: "boolean",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "experienced-hypoglycemia",
		name: {
			en: "Experienced Hypoglycemia",
			tr: "Hipoglisemi Deneyimi",
			es: "Experiencia de Hipoglucemia",
			ar: "هل سبق لك تجربة انخفاض مستوى السكر في الدم؟"
		},
		title: {
			en: "Have you ever experienced hypoglycemia (low blood sugar)?",
			tr: "Daha önce hipoglisemi (düşük kan şekeri) yaşadınız mı?",
			es: "¿Alguna vez ha experimentado hipoglucemia (baja de azúcar en la sangre)?",
			ar: "هل سبق لك تجربة انخفاض مستوى السكر في الدم؟"
		},
		answer_type: "boolean",
		group: "health-information",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "dental-problems",
		name: {
			en: "Dental Health Problems",
			tr: "Diş Sağlığı Problemleri",
			es: "Problemas de Salud Dental",
			ar: "مشاكل صحة الأسنان"
		},
		title: {
			en: "Do you have any dental health problems, such as gum disease or tooth decay?",
			tr: "Diş eti hastalığı veya diş çürüğü gibi herhangi bir diş sağlığı problemi yaşıyor musunuz?",
			es: "¿Tiene algún problema de salud dental, como enfermedad de las encías o caries?",
			ar: "هل لديك أي مشكلات صحة الأسنان، مثل مرض اللثة أو تسوس الأسنان؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "diagnosed-arthritis",
		name: {
			en: "Diagnosed With Arthritis Or Joint Issues",
			tr: "Artrit veya Eklem Sorunu Teşhisi Konuldu mu?",
			es: "Diagnóstico de Artritis o Problemas Articulares",
			ar: "هل تم تشخيصك بالتهاب المفاصل أو مشاكل المفاصل؟"
		},
		title: {
			en: "Have you ever been diagnosed with arthritis or joint problems?",
			tr: "Daha önce artrit veya eklem sorunları teşhisi konuldu mu?",
			es: "¿Alguna vez le han diagnosticado artritis o problemas en las articulaciones?",
			ar: "هل تم تشخيصك بالتهاب المفاصل أو مشاكل المفاصل؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "skin-problems",
		name: {
			en: "Diabetes Related Skin Issues",
			tr: "Diyabetle İlgili Cilt Sorunları",
			es: "Problemas Cutáneos Relacionados con la Diabetes",
			ar: "مشاكل الجلد المرتبطة بالسكري"
		},
		title: {
			en: "Have you ever had skin problems related to diabetes, such as dry skin or slow wound healing?",
			tr: "Kuru cilt veya yavaş yara iyileşmesi gibi diyabetle ilişkili cilt sorunları yaşadınız mı?",
			es: "¿Ha tenido problemas cutáneos relacionados con la diabetes, como piel seca o lenta cicatrización de heridas?",
			ar: "هل لديك أي مشاكل في الجلد المرتبطة بالسكري، مثل الجلد الجاف أو بطء التئام الجروح؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "food-preferences-dietary-restrictions",
		name: {
			en: "Food Preferences Or Dietary Restrictions",
			tr: "Yiyecek Tercihleri veya Diyet Kısıtlamaları",
			es: "Preferencias Alimenticias o Restricciones Dietéticas",
			ar: "تفضيلات الطعام أو القيود الغذائية"
		},
		title: {
			en: "Do you have any food preferences or dietary restrictions, such as vegetarian or gluten-free?",
			tr: "Vejetaryen veya glütensiz gibi yiyecek tercihleriniz veya diyet kısıtlamalarınız var mı?",
			es: "¿Tiene alguna preferencia alimenticia o restricción dietética, como ser vegetariano o sin gluten?",
			ar: "هل لديك أي تفضيلات غذائية أو قيود غذائية، مثل النباتيين أو خالي من الغلوتين؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "kidney-transplant-dialysis",
		name: {
			en: "Kidney Transplant Or Dialysis History",
			tr: "Böbrek Nakli veya Diyaliz Geçmişi",
			es: "Historial de Trasplante de Riñón o Diálisis",
			ar: "تاريخ زرع الكلى أو الغسيل الكلوي"
		},
		title: {
			en: "Have you ever had a kidney transplant or been on dialysis?",
			tr: "Hiç böbrek nakli geçirdiniz veya diyalizde kaldınız mı?",
			es: "¿Ha tenido alguna vez un trasplante de riñón o ha estado en diálisis?",
			ar: "هل خضعت لزرع الكلى أو خضعت للغسيل الكلوي؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "diagnosed-cancer",
		name: {
			en: "Diagnosed With Cancer",
			tr: "Kanser Tanısı Konuldu",
			es: "Diagnosticado Con Cáncer",
			ar: "تشخيص سرطان"
		},
		title: {
			en: "Have you ever been diagnosed with cancer?",
			tr: "Hiç kanser teşhisi konuldu mu?",
			es: "¿Alguna vez ha sido diagnosticado con cáncer?",
			ar: "هل تم تشخيصك بالسرطان من قبل؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "sexual-problems",
		name: {
			en: "Experienced Sexual Problems Related To Diabetes",
			tr: "Diyabetle İlgili Cinsel Sorunlar Yaşandı",
			es: "Problemas Sexuales Relacionados Con la Diabetes",
			ar: "هل سبق لك أن واجهت مشاكل جنسية مرتبطة بالسكري؟"
		},
		title: {
			en: "Have you ever experienced sexual problems related to diabetes?",
			tr: "Hiç diyabetle ilgili cinsel sorunlar yaşadınız mı?",
			es: "¿Alguna vez ha experimentado problemas sexuales relacionados con la diabetes?",
			ar: "هل سبق لك أن واجهت مشاكل جنسية مرتبطة بالسكري؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "dentist-cleanings",
		name: {
			en: "Regular Dental Checkups And Cleanings",
			tr: "Düzenli Diş Temizlikleri ve Kontroller",
			es: "Revisiones y Limpiezas Dentales Regulares",
			ar: "الفحوصات الدورية للأسنان والتنظيف"
		},
		title: {
			en: "Do you regularly see a dentist for cleanings and checkups?",
			tr: "Diş temizlikleri ve kontrolleri için düzenli olarak diş hekimine gidiyor musunuz?",
			es: "¿Visita regularmente al dentista para limpiezas y chequeos?",
			ar: "هل تراجع طبيب الأسنان بانتظام لإجراء الفحوصات والتنظيف؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "bone-density-test",
		name: {
			en: "Bone Density Test For Osteoporosis",
			tr: "Osteoporoz Kontrolü İçin Kemik Yoğunluğu Testi",
			es: "Prueba de Densidad Ósea para la Osteoporosis",
			ar: "اختبار كثافة العظام للاحتياط من هشاشة العظام"
		},
		title: {
			en: "Have you ever had a bone density test to check for osteoporosis?",
			tr: "Osteoporoz kontrolü için kemik yoğunluğu testi yaptırdınız mı?",
			es: "¿Alguna vez se ha realizado una prueba de densidad ósea para verificar la osteoporosis?",
			ar: "هل خضعت لاختبار كثافة العظام للتحقق من وجود هشاشة العظام؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "acid-reflux",
		name: {
			en: "Experienced Acid Reflux Or Gastrointestinal Issues",
			tr: "Mide Yanması veya Gastrointestinal Sorunlar",
			es: "Reflujo Ácido o Problemas Gastrointestinales Experimentados",
			ar: "هل سبق لك أن تعرضت لارتجاع المعدة أو مشاكل هضمية أخرى؟"
		},
		title: {
			en: "Have you ever experienced acid reflux or other gastrointestinal problems?",
			tr: "Daha önce mide yanması veya diğer gastrointestinal sorunlar yaşadınız mı?",
			es: "¿Alguna vez ha experimentado reflujo ácido u otros problemas gastrointestinales?",
			ar: "هل سبق لك أن تعرضت لارتجاع المعدة أو مشاكل هضمية أخرى؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "sleep-study",
		name: {
			en: "Sleep Study For Sleep Apnea Or Disorders",
			tr: "Uyku Apnesi veya Bozuklukları İçin Uyku Çalışması",
			es: "Estudio Del Sueño Para Apnea Del Sueño O Trastornos",
			ar: "هل أجريت أبحاثاً عن النوم لاكتشاف أوجاع النوم أو الاضطرابات الأخرى؟"
		},
		title: {
			en: "Have you ever had a sleep study to diagnose sleep apnea or other sleep disorders?",
			tr: "Uyku apnesi veya diğer uyku bozukluklarını teşhis etmek için daha önce uyku çalışması yaptırdınız mı?",
			es: "¿Alguna vez se ha realizado un estudio del sueño para diagnosticar apnea del sueño u otros trastornos del sueño?",
			ar: "هل أجريت أبحاثاً عن النوم لاكتشاف أوجاع النوم أو الاضطرابات الأخرى؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "hearing-problems",
		name: {
			en: "Hearing Problems Or Tinnitus",
			tr: "İşitme Problemleri veya Tinnitus",
			es: "Problemas De Audición O Tinnitus",
			ar: "هل لديك أي مشاكل في السمع أو طنين؟"
		},
		title: {
			en: "Do you have any hearing problems or tinnitus?",
			tr: "Herhangi bir işitme problemi veya tinnitusunuz var mı?",
			es: "¿Tiene problemas de audición o tinnitus?",
			ar: "هل لديك أي مشاكل في السمع أو طنين؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "heart-attack",
		name: {
			en: "History Of Heart Attack Or Stroke",
			tr: "Kalp Krizi veya Felç Geçmişi",
			es: "Historial De Ataque Cardíaco O Accidente Cerebrovascular",
			ar: "هل سبق لك أن تعرضت لنوبة قلبية أو سكتة دماغية؟"
		},
		title: {
			en: "Have you ever had a heart attack or stroke?",
			tr: "Daha önce kalp krizi veya felç geçirdiniz mi?",
			es: "¿Ha tenido alguna vez un ataque cardíaco o accidente cerebrovascular?",
			ar: "هل سبق لك أن تعرضت لنوبة قلبية أو سكتة دماغية؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "blood-clot",
		name: {
			en: "History Of Blood Clot Or Clotting Disorder Diagnosis",
			tr: "Kan Pıhtısı Geçmişi veya Pıhtılaşma Bozukluğu Tanısı",
			es: "Historial De Coágulo De Sangre O Diagnóstico De Trastorno De Coagulación",
			ar: "هل سبق لك أن حدث لك جلطة دموية أو تم تشخيص اضطراب تخثر؟"
		},
		title: {
			en: "Have you ever had a blood clot or been diagnosed with a clotting disorder?",
			tr: "Daha önce kan pıhtısı geçirdiniz mi veya pıhtılaşma bozukluğu tanısı aldınız mı?",
			es: "¿Ha tenido alguna vez un coágulo de sangre o le han diagnosticado un trastorno de coagulación?",
			ar: "هل سبق لك أن حدث لك جلطة دموية أو تم تشخيص اضطراب تخثر؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "thyroid-disorder",
		name: {
			en: "Thyroid Disorder Diagnosis",
			tr: "Tiroid Bozukluğu Tanısı",
			es: "Diagnóstico De Trastorno Tiroideo",
			ar: "تشخيص اضطراب الغدة الدرقية"
		},
		title: {
			en: "Have you ever been diagnosed with a thyroid disorder?",
			tr: "Daha önce tiroid bozukluğu tanısı aldınız mı?",
			es: "¿Alguna vez le han diagnosticado un trastorno tiroideo?",
			ar: "هل تم تشخيصك بأي اضطراب في الغدة الدرقية؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "allergies-medications",
		name: {
			en: "Medication Or Substance Allergies",
			tr: "İlaç veya Madde Alerjileri",
			es: "Alergias A Medicamentos O Sustancias",
			ar: "حساسية للأدوية أو المواد الأخرى"
		},
		title: {
			en: "Do you have any allergies to medications or other substances?",
			tr: "İlaçlara veya diğer maddelere alerjiniz var mı?",
			es: "¿Tiene alergias a medicamentos u otras sustancias?",
			ar: "هل لديك أي حساسية للأدوية أو المواد الأخرى؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "bone-marrow-transplant",
		name: {
			en: "Bone Marrow Transplant Or Stem Cell Therapy History",
			tr: "Kemik İliği Nakli veya Kök Hücre Tedavisi Geçmişi",
			es: "Historial de Trasplante de Médula Ósea o Terapia de Células Madre",
			ar: "تاريخ زراعة نخاع العظم أو العلاج بالخلايا الجذعية"
		},
		title: {
			en: "Have you ever had a bone marrow transplant or stem cell therapy?",
			tr: "Daha önce kemik iliği nakli veya kök hücre tedavisi geçirdiniz mi?",
			es: "¿Ha tenido algún trasplante de médula ósea o terapia de células madre?",
			ar: "هل خضعت لزراعة نخاع العظم أو العلاج بالخلايا الجذعية؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "had-surgery",
		name: { en: "History Of Surgery", tr: "Cerrahi Geçmiş", es: "Historial de Cirugía", ar: "تاريخ الجراحة" },
		title: {
			en: "Have you ever had surgery for any reason?",
			tr: "Herhangi bir nedenle ameliyat oldunuz mu?",
			es: "¿Ha tenido alguna cirugía por alguna razón?",
			ar: "هل خضعت لجراحة لأي سبب؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "wear-sunscreen",
		name: {
			en: "Regular Use Of Sunscreen Or Sun Protection",
			tr: "Düzenli Güneş Kremi Kullanımı veya Güneşten Korunma",
			es: "Uso Regular de Protector Solar o Protección Solar",
			ar: "استخدام منتظم للواقي الشمس أو الحماية من الشمس"
		},
		title: {
			en: "Do you regularly wear sunscreen or protect your skin from the sun?",
			tr: "Düzenli olarak güneş kremi kullanır mısınız veya cildinizi güneşten korur musunuz?",
			es: "¿Usa regularmente protector solar o protege su piel del sol?",
			ar: "هل تستخدم بانتظام واقي الشمس أو تحمي بشرتك من أشعة الشمس؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "fungal-yeast",
		name: {
			en: "History Of Fungal Or Yeast Infections",
			tr: "Fungal veya Maya Enfeksiyonları Geçmişi",
			es: "Historial de Infecciones por Hongos o Levaduras",
			ar: "تاريخ الإصابة بالعدوى الفطرية أو الخميرة"
		},
		title: {
			en: "Have you ever had a fungal or yeast infection, such as thrush or jock itch?",
			tr: "Daha önce mantar veya maya enfeksiyonu geçirdiniz mi, örneğin pamukçuk veya jock kaşıntısı?",
			es: "¿Ha tenido alguna vez una infección por hongos o levaduras, como la candidiasis bucal o la picazón de la ingle?",
			ar: "هل سبق لك أن عانيت من عدوى فطرية أو خميرة، مثل البلاش أو الحكة الأحدب؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "autoimmune-disorder",
		name: {
			en: "Diagnosed With An Autoimmune Disorder",
			tr: "Otoimmün Bozukluk Teşhisi",
			es: "Diagnóstico de Trastorno Autoinmune",
			ar: "تشخيص اضطراب المناعة الذاتية"
		},
		title: {
			en: "Have you ever been diagnosed with an autoimmune disorder?",
			tr: "Hiç otoimmün bozukluk teşhisi konuldu mu?",
			es: "¿Ha sido diagnosticado alguna vez con un trastorno autoinmune?",
			ar: "هل تم تشخيصك يومًا ما بمتلازمة المناعة الذاتية؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "substance-abuse",
		name: {
			en: "History Of Substance Abuse Or Addiction",
			tr: "Madde Bağımlılığı veya Bağımlılığı Geçmişi",
			es: "Historial de Abuso de Sustancias o Adicción",
			ar: "تاريخ إساءة استخدام المواد أو الإدمان"
		},
		title: {
			en: "Do you have any history of substance abuse or addiction?",
			tr: "Madde bağımlılığı veya bağımlılığı geçmişiniz var mı?",
			es: "¿Tiene algún historial de abuso de sustancias o adicción?",
			ar: "هل لديك أي تاريخ من إساءة استخدام المواد أو الإدمان؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "sexually-transmitted-infection",
		name: {
			en: "Diagnosed With A Sexually Transmitted Infection",
			tr: "Cinsel Yolla Bulaşan Bir Enfeksiyon Teşhisi",
			es: "Diagnóstico de Infección de Transmisión Sexual",
			ar: "تشخيص عدوى مكتسبة جنسياً"
		},
		title: {
			en: "Have you ever been diagnosed with a sexually transmitted infection (STI)?",
			tr: "Daha önce cinsel yolla bulaşan bir enfeksiyon (STI) teşhisi konuldu mu?",
			es: "¿Alguna vez ha sido diagnosticado con una infección de transmisión sexual (ITS)?",
			ar: "هل تم تشخيصك بعدوى مكتسبة جنسياً (ITS)؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "depression-anxiety",
		name: {
			en: "Experienced Depression Or Anxiety",
			tr: "Depresyon veya Anksiyete Yaşadınız mı?",
			es: "Experiencia de Depresión o Ansiedad",
			ar: "هل سبق لك أن تعرضت للاكتئاب أو القلق؟"
		},
		title: {
			en: "Have you ever experienced depression or anxiety?",
			tr: "Daha önce depresyon veya anksiyete yaşadınız mı?",
			es: "¿Alguna vez ha experimentado depresión o ansiedad?",
			ar: "هل سبق لك أن تعرضت للاكتئاب أو القلق؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "blood-transfusion",
		name: {
			en: "Received Blood Transfusion Or Other Blood Products",
			tr: "Kan Transfüzyonu veya Diğer Kan Ürünleri Alımı",
			es: "Transfusión de Sangre u Otros Productos Sanguíneos",
			ar: "هل تلقيت نقل دم أو منتجات دم أخرى؟"
		},
		title: {
			en: "Have you ever had a blood transfusion or received other blood products?",
			tr: "Daha önce kan transfüzyonu yapıldı mı veya diğer kan ürünleri alındı mı?",
			es: "¿Ha recibido alguna vez una transfusión de sangre u otros productos sanguíneos?",
			ar: "هل تلقيت نقل دم أو منتجات دم أخرى؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "rare-disease",
		name: {
			en: "Diagnosed With A Rare Disease Or Disorder",
			tr: "Nadir Bir Hastalık veya Bozukluk Tanısı",
			es: "¿Ha sido diagnosticado con una enfermedad o trastorno raro?",
			ar: "هل تم تشخيصك بمرض نادر أو اضطراب؟"
		},
		title: {
			en: "Have you ever been diagnosed with a rare disease or disorder?",
			tr: "Daha önce nadir bir hastalık veya bozukluk tanısı kondu mu?",
			es: "¿Ha sido diagnosticado con una enfermedad o trastorno raro?",
			ar: "هل تم تشخيصك بمرض نادر أو اضطراب؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "seizure-epilepsy",
		name: {
			en: "History Of Seizures Or Epilepsy Diagnosis",
			tr: "Nöbet veya Epilepsi Tanısı Geçmişi",
			es: "Historial de Convulsiones o Diagnóstico de Epilepsia",
			ar: "تاريخ النوبات أو تشخيص الصرع"
		},
		title: {
			en: "Have you ever had a seizure or been diagnosed with epilepsy?",
			tr: "Daha önce nöbet geçirdiniz veya epilepsi tanısı konuldu mu?",
			es: "¿Ha tenido alguna convulsión o ha sido diagnosticado con epilepsia?",
			ar: "هل سبق أن حدثت لديك نوبة أو تم تشخيصك بالصرع؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "allergic-reaction-medication-food",
		name: {
			en: "History Of Allergic Reactions To Medications Or Food",
			tr: "İlaçlara veya Yiyeceklere Alerjik Reaksiyon Geçmişi",
			es: "Antecedentes de Reacciones Alérgicas a Medicamentos o Alimentos",
			ar: "تاريخ الحساسية للأدوية أو الطعام"
		},
		title: {
			en: "Have you ever had an allergic reaction to a medication or food?",
			tr: "Daha önce bir ilaca veya yiyeceğe alerjik reaksiyon geçirdiniz mi?",
			es: "¿Ha tenido alguna reacción alérgica a un medicamento o alimento?",
			ar: "هل سبق لك أن حدثت لديك رد فعل تحسسي لدواء ما أو طعام؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "positive-tuberculosis",
		name: {
			en: "History Of Positive Tuberculosis Test",
			tr: "Pozitif Verem (Tüberküloz) Test Geçmişi",
			es: "Antecedentes de Prueba de Tuberculosis (TB) Positiva",
			ar: "تاريخ إجراء اختبار إيجابي للسل (الدرن)"
		},
		title: {
			en: "Have you ever had a positive tuberculosis (TB) test or been diagnosed with TB?",
			tr: "Daha önce pozitif bir verem (tüberküloz) testi yaptırdınız mı veya verem (tüberküloz) teşhisi kondu mu?",
			es: "¿Ha tenido alguna prueba de tuberculosis (TB) positiva o ha sido diagnosticado con TB?",
			ar: "هل سبق لك أن أجريت اختبار إيجابي للسل (الدرن) أو تم تشخيصك بالسل؟"
		},
		answer_type: "boolean",
		group: "advanced-diabetes",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "looking-weight-state",
		name: { en: "Your Priority", tr: "Önceliğiniz", es: "Tu Prioridad", ar: "أولويتك" },
		title: {
			en: "What is your priority?",
			tr: "Önceliğiniz nedir?",
			es: "¿Cuál es tu prioridad?",
			ar: "ما هي أولويتك؟"
		},
		answer_type: "select",
		options: {
			en: [
				"healthier life",
				"lose weight",
				"have a better skin",
				"illness treatment",
				"more peace and happiness"
			],
			tr: [
				"daha sağlıklı bir yaşam",
				"kilo vermek",
				"daha iyi bir cilt",
				"hastalık tedavisi",
				"daha fazla huzur ve mutluluk"
			],
			es: [
				"una vida más saludable",
				"perder peso",
				"tener una mejor piel",
				"tratamiento de enfermedades",
				"más paz y felicidad"
			],
			ar: ["حياة أكثر صحة", "فقدان الوزن", "الحصول على بشرة أفضل", "علاج الأمراض", "المزيد من السلام والسعادة"]
		},
		group: ""
	},
	{
		id: "workout-restrictions",
		name: {
			en: "Workout Restrictions"
		},
		title: {
			en: "Do you have any restrictions for workout?"
		},
		answer_type: "select",
		group: "workout",
		options: {
			en: ["No Issues", "Back", "Knees", "Shoulders", "Neck", "Arms", "Elbows", "Legs"],
			tr: [],
			es: [],
			ar: []
		}
	},
	{
		id: "workout-focus-areas",
		name: {
			en: "Workout Focus Area"
		},
		title: {
			en: "Which areas would you like to focus on for workout?"
		},
		answer_type: "select",
		group: "workout",
		options: {
			en: [
				"Full Body",
				"Massive Biceps",
				"Wider Back",
				"Tight Glutes",
				"Boulder Shoulders",
				"Broad Chest",
				"Six-pack",
				"Strong Legs"
			],
			tr: [],
			es: [],
			ar: []
		}
	},
	{
		id: "workout-cardio",
		name: {
			en: "Cardio"
		},
		title: {
			en: "Do you want Viva add cardio exercises into your plan?"
		},
		answer_type: "boolean",
		group: "workout",
		options: { en: [], tr: [], es: [], ar: [] }
	},
	{
		id: "workout-days",
		name: {
			en: "Workout Days Of The Week"
		},
		title: {
			en: "Which days of the week do you want to exercise?"
		},
		answer_type: "select",
		group: "workout",
		options: {
			en: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
			tr: [],
			es: [],
			ar: []
		}
	},
	// "SU", "MO", "TU", "WE", "TH", "FR", "SA"
	{
		id: "workout-duration",
		name: {
			en: "Workout Minutes A Day"
		},
		title: {
			en: "how many minutes a day do you want to exercise?"
		},
		answer_type: "select",
		group: "workout",
		options: { en: ["Quick", "Short", "Average", "Long"], tr: [], es: [], ar: [] },
		options_description: {
			en: ["Up to 20 minutes", "20-40 minutes", "40-60 minutes", "60+ minutes"],
			tr: [],
			es: [],
			ar: []
		}
	}
]
