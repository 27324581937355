import { defineStore, skipHydrate } from "pinia"
import { useLocalStorage } from "@vueuse/core"
import { type ShopProduct } from "~/models/shop-product"

export const useCartStore = defineStore("cart", () => {
	const state = {
		cart: skipHydrate(useLocalStorage<ShopProduct[]>("cart", []))
	}

	const updateItems = (products: ShopProduct[]) => {
		products.forEach((product) => {
			if (!product.quantity) {
				removeItems([product])
			} else {
				if (state.cart.value.some((p) => p.id === product.id)) {
					state.cart.value = state.cart.value.map((p) => (p.id === product.id ? product : p))
				} else {
					state.cart.value = [...state.cart.value, product]
				}
			}
		})
	}

	const removeItems = (products: ShopProduct[]) => {
		products.forEach((product) => {
			state.cart.value = state.cart.value.filter((p) => p.id != product.id)
		})
	}

	const clear = () => {
		removeItems(state.cart.value)
	}

	return {
		...state,
		updateItems,
		removeItems,
		clear
	}
})
