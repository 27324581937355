export default [
	{
		sample_id: "S1",
		taxonomic_levels: {
			Phylum: {
				Bacteroidetes: {
					percent: "35%",
					normal_range: { min: "20%", max: "40%" }
				},
				Firmicutes: {
					percent: "45%",
					normal_range: { min: "30%", max: "50%" }
				},
				Proteobacteria: {
					percent: "10%",
					normal_range: { min: "5%", max: "15%" }
				},
				Actinobacteria: {
					percent: "5%",
					normal_range: { min: "2%", max: "10%" }
				},
				Others: {
					percent: "5%",
					normal_range: { min: "0%", max: "10%" }
				}
			},
			Genus: {
				Bacteroides: {
					percent: "20%",
					normal_range: { min: "10%", max: "30%" }
				},
				Prevotella: {
					percent: "15%",
					normal_range: { min: "5%", max: "20%" }
				},
				Faecalibacterium: {
					percent: "10%",
					normal_range: { min: "30%", max: "50%" },
					normal: "5-15%"
				},
				Escherichia: {
					percent: "5%",
					normal_range: { min: "1%", max: "10%" }
				},
				Others: {
					percent: "50%",
					normal_range: { min: "20%", max: "60%" }
				}
			}
		}
	},
	{
		sample_id: "S2",
		taxonomic_levels: {
			Phylum: {
				Firmicutes: {
					percent: "40%",
					normal_range: { min: "30%", max: "50%" }
				},
				Bacteroidetes: {
					percent: "30%",
					normal_range: { min: "20%", max: "40%" }
				},
				Proteobacteria: {
					percent: "15%",
					normal_range: { min: "10%", max: "20%" }
				},
				Actinobacteria: {
					percent: "10%",
					normal_range: { min: "5%", max: "15%" }
				},
				Others: {
					percent: "5%",
					normal_range: { min: "0%", max: "10%" }
				}
			},
			Genus: {
				Bacteroides: {
					percent: "25%",
					normal_range: { min: "15%", max: "35%" }
				},
				Faecalibacterium: {
					percent: "20%",
					normal_range: { min: "10%", max: "30%" }
				},
				Ruminococcus: {
					percent: "15%",
					normal_range: { min: "5%", max: "25%" }
				},
				Lactobacillus: {
					percent: "10%",
					normal_range: { min: "0%", max: "20%" }
				},
				Others: {
					percent: "30%",
					normal_range: { min: "15%", max: "45%" }
				}
			}
		}
	},
	{
		sample_id: "S3",
		taxonomic_levels: {
			Phylum: {
				Bacteroidetes: {
					percent: "25%",
					normal_range: { min: "15%", max: "35%" }
				},
				Firmicutes: {
					percent: "50%",
					normal_range: { min: "40%", max: "60%" }
				},
				Actinobacteria: {
					percent: "15%",
					normal_range: { min: "10%", max: "20%" }
				},
				Proteobacteria: {
					percent: "5%",
					normal_range: { min: "2%", max: "8%" }
				},
				Others: {
					percent: "5%",
					normal_range: { min: "0%", max: "10%" }
				}
			},
			Genus: {
				Bacteroides: {
					percent: "10%",
					normal_range: { min: "5%", max: "15%" }
				},
				Prevotella: {
					percent: "20%",
					normal_range: { min: "15%", max: "25%" }
				},
				Roseburia: {
					percent: "15%",
					normal_range: { min: "10%", max: "20%" }
				},
				Streptococcus: {
					percent: "10%",
					normal_range: { min: "5%", max: "15%" }
				},
				Others: {
					percent: "45%",
					normal_range: { min: "30%", max: "60%" }
				}
			}
		}
	},
	{
		sample_id: "S4",
		taxonomic_levels: {
			Phylum: {
				Firmicutes: {
					percent: "55%",
					normal_range: { min: "45%", max: "65%" }
				},
				Bacteroidetes: {
					percent: "30%",
					normal_range: { min: "20%", max: "40%" }
				},
				Actinobacteria: {
					percent: "8%",
					normal_range: { min: "5%", max: "15%" }
				},
				Proteobacteria: {
					percent: "5%",
					normal_range: { min: "2%", max: "10%" }
				},
				Others: {
					percent: "2%",
					normal_range: { min: "0%", max: "5%" }
				}
			},
			Genus: {
				Bacteroides: {
					percent: "25%",
					normal_range: { min: "15%", max: "35%" }
				},
				Prevotella: {
					percent: "15%",
					normal_range: { min: "10%", max: "20%" }
				},
				Lactobacillus: {
					percent: "5%",
					normal_range: { min: "0%", max: "10%" }
				},
				Escherichia: {
					percent: "3%",
					normal_range: { min: "1%", max: "5%" }
				},
				Others: {
					percent: "52%",
					normal_range: { min: "40%", max: "60%" }
				}
			}
		}
	},
	{
		sample_id: "S5",
		taxonomic_levels: {
			Phylum: {
				Firmicutes: {
					percent: "50%",
					normal_range: { min: "40%", max: "60%" }
				},
				Bacteroidetes: {
					percent: "35%",
					normal_range: { min: "25%", max: "45%" }
				},
				Proteobacteria: {
					percent: "8%",
					normal_range: { min: "4%", max: "12%" }
				},
				Actinobacteria: {
					percent: "5%",
					normal_range: { min: "2%", max: "8%" }
				},
				Others: {
					percent: "2%",
					normal_range: { min: "0%", max: "5%" }
				}
			},
			Genus: {
				Bacteroides: {
					percent: "20%",
					normal_range: { min: "10%", max: "30%" }
				},
				Prevotella: {
					percent: "18%",
					normal_range: { min: "12%", max: "24%" }
				},
				Faecalibacterium: {
					percent: "8%",
					normal_range: { min: "4%", max: "12%" }
				},
				Streptococcus: {
					percent: "5%",
					normal_range: { min: "2%", max: "8%" }
				},
				Others: {
					percent: "49%",
					normal_range: { min: "30%", max: "60%" }
				}
			}
		}
	}
]
