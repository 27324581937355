<template>
	<div
		class="relative flex flex-col overflow-hidden bg-white rounded-2xl"
		:style="{
			boxShadow: '1px 1px 4px 0px #00000033'
		}"
	>
		<div
			v-if="product.quantity"
			class="absolute top-0 left-0 right-0 flex items-center justify-center mt-4 pointer-events-none"
		>
			<span
				class="absolute top-0 left-auto right-auto bg-white border border-slate-200 rounded-xl z-[1] px-3 py-2 font-bold text-xs shadow-lg"
			>
				{{ product.quantity }}
			</span>
		</div>
		<div class="flex flex-col pb-2 -mb-px cursor-pointer ripple-bg-white" @click="emit('open')">
			<div class="relative bg-slate-100">
				<div class="pt-[56.25%]" />
				<img
					v-if="product.image"
					:src="product.image"
					class="absolute top-0 object-cover w-full h-full start-0 end-auto"
				/>
				<div v-else class="absolute top-0 flex items-center justify-center w-full h-full start-0 end-auto">
					<BaseIcon name="dish" class="w-16 h-16 text-gray-300" />
				</div>
			</div>
			<span class="px-3 mt-2 text-xs font-bold text-center text-primary">
				{{ "$" + product.unit_price }}
			</span>
			<span class="mt-0.5 text-sm font-bold text-center text-neutral-900 truncate px-3">
				{{ product.title }}
			</span>
			<span class="mt-0.5 text-xs text-center text-neutral-400">
				{{ $t("n_kg", { n: 1 }) }}
			</span>
			<div class="flex-grow" />
		</div>
		<ClientOnly>
			<div
				v-if="!hideButton"
				class="px-4 text-center cursor-pointer ripple-bg-white"
				@click="
					isInCart
						? cartStore.removeItems([product])
						: cartStore.updateItems([
								{
									...product,
									quantity: 1
								}
						  ])
				"
			>
				<div
					:class="[
						'text-xs px-3 pb-2 pt-2 border-t  border-dashed text-center font-medium',
						isInCart ? 'text-red-500 border-red-500' : 'text-[#689C36] border-[#689C36]'
					]"
				>
					{{ isInCart ? $t("remove_from_cart") : $t("add_to_cart") }}
				</div>
			</div>
		</ClientOnly>
	</div>
</template>

<script setup lang="ts">
import { type ShopProduct } from "~/models/shop-product"

const cartStore = useCartStore()

const emit = defineEmits(["open"])
const props = defineProps<{
	product: ShopProduct
	hideButton?: boolean
}>()

const isInCart = computed(() => cartStore.cart.some((p) => p.id === props.product.id))
</script>
