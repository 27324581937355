export default () => ({
	thousandFormat(value: any): any {
		if (value === 0) return 0
		if (!value) return ""
		let v = ((value || "") + "") as string
		let v2 = ""
		let m = false
		if (v.includes(".")) {
			const i = v.indexOf(".")
			v2 = v.substring(i, v.length)
			v = v.substring(0, i)
		}
		if (v.includes("-")) {
			v = v.replace("-", "")
			m = true
		}
		v = v
			.replace(/,/g, "")
			.split("")
			.reverse()
			.join("")
			.replace(/(...)/g, "$1,")
			.split("")
			.reverse()
			.join("")
			.replace(/^,+|,+$/, "")
		if (m) v = "-" + v
		return v + v2
	}
})
