import questions from "~/assets/questions"

export default () => {
	const app = useNuxtApp()
	return computed(() => {
		const localeCode = app.$i18n.locale.value
		return questions.map((question: any) => {
			return {
				...(question as (typeof questions)[number]),
				name: (question.name?.[localeCode] || "") as string,
				title: (question.title?.[localeCode] || "") as string,
				units: (question.units?.[localeCode] || []) as string[],
				units_en: (question.units?.["en"] || []) as string[],
				options: (question.options?.[localeCode] || []) as string[],
				options_en: (question.options?.["en"] || []) as string[],
				options_description: (question.options_description?.[localeCode] || []) as string[]
			}
		})
	})
}
