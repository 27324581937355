import { defineStore } from "pinia"
import { type Medicine } from "~/models/medicine"
import { type Database } from "~/types/supabase"

export const useMedicineStore = defineStore("medicine", () => {
	const state = {
		medicines: ref<Medicine[] | null>(null)
	}

	const supabase = useSupabaseClient<Database>()
	const user = useSupabaseUser()

	const getMedicines = async () => {
		const medicines = (await supabase.from("user_medicines").select().eq("user_id", user.value?.id || '')).data || []
		state.medicines.value = medicines
		return medicines
	}

	const fetchMedicines = async () => {
		if (state.medicines.value || !user.value?.id) return null
		return await getMedicines()
	}

	const getMedicine = async (id: string) => {
		let medicine = state.medicines.value?.find((v) => v.id === id) || null
		if (!medicine) {
			medicine = (await supabase.from("user_medicines").select().eq("id", id).single()).data
		}
		return medicine
	}

	const addMedicine = async (formData: Medicine) => {
		await supabase.from("user_medicines").insert({
			user_id: user.value?.id!,
			name: formData.name,
			name2: formData.name2,
			description: formData.description,
			usage_amount: formData.usage_amount,
			usage_interval: formData.usage_interval,
			usage_start_date: formData.usage_start_date,
			usage_start_time: formData.usage_start_time,
			dosage: formData.dosage,
			amount: formData.amount
		})
		await getMedicines()
	}

	const editMedicine = async (formData: Medicine) => {
		await supabase
			.from("user_medicines")
			.update({
				name: formData.name,
				name2: formData.name2,
				description: formData.description,
				usage_amount: formData.usage_amount,
				usage_interval: formData.usage_interval,
				usage_start_date: formData.usage_start_date,
				usage_start_time: formData.usage_start_time,
				dosage: formData.dosage,
				amount: formData.amount
			})
			.eq("id", formData.id || '')
		await getMedicines()
	}

	const deleteMedicine = async (id: string) => {
		await supabase.from("user_medicines").delete().eq("id", id)
		await getMedicines()
	}

	return {
		...state,
		getMedicines,
		fetchMedicines,
		getMedicine,
		addMedicine,
		editMedicine,
		deleteMedicine
	}
})
