import { defineStore, skipHydrate } from "pinia"
import { useLocalStorage } from "@vueuse/core"
import personSamples from "~/assets/person-samples"
import samples from "~/assets/samples"

export const useSampleStore = defineStore("sample", () => {
	const state = {
		sampleId: skipHydrate(useLocalStorage("sample_id", "")),
		personSampleId: skipHydrate(useLocalStorage("person_sample_id", ""))
	}

	const selectedSample = computed(() => samples.find((v) => v.sample_id === state.sampleId.value) || samples[0])
	const selectedPerson = computed(
		() => personSamples.find((v) => v.sample_id === state.personSampleId.value) || personSamples[0]
	)

	const selectedSamplePromptPart = computed(() =>
		selectedSample.value
			? Object.entries(selectedSample.value?.taxonomic_levels)
					.map(([name, levels]) =>
						[
							`- ${name}`,
							...Object.entries(levels).map(([level, percent]: any) => `  - ${level}: ${percent.percent}`)
						].join("\n")
					)
					.join("\n")
			: null
	)

	return {
		...state,
		selectedSample,
		selectedPerson,
		selectedSamplePromptPart
	}
})
