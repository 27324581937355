<template>
	<div class="relative z-0 group">
		<input
			v-if="['text', 'number', 'date', 'email', 'password', 'time'].includes(type)"
			:value="type === 'date' ? dateValue : modelValue"
			@input="changeEventValue"
			:type="type"
			:class="[
				'block py-3 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer',
				endIcon ? 'ps-9' : 'ps-0',
				type === 'select' ? 'pe-9' : 'pe-0'
			]"
			:step="step"
			placeholder=" "
			:required="required"
		/>
		<span
			v-if="['textarea'].includes(type)"
			:class="[
				'block py-3 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer',
				endIcon ? 'ps-9' : 'ps-0',
				type === 'select' ? 'pe-9' : 'pe-0',
				'pointer-events-none select-none overflow-x-auto whitespace-pre-wrap opacity-0'
			]"
			:style="[
				{
					wordWrap: 'break-word'
				}
			]"
			v-text="(modelValue || '') + '.'"
		/>
		<textarea
			v-if="['textarea'].includes(type)"
			:value="type === 'date' ? dateValue : modelValue"
			@input="changeEventValue"
			:type="type"
			:class="[
				'block py-3 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer',
				endIcon ? 'ps-9' : 'ps-0',
				type === 'select' ? 'pe-9' : 'pe-0',
				'absolute bottom-0 left-0 right-0 top-0 resize-none'
			]"
			:step="step"
			placeholder=" "
			:required="required"
		/>
		<select
			v-if="['select'].includes(type)"
			:value="modelValue"
			@change="changeEventValue"
			:class="[
				'block py-3 pe-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-primary peer',
				endIcon ? 'ps-9' : 'ps-0',
				type === 'select' ? 'pe-9' : 'pe-0'
			]"
			:required="required"
		>
			<option :value="null" default selected></option>
			<option
				v-for="(option, i) in options"
				:key="i"
				:value="optionValue ? option[optionValue] : option"
				@change="changeEventValue"
			>
				{{ optionName ? option[optionName] : option }}
			</option>
		</select>
		<label
			:class="[
				type === 'select' && !modelValue
					? 'translate-y-0 scale-100'
					: 'rtl:translate-y-[1.125rem] ltr:-translate-y-[1.125rem] scale-75',
				'absolute text-sm text-gray-500 duration-300 transform top-3 -z-10 rtl:origin-[100%] ltr:origin-[0] peer-focus:start-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 rtl:peer-focus:-translate-y-[1.125rem] ltr:peer-focus:-translate-y-[1.125rem] pointer-events-none',
				endIcon ? 'ms-9' : 'ms-0'
			]"
		>
			{{ label }}
		</label>
		<BaseIcon
			v-if="endIcon"
			:name="endIcon"
			class="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-900 duration-300 pointer-events-none group-focus-within:text-primary start-0 end-auto"
		/>
		<BaseIcon
			v-if="type === 'select'"
			name="arrow-down"
			class="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-600 duration-300 transform pointer-events-none group-focus-within:text-primary group-focus-within:rotate-180 start-auto end-0"
		/>
	</div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { type IconType } from "~/types/base-icons"

const emit = defineEmits(["update:modelValue", "input", "change"])
const props = withDefaults(
	defineProps<{
		label?: string
		modelValue?: any
		type?: "text" | "number" | "select" | "date" | "email" | "password" | "time" | "textarea"
		step?: number
		endIcon?: IconType
		options?: any[]
		optionValue?: any
		optionName?: any
		required?: boolean
	}>(),
	{
		type: "text"
	}
)

const dateValue = computed(() => {
	if (props.type === "date" && props.modelValue) {
		return dayjs(props.modelValue).format("YYYY-MM-DD")
	} else {
		return null
	}
})

const changeEventValue = (e: any) => {
	const v = e.target.value
	emit("update:modelValue", v)
	emit("change", v)
	emit("input", e)
}

const changeValue = (v: any) => {
	emit("update:modelValue", v)
	emit("change", v)
}
</script>
