<template>
	<div>
		<div
			class="relative flex flex-col overflow-hidden bg-white border border-grey-300 rounded-xl"
			:class="{}"
			@click="emit('click')"
			v-auto-animate
		>
			<div class="flex flex-col p-5">
				<div class="flex items-center mb-5">
					<div
						class="flex items-center rounded-md bg-opacity-10 p-0.5 duration-300"
						:class="{
							[status.bgClass]: true,
							'cursor-pointer hover:bg-opacity-20': admin
						}"
						@click="emit('status')"
					>
						<BaseIcon
							name="bag"
							class="w-5 h-5 text-white me-1.5 rounded-md p-0.5"
							:class="{ [status.bgClass]: true }"
						/>
						<span class="text-xs font-semibold me-1" :class="[status.txtClass]">
							{{ status.name || "~" }}
						</span>
						<Icon v-if="admin" name="mdi:pencil" class="w-5 h-5 ms-1.5 p-0.5" :class="[status.txtClass]" />
					</div>
					<div class="flex-grow" />
					<BaseIcon
						v-if="!detailed || admin"
						name="arrow-left"
						:class="[
							'w-8 h-8 p-1 transform rounded-full cursor-pointer hover:text-slate-500 bg-s duration-300 bg-opacity-0 hover:bg-opacity-10',
							detailed ? '-rotate-90' : '-rotate-180'
						]"
						@click="detailed ? emit('less') : emit('more')"
					/>
				</div>
				<div class="flex flex-wrap items-center -mb-2 -me-3">
					<div class="flex items-center mb-2 me-3">
						<span class="text-sm font-medium text-slate-400">
							{{ createdString }}
						</span>
					</div>
					<div class="flex flex-wrap items-center mb-2 me-3">
						<span class="w-1 h-1 rounded-full opacity-25 me-3 bg-slate-400" />
						<span class="text-xs text-slate-400 me-1.5 whitespace-nowrap">
							{{ $t("order_code") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.id }}
						</span>
					</div>
					<div class="flex items-center mb-2 me-3">
						<span class="w-1 h-1 rounded-full opacity-25 me-3 bg-slate-400" />
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("amount") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark me-1">
							{{ "$" + (totalPrice || 0) }}
						</span>
					</div>
				</div>
			</div>
			<div v-if="detailed" class="flex flex-col">
				<div class="mx-5 border-b border-gray-200" />
				<div class="grid grid-cols-1 gap-5 p-5 sm:grid-cols-2">
					<div class="flex items-center">
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("email") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.email || "~" }}
						</span>
					</div>
					<div class="flex items-center">
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("phone_number") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.mobile || "~" }}
						</span>
					</div>
				</div>
			</div>
			<div v-if="detailed" class="flex flex-col">
				<div class="mx-5 border-b border-gray-200" />
				<div class="grid grid-cols-1 gap-5 p-5 sm:grid-cols-2">
					<div class="flex items-center">
						<span class="text-xs text-slate-400 me-1.5">
							{{ $t("address") }}
						</span>
						<span class="text-sm font-bold text-natural-semidark">
							{{ data?.address || "~" }}
						</span>
					</div>
				</div>
			</div>
			<div class="bg-gray-200 border-b" />
			<div v-if="detailed" class="flex flex-col">
				<div class="grid grid-cols-1 gap-5 p-5 sm:grid-cols-2 lg:grid-cols-3">
					<NuxtLink v-for="(product, i) in products" :key="i" :to="$routeUrl.ShopProductUrl(product.id)">
						<ShopProductCard :product="product" :hide-button="true" />
					</NuxtLink>
				</div>
			</div>
			<div v-if="!detailed" class="flex flex-col">
				<div class="flex items-center p-1.5">
					<div v-for="(item, i) in data?.items" :key="i" class="p-1.5">
						<div class="relative w-16 h-16 bg-slate-100 rounded-xl">
							<img
								v-if="item.image"
								:src="item.image"
								class="absolute top-0 object-cover w-full h-full start-0 end-auto rounded-xl"
							/>
							<div
								v-else
								class="absolute top-0 flex items-center justify-center w-full h-full start-0 end-auto rounded-xl"
							>
								<BaseIcon name="dish" class="w-8 h-8 text-gray-300" />
							</div>
							<div
								class="absolute top-0 left-0 w-full h-full border-2 border-opacity-25 shadow-inner pointer-events-none rounded-xl border-slate-400"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"
import { type ShopOrder } from "~/models/shop-order"

const runtimeConfig = useRuntimeConfig()
const shopStore = useShopStore()
const { $stringUtil } = useNuxtApp()

const emit = defineEmits(["click", "cancel", "more", "less", "status"])
const props = defineProps<{
	data?: ShopOrder
	detailed?: boolean
	admin?: boolean
}>()

const orderStatuses = computed(() => shopStore.statuses)

const status = computed(() => orderStatuses.value.find((v) => v.value === props.data?.status) || orderStatuses.value[0])

const products = computed(() => props.data?.items)

const subTotalPrice = computed(
	() => products.value?.map((p) => p.unit_price * (p.quantity || 0))?.reduce((a, b) => a + b, 0) || 0
)

const taxPrice = computed(() => 0)

const shippingPrice = computed(() => 0)

const totalPrice = computed(() => subTotalPrice.value + taxPrice.value + shippingPrice.value)

const createdString = computed(() => dayjs(props.data?.created_at, "YYYY/MM/DD").format("DD MMMM YYYY"))
</script>
