import { useLocalStorage } from "@vueuse/core";
import type { ChatCompletionRequestMessage } from "openai";
import { defineStore } from "pinia";
import Bumblebee from "bumblebee-hotword"

export const useVoiceAssistantStore = defineStore("voice-assistant", {
	state: () => ({
		mode: "off" as AssistantMode,
		pending: false as boolean,
		abortController: null as AbortController | null,
		todayFeelingScore: null as number | null,
		currentConversationId: "main" as string,
		currentNodeId: "" as string,
		isNodePicked: false as boolean,
		preserveAnswer: false as boolean,
		prevNodeId: "" as string,
		prevAnswer: "" as string,
		isListening: false as boolean,
		isLoaded: false as boolean,
		transcript: "" as string,
		answer: "" as string,
		writedAnswer: "" as string,
		isTourActive: false as boolean,
		isIntroduced: useLocalStorage<boolean>("voice_assistant_is_introduced", false),
		activeQuestionGroup: useLocalStorage<string>("voice_assistant_active_question_group", "onboarding"),
		sessionNodeRunTimes: {} as any,
		foreverNodeRunTimes: useLocalStorage<any>("voice_assistant_node_run_times", () => ({})),
		recorder: useRecorder(),
		bumblebee: useState("bumblebee", () => new Bumblebee()),
		flowConversations: {} as { [key: string]: ChatCompletionRequestMessage[] | undefined }
	})
})
