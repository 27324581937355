import IziToast from "izitoast"

import "izitoast/dist/css/iziToast.css"
import "izitoast/dist/css/iziToast.min.css"

export default defineNuxtPlugin(() => {
	IziToast.settings({
		resetOnHover: true,
		position: "bottomLeft",
		timeout: 5000
	})
	return {
		provide: {
			toast: IziToast
		}
	}
})
