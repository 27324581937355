import { defineStore } from "pinia"
import { type Database } from "~/types/supabase"

export type UserStatusType = {
	name: string
	value: string
	borderClass: string
	bgClass: string
	txtClass: string
}

export const useUserStore = defineStore("user", () => {
	const supabase = useSupabaseClient<Database>()
	const app = useNuxtApp()
	const user = useSupabaseUser()

	const state = {
		profile: ref<Database["public"]["Tables"]["profiles"]["Row"] | null>(null),
		types: ref([
			{
				name: app.$i18n.t("normal_user"),
				value: "user"
			},
			{
				name: app.$i18n.t("expert"),
				value: "expert"
			}
		] as UserStatusType[]),
		statuses: ref([
			{
				name: app.$i18n.t("enable"),
				value: "enable"
			},
			{
				name: app.$i18n.t("disable"),
				value: "disable"
			},
			{
				name: app.$i18n.t("block"),
				value: "block"
			}
		] as UserStatusType[]),
		googleScopes: [
			"email",
			"profile",
			"openid",
			"https://www.googleapis.com/auth/fitness.activity.read",
			// "https://www.googleapis.com/auth/fitness.blood_glucose.read",
			// "https://www.googleapis.com/auth/fitness.blood_pressure.read",
			"https://www.googleapis.com/auth/fitness.body.read",
			// "https://www.googleapis.com/auth/fitness.body_temperature.read",
			// "https://www.googleapis.com/auth/fitness.heart_rate.read",
			"https://www.googleapis.com/auth/fitness.location.read"
			// "https://www.googleapis.com/auth/fitness.nutrition.read",
			// "https://www.googleapis.com/auth/fitness.oxygen_saturation.read",
			// "https://www.googleapis.com/auth/fitness.reproductive_health.read",
			// "https://www.googleapis.com/auth/fitness.sleep.read"
		]
	}

	const getProfile = async () => {
		if (user.value?.id) {
			if (user.value?.id != state.profile.value?.id) {
				const { error, data, status } = await useSupabaseUtil().refreshMiddleware(
					supabase.from("profiles").select().eq("id", user.value?.id).single()
				)
				if (error) {
					// @ts-ignore
					error.status = status
					throw error
				}
				state.profile.value = data
			}
		} else {
			state.profile.value = null
		}
		return state.profile.value
	}

	const editProfile = async (formData: { first_name?: string; last_name?: string }) => {
		if (user.value?.id) {
			const { error, data } = await supabase
				.from("profiles")
				.update({
					first_name: formData.first_name,
					last_name: formData.last_name
				})
				.eq("id", user.value?.id)
				.select()
				.single()
			if (error) throw error
			state.profile.value = data
			return data
		} else {
			return null
		}
	}

	const getUser = async (id: string) => {
		const { error, data } = await supabase.from("profiles").select().eq("id", id).single()
		if (error) throw error
		return data
	}

	const searchUsers = async (query: { page: number; limit: number; search?: string; type?: string }) => {
		const result = supabase
			.from("profiles")
			.select()
			.neq("type", "admin")
			.range(query.page - 1 * query.limit, query.page * query.limit)
		if (query.type) {
			result.eq("type", query.type)
		}
		if (query.search) {
			result.or(`first_name.like.%${query.search}%, email.eq.${query.search}`)
		}
		const { error, data, count } = await result
		if (error) throw error
		return {
			users: data as any[],
			total: count || 0
		}
	}

	const changeUserType = async (formData: { user_id: string; type: string }) => {
		const { error, data } = await supabase
			.from("profiles")
			.update({
				type: formData.type as any
			})
			.eq("id", formData.user_id)
			.single()
		if (error) throw error
		return data
	}

	return {
		...state,
		getUser,
		getProfile,
		editProfile,
		searchUsers,
		changeUserType
	}
})
