import { type RouteLocationRaw } from "vue-router"

export default defineNuxtPlugin(() => {
	const { $toast, $router } = useNuxtApp()

	const simplifyError = (e: any) => {
		return {
			message: e.response?._data?.message || e.error_description || e.message || "Unknown Error"
		}
	}

	return {
		provide: {
			routeUrl: useRouteUrl(),
			stringUtil: useStringUtil(),
			simplifyError,
			toastError: (e: any) => {
				const simplifiedError = simplifyError(e)
				if (process.client) {
					$toast.error({
						message: simplifiedError.message
					})
				}
			},
			backWithFallback: async (to: RouteLocationRaw) => {
				if (history.state.position > 1 && history.state.back?.startsWith("/")) {
					console.log(history.state.current)
					console.log("back")
					$router.back()
				} else {
					$router.replace(to)
				}
			}
		}
	}
})
