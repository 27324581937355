export const icons = [
	"add",
	"add-circle",
	"afternoon",
	"app-logo",
	"arrow-down",
	"arrow-left",
	"arrow-long-left",
	"bag",
	"bell",
	"breath",
	"camera",
	"chart",
	"check-circle",
	"close",
	"comment",
	"date-range",
	"dish",
	"dna",
	"edit-square",
	"email",
	"gear",
	"home",
	"location",
	"lock",
	"menu",
	"minus",
	"newspaper",
	"night",
	"pdf",
	"person",
	"phone",
	"plus",
	"recent-time",
	"running",
	"search",
	"send",
	"sound",
	"spoon",
	"stethoscope",
	"store",
	"sunrise",
	"sunset",
	"time",
	"voice",
	"watch"
] as const

export type IconType = (typeof icons)[number]