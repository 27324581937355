<template>
	<BaseModal :visible="visible" :title="$t('metagenome_test_details')" hide-footer size="lg" @close="emit('close')">
		<div class="grid grid-cols-1 gap-6 p-6">
			<table>
				<thead>
					<tr class="text-slate-900">
						<th class="pb-2 text-start">
							{{ $t("phylum") }}
						</th>
						<th class="pb-2 text-start">
							{{ $t("value") }}
						</th>
						<th class="pb-2 text-start">
							{{ $t("normal") }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(level, i) in metagenomePhylumKeys" :key="i" class="text-sm text-slate-800">
						<td>{{ level }}</td>
						<td>{{ order.taxonomic_levels?.Phylum[level]?.percent }}</td>
						<td>
							{{
								order.taxonomic_levels?.Phylum[level]?.normal_range?.min +
								"-" +
								order.taxonomic_levels?.Phylum[level]?.normal_range?.max
							}}
						</td>
					</tr>
				</tbody>
			</table>
			<span class="border-b border-slate-200" />
			<table>
				<thead>
					<tr class="text-slate-900">
						<th class="pb-2 text-start">
							{{ $t("genus") }}
						</th>
						<th class="pb-2 text-start">
							{{ $t("value") }}
						</th>
						<th class="pb-2 text-start">
							{{ $t("normal") }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(level, i) in metagenomeGenusKeys" :key="i" class="text-sm text-slate-800">
						<td>{{ level }}</td>
						<td>{{ order.taxonomic_levels?.Genus[level]?.percent }}</td>
						<td>
							{{
								order.taxonomic_levels?.Genus[level]?.normal_range?.min +
								"-" +
								order.taxonomic_levels?.Genus[level]?.normal_range?.max
							}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</BaseModal>
</template>

<script setup lang="ts">
import type { GenomeTestOrder } from "~/models/genome-test-order"

const emit = defineEmits<{
	close: []
}>()

const props = defineProps<{
	visible: boolean
	order: GenomeTestOrder
}>()

const metagenomePhylumKeys = computed<string[]>(() => Object.keys(props.order.taxonomic_levels?.Phylum || []))
const metagenomeGenusKeys = computed<string[]>(() => Object.keys(props.order.taxonomic_levels?.Genus || []))
</script>
