<template>
	<div
		:class="[
			'fixed w-full h-full bg-black bg-opacity-75 z-[20] duration-300 flex flex-col',
			voiceAssistantStore.mode !== 'off' ? 'opacity-100' : 'opacity-0 pointer-events-none select-none'
		]"
	>
		<div
			class="absolute start-0 top-0 end-0 bg-gradient-to-b from-[#00000090] to-transparent h-[120px] pointer-events-none z-[0]"
		/>
		<div class="h-16" />
		<span
			v-if="voiceAssistant.currentNode.value?.node?.question?.title"
			class="self-center px-4 py-2 my-auto mb-8 text-base font-bold text-center text-white border-2 border-white rounded-full cursor-pointer z-[1] mt-8"
			@click="voiceAssistant.skipQuestion"
		>
			{{ $t("skip_question") }}
		</span>
		<span
			v-if="voiceAssistant.currentNode.value?.node?.question?.title"
			class="px-12 pb-16 text-2xl font-bold text-center text-white z-[1]"
		>
			{{ voiceAssistant.currentNode.value?.node?.question.title }}
		</span>
		<span
			v-if="voiceAssistant.currentNode.value?.node?.question?.title && voiceAssistantStore.transcript"
			class="px-12 text-xl font-medium text-center text-blue-200 z-[1]"
		>
			{{ voiceAssistantStore.transcript }}
		</span>
		<div class="flex-grow my-6 z-[1]" />
		<form
			v-if="state.isTextInputVisible"
			@submit.prevent="onTextSubmit"
			class="flex-shrink-0 bg-white mx-8 rounded-lg p-4 shadow-lg"
		>
			<BaseTextInput type="text" v-model="state.text" class="" label="Text" />
		</form>
		<div v-if="state.isTextInputVisible" class="flex-grow my-6 z-[1]" />
		<div
			:class="[
				'relative flex items-center justify-center px-6',
				voiceAssistantStore.mode !== 'off' ? 'pb-20' : 'pb-12'
			]"
		>
			<span :class="['flex-shrink-0 flex items-center justify-center relative duration-300']">
				<span
					v-if="voiceAssistantStore.mode !== 'off'"
					:class="[
						'absolute rounded-full w-[72px] h-[72px] animate-ping',
						voiceAssistantStore.mode === 'listen' && 'bg-red-500',
						voiceAssistantStore.mode === 'talk' && 'bg-blue-500'
					]"
				/>
				<span
					class=""
					:class="[
						'w-[72px] h-[72px] z-[1] cursor-pointer rounded-full flex items-center justify-center text-white origin-center',
						voiceAssistantStore.mode === 'off' && 'ripple-bg-primary',
						voiceAssistantStore.mode === 'listen' && 'bg-red-500',
						voiceAssistantStore.mode === 'talk' && 'bg-blue-500'
					]"
					@click="() => voiceButtionCLick()"
				>
					<BaseIcon
						v-if="!voiceAssistantStore.pending"
						:name="voiceAssistantStore.mode == 'talk' ? 'sound' : 'voice'"
						class="w-[44px] h-[44px]"
					/>
					<PulseLoader v-else size="13px" color="#ffffff" />
				</span>
			</span>
		</div>
	</div>
</template>

<script setup lang="ts">
// @ts-ignore
import PulseLoader from "vue-spinner/src/PulseLoader.vue"

const voiceAssistant = useVoiceAssistant()
const voiceAssistantStore = useVoiceAssistantStore()
const runtimeConfig = useRuntimeConfig()

const state = reactive({
	isTextInputVisible: false,
	text: ""
})

const keys = ref<string[]>([])

const questionGroups = computed(() => [
	...new Set(
		useQuestions()
			.value.map((question) => question.group)
			.filter((v) => v)
	)
])

const voiceButtionCLick = () => {
	if(!voiceAssistantStore.isLoaded) return
	if (voiceAssistantStore.mode === "off") {
		voiceAssistantStore.mode = "talk"
	} else if (voiceAssistantStore.mode === "listen") {
		voiceAssistantStore.mode = "off"
	} else if (voiceAssistantStore.mode === "talk") {
		voiceAssistantStore.mode = "off"
	}
}

voiceAssistant.beforeInit()

const onTextSubmit = () => {
	voiceAssistant.write(state.text)
	state.text = ""
}

const onKeyDown = (e: KeyboardEvent) => {
	keys.value = []
}

const onKeyUp = (e: KeyboardEvent) => {
	keys.value.push(e.key)
	if(!voiceAssistantStore.isLoaded) return
	if (
		keys.value.includes("Control") &&
		keys.value.includes("Meta") &&
		keys.value.includes("Shift") &&
		(keys.value.includes("w") || keys.value.includes("W"))
	) {
		state.isTextInputVisible = !state.isTextInputVisible
		if (voiceAssistantStore.mode === "off") {
			voiceAssistantStore.mode = "talk"
		}
	}
}

onMounted(() => {
	voiceAssistant.init()
	console.log(questionGroups.value)
	document.addEventListener("keyup", onKeyUp)
	document.addEventListener("keydown", onKeyDown)
})

onBeforeUnmount(() => {
	voiceAssistant.release()
	document.removeEventListener("keyup", onKeyUp)
	document.removeEventListener("keydown", onKeyDown)
})
</script>
