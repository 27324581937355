import { default as exercise_45trackeroNSwpvoTHyMeta } from "/home/cgdev/vivai-web/pages/activity/exercise-tracker.vue?macro=true";
import { default as meditation_45trackeruUPzkRdd4bMeta } from "/home/cgdev/vivai-web/pages/activity/meditation-tracker.vue?macro=true";
import { default as activityGhyqOTImLBMeta } from "/home/cgdev/vivai-web/pages/activity.vue?macro=true";
import { default as indextm4P1PxV44Meta } from "/home/cgdev/vivai-web/pages/admin/index.vue?macro=true";
import { default as loginsIpIZdtSEhMeta } from "/home/cgdev/vivai-web/pages/admin/login.vue?macro=true";
import { default as indexP0atX4ZIW6Meta } from "/home/cgdev/vivai-web/pages/admin/shop-orders/index.vue?macro=true";
import { default as _91page_93CMnJyFKgAOMeta } from "/home/cgdev/vivai-web/pages/admin/shop-orders/page/[page].vue?macro=true";
import { default as indexJDepRTfzFDMeta } from "/home/cgdev/vivai-web/pages/admin/test-requests/index.vue?macro=true";
import { default as _91page_93jeTOjq6QZRMeta } from "/home/cgdev/vivai-web/pages/admin/test-requests/page/[page].vue?macro=true";
import { default as indexubYdu3ipg6Meta } from "/home/cgdev/vivai-web/pages/admin/users/index.vue?macro=true";
import { default as _91page_93zWeMwR4ZrbMeta } from "/home/cgdev/vivai-web/pages/admin/users/page/[page].vue?macro=true";
import { default as confirmxwdZEBEIH7Meta } from "/home/cgdev/vivai-web/pages/auth/confirm.vue?macro=true";
import { default as loginJK08RYiPX2Meta } from "/home/cgdev/vivai-web/pages/auth/login.vue?macro=true";
import { default as registera84XUD6OcEMeta } from "/home/cgdev/vivai-web/pages/auth/register.vue?macro=true";
import { default as diet_45trackerkq5CXWHthVMeta } from "/home/cgdev/vivai-web/pages/diet-tracker.vue?macro=true";
import { default as dietSSO7ScxlM1Meta } from "/home/cgdev/vivai-web/pages/diet.vue?macro=true";
import { default as exercise_45dolmS8VL1GTjMeta } from "/home/cgdev/vivai-web/pages/exercise-do.vue?macro=true";
import { default as exerciseNcw2sv7VcbMeta } from "/home/cgdev/vivai-web/pages/exercise.vue?macro=true";
import { default as _91expertId_93X9OypdTO6OMeta } from "/home/cgdev/vivai-web/pages/experts/[expertId].vue?macro=true";
import { default as edit_45profile0hSqqAG7QzMeta } from "/home/cgdev/vivai-web/pages/experts/edit-profile.vue?macro=true";
import { default as indexfn13WSDWa5Meta } from "/home/cgdev/vivai-web/pages/experts/index.vue?macro=true";
import { default as searchaWO1TS04gbMeta } from "/home/cgdev/vivai-web/pages/experts/search.vue?macro=true";
import { default as homeKH70Ebi2EIMeta } from "/home/cgdev/vivai-web/pages/home.vue?macro=true";
import { default as indexZr303xVzKVMeta } from "/home/cgdev/vivai-web/pages/index.vue?macro=true";
import { default as insightJvMPWAAtNuMeta } from "/home/cgdev/vivai-web/pages/insight.vue?macro=true";
import { default as integration0ZvMTJsVORMeta } from "/home/cgdev/vivai-web/pages/integration.vue?macro=true";
import { default as addJCDBfSEHMRMeta } from "/home/cgdev/vivai-web/pages/manage-blood-tests/add.vue?macro=true";
import { default as indexHBlXjDMsxjMeta } from "/home/cgdev/vivai-web/pages/manage-blood-tests/index.vue?macro=true";
import { default as addcmclwswXLfMeta } from "/home/cgdev/vivai-web/pages/manage-events/add.vue?macro=true";
import { default as addBSsKAlwdAxMeta } from "/home/cgdev/vivai-web/pages/manage-medicines/add.vue?macro=true";
import { default as index7rUt0TO4WJMeta } from "/home/cgdev/vivai-web/pages/manage-medicines/index.vue?macro=true";
import { default as manage_45testsvCM2RUOiNkMeta } from "/home/cgdev/vivai-web/pages/manage-tests.vue?macro=true";
import { default as meditationZlw83q9WmsMeta } from "/home/cgdev/vivai-web/pages/meditation.vue?macro=true";
import { default as notificationsqRLlotucnMMeta } from "/home/cgdev/vivai-web/pages/notifications.vue?macro=true";
import { default as onboarding7VMdQpoA1aMeta } from "/home/cgdev/vivai-web/pages/onboarding.vue?macro=true";
import { default as _91_46_46_46slug_93HbQpTRRxETMeta } from "/home/cgdev/vivai-web/pages/page/[...slug].vue?macro=true";
import { default as pose_45testbXw8ab7OmLMeta } from "/home/cgdev/vivai-web/pages/pose-test.vue?macro=true";
import { default as profileeO6KslGfD0Meta } from "/home/cgdev/vivai-web/pages/profile.vue?macro=true";
import { default as request_45new_45testygHVWKhar6Meta } from "/home/cgdev/vivai-web/pages/request-new-test.vue?macro=true";
import { default as cartyImdjhbkPuMeta } from "/home/cgdev/vivai-web/pages/shop/cart.vue?macro=true";
import { default as indexsxkCDcRobDMeta } from "/home/cgdev/vivai-web/pages/shop/index.vue?macro=true";
import { default as order_45confirmationi66EaOaIDoMeta } from "/home/cgdev/vivai-web/pages/shop/order-confirmation.vue?macro=true";
import { default as order_45doneiihAY0pK65Meta } from "/home/cgdev/vivai-web/pages/shop/order-done.vue?macro=true";
import { default as orderssm7m4T7z0TMeta } from "/home/cgdev/vivai-web/pages/shop/orders.vue?macro=true";
import { default as paymenttiL6Vdu56zMeta } from "/home/cgdev/vivai-web/pages/shop/payment.vue?macro=true";
import { default as _91productId_93G9bl1DHCQ5Meta } from "/home/cgdev/vivai-web/pages/shop/product/[productId].vue?macro=true";
import { default as indexuStpwewelpMeta } from "/home/cgdev/vivai-web/pages/test/index.vue?macro=true";
import { default as vivaioVWzQtLx4ZMeta } from "/home/cgdev/vivai-web/pages/vivai.vue?macro=true";
import { default as workoutzE8TRumXUXMeta } from "/home/cgdev/vivai-web/pages/workout.vue?macro=true";
export default [
  {
    name: activityGhyqOTImLBMeta?.name ?? "activity",
    path: activityGhyqOTImLBMeta?.path ?? "/activity",
    meta: activityGhyqOTImLBMeta || {},
    alias: activityGhyqOTImLBMeta?.alias || [],
    redirect: activityGhyqOTImLBMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/activity.vue").then(m => m.default || m),
    children: [
  {
    name: exercise_45trackeroNSwpvoTHyMeta?.name ?? "activity-exercise-tracker",
    path: exercise_45trackeroNSwpvoTHyMeta?.path ?? "exercise-tracker",
    meta: exercise_45trackeroNSwpvoTHyMeta || {},
    alias: exercise_45trackeroNSwpvoTHyMeta?.alias || [],
    redirect: exercise_45trackeroNSwpvoTHyMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/activity/exercise-tracker.vue").then(m => m.default || m)
  },
  {
    name: meditation_45trackeruUPzkRdd4bMeta?.name ?? "activity-meditation-tracker",
    path: meditation_45trackeruUPzkRdd4bMeta?.path ?? "meditation-tracker",
    meta: meditation_45trackeruUPzkRdd4bMeta || {},
    alias: meditation_45trackeruUPzkRdd4bMeta?.alias || [],
    redirect: meditation_45trackeruUPzkRdd4bMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/activity/meditation-tracker.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indextm4P1PxV44Meta?.name ?? "admin",
    path: indextm4P1PxV44Meta?.path ?? "/admin",
    meta: indextm4P1PxV44Meta || {},
    alias: indextm4P1PxV44Meta?.alias || [],
    redirect: indextm4P1PxV44Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: loginsIpIZdtSEhMeta?.name ?? "admin-login",
    path: loginsIpIZdtSEhMeta?.path ?? "/admin/login",
    meta: loginsIpIZdtSEhMeta || {},
    alias: loginsIpIZdtSEhMeta?.alias || [],
    redirect: loginsIpIZdtSEhMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: indexP0atX4ZIW6Meta?.name ?? "admin-shop-orders",
    path: indexP0atX4ZIW6Meta?.path ?? "/admin/shop-orders",
    meta: indexP0atX4ZIW6Meta || {},
    alias: indexP0atX4ZIW6Meta?.alias || [],
    redirect: indexP0atX4ZIW6Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/admin/shop-orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93CMnJyFKgAOMeta?.name ?? "admin-shop-orders-page-page",
    path: _91page_93CMnJyFKgAOMeta?.path ?? "/admin/shop-orders/page/:page()",
    meta: _91page_93CMnJyFKgAOMeta || {},
    alias: _91page_93CMnJyFKgAOMeta?.alias || [],
    redirect: _91page_93CMnJyFKgAOMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/admin/shop-orders/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexJDepRTfzFDMeta?.name ?? "admin-test-requests",
    path: indexJDepRTfzFDMeta?.path ?? "/admin/test-requests",
    meta: indexJDepRTfzFDMeta || {},
    alias: indexJDepRTfzFDMeta?.alias || [],
    redirect: indexJDepRTfzFDMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/admin/test-requests/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93jeTOjq6QZRMeta?.name ?? "admin-test-requests-page-page",
    path: _91page_93jeTOjq6QZRMeta?.path ?? "/admin/test-requests/page/:page()",
    meta: _91page_93jeTOjq6QZRMeta || {},
    alias: _91page_93jeTOjq6QZRMeta?.alias || [],
    redirect: _91page_93jeTOjq6QZRMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/admin/test-requests/page/[page].vue").then(m => m.default || m)
  },
  {
    name: indexubYdu3ipg6Meta?.name ?? "admin-users",
    path: indexubYdu3ipg6Meta?.path ?? "/admin/users",
    meta: indexubYdu3ipg6Meta || {},
    alias: indexubYdu3ipg6Meta?.alias || [],
    redirect: indexubYdu3ipg6Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91page_93zWeMwR4ZrbMeta?.name ?? "admin-users-page-page",
    path: _91page_93zWeMwR4ZrbMeta?.path ?? "/admin/users/page/:page()",
    meta: _91page_93zWeMwR4ZrbMeta || {},
    alias: _91page_93zWeMwR4ZrbMeta?.alias || [],
    redirect: _91page_93zWeMwR4ZrbMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/admin/users/page/[page].vue").then(m => m.default || m)
  },
  {
    name: confirmxwdZEBEIH7Meta?.name ?? "auth-confirm",
    path: confirmxwdZEBEIH7Meta?.path ?? "/auth/confirm",
    meta: confirmxwdZEBEIH7Meta || {},
    alias: confirmxwdZEBEIH7Meta?.alias || [],
    redirect: confirmxwdZEBEIH7Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/auth/confirm.vue").then(m => m.default || m)
  },
  {
    name: loginJK08RYiPX2Meta?.name ?? "auth-login",
    path: loginJK08RYiPX2Meta?.path ?? "/auth/login",
    meta: loginJK08RYiPX2Meta || {},
    alias: loginJK08RYiPX2Meta?.alias || [],
    redirect: loginJK08RYiPX2Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: registera84XUD6OcEMeta?.name ?? "auth-register",
    path: registera84XUD6OcEMeta?.path ?? "/auth/register",
    meta: registera84XUD6OcEMeta || {},
    alias: registera84XUD6OcEMeta?.alias || [],
    redirect: registera84XUD6OcEMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: diet_45trackerkq5CXWHthVMeta?.name ?? "diet-tracker",
    path: diet_45trackerkq5CXWHthVMeta?.path ?? "/diet-tracker",
    meta: diet_45trackerkq5CXWHthVMeta || {},
    alias: diet_45trackerkq5CXWHthVMeta?.alias || [],
    redirect: diet_45trackerkq5CXWHthVMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/diet-tracker.vue").then(m => m.default || m)
  },
  {
    name: dietSSO7ScxlM1Meta?.name ?? "diet",
    path: dietSSO7ScxlM1Meta?.path ?? "/diet",
    meta: dietSSO7ScxlM1Meta || {},
    alias: dietSSO7ScxlM1Meta?.alias || [],
    redirect: dietSSO7ScxlM1Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/diet.vue").then(m => m.default || m)
  },
  {
    name: exercise_45dolmS8VL1GTjMeta?.name ?? "exercise-do",
    path: exercise_45dolmS8VL1GTjMeta?.path ?? "/exercise-do",
    meta: exercise_45dolmS8VL1GTjMeta || {},
    alias: exercise_45dolmS8VL1GTjMeta?.alias || [],
    redirect: exercise_45dolmS8VL1GTjMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/exercise-do.vue").then(m => m.default || m)
  },
  {
    name: exerciseNcw2sv7VcbMeta?.name ?? "exercise",
    path: exerciseNcw2sv7VcbMeta?.path ?? "/exercise",
    meta: exerciseNcw2sv7VcbMeta || {},
    alias: exerciseNcw2sv7VcbMeta?.alias || [],
    redirect: exerciseNcw2sv7VcbMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/exercise.vue").then(m => m.default || m)
  },
  {
    name: _91expertId_93X9OypdTO6OMeta?.name ?? "experts-expertId",
    path: _91expertId_93X9OypdTO6OMeta?.path ?? "/experts/:expertId()",
    meta: _91expertId_93X9OypdTO6OMeta || {},
    alias: _91expertId_93X9OypdTO6OMeta?.alias || [],
    redirect: _91expertId_93X9OypdTO6OMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/experts/[expertId].vue").then(m => m.default || m)
  },
  {
    name: edit_45profile0hSqqAG7QzMeta?.name ?? "experts-edit-profile",
    path: edit_45profile0hSqqAG7QzMeta?.path ?? "/experts/edit-profile",
    meta: edit_45profile0hSqqAG7QzMeta || {},
    alias: edit_45profile0hSqqAG7QzMeta?.alias || [],
    redirect: edit_45profile0hSqqAG7QzMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/experts/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: indexfn13WSDWa5Meta?.name ?? "experts",
    path: indexfn13WSDWa5Meta?.path ?? "/experts",
    meta: indexfn13WSDWa5Meta || {},
    alias: indexfn13WSDWa5Meta?.alias || [],
    redirect: indexfn13WSDWa5Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/experts/index.vue").then(m => m.default || m)
  },
  {
    name: searchaWO1TS04gbMeta?.name ?? "experts-search",
    path: searchaWO1TS04gbMeta?.path ?? "/experts/search",
    meta: searchaWO1TS04gbMeta || {},
    alias: searchaWO1TS04gbMeta?.alias || [],
    redirect: searchaWO1TS04gbMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/experts/search.vue").then(m => m.default || m)
  },
  {
    name: homeKH70Ebi2EIMeta?.name ?? "home",
    path: homeKH70Ebi2EIMeta?.path ?? "/home",
    meta: homeKH70Ebi2EIMeta || {},
    alias: homeKH70Ebi2EIMeta?.alias || [],
    redirect: homeKH70Ebi2EIMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexZr303xVzKVMeta?.name ?? "index",
    path: indexZr303xVzKVMeta?.path ?? "/",
    meta: indexZr303xVzKVMeta || {},
    alias: indexZr303xVzKVMeta?.alias || [],
    redirect: indexZr303xVzKVMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: insightJvMPWAAtNuMeta?.name ?? "insight",
    path: insightJvMPWAAtNuMeta?.path ?? "/insight",
    meta: insightJvMPWAAtNuMeta || {},
    alias: insightJvMPWAAtNuMeta?.alias || [],
    redirect: insightJvMPWAAtNuMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/insight.vue").then(m => m.default || m)
  },
  {
    name: integration0ZvMTJsVORMeta?.name ?? "integration",
    path: integration0ZvMTJsVORMeta?.path ?? "/integration",
    meta: integration0ZvMTJsVORMeta || {},
    alias: integration0ZvMTJsVORMeta?.alias || [],
    redirect: integration0ZvMTJsVORMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/integration.vue").then(m => m.default || m)
  },
  {
    name: addJCDBfSEHMRMeta?.name ?? "manage-blood-tests-add",
    path: addJCDBfSEHMRMeta?.path ?? "/manage-blood-tests/add",
    meta: addJCDBfSEHMRMeta || {},
    alias: addJCDBfSEHMRMeta?.alias || [],
    redirect: addJCDBfSEHMRMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/manage-blood-tests/add.vue").then(m => m.default || m)
  },
  {
    name: indexHBlXjDMsxjMeta?.name ?? "manage-blood-tests",
    path: indexHBlXjDMsxjMeta?.path ?? "/manage-blood-tests",
    meta: indexHBlXjDMsxjMeta || {},
    alias: indexHBlXjDMsxjMeta?.alias || [],
    redirect: indexHBlXjDMsxjMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/manage-blood-tests/index.vue").then(m => m.default || m)
  },
  {
    name: addcmclwswXLfMeta?.name ?? "manage-events-add",
    path: addcmclwswXLfMeta?.path ?? "/manage-events/add",
    meta: addcmclwswXLfMeta || {},
    alias: addcmclwswXLfMeta?.alias || [],
    redirect: addcmclwswXLfMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/manage-events/add.vue").then(m => m.default || m)
  },
  {
    name: addBSsKAlwdAxMeta?.name ?? "manage-medicines-add",
    path: addBSsKAlwdAxMeta?.path ?? "/manage-medicines/add",
    meta: addBSsKAlwdAxMeta || {},
    alias: addBSsKAlwdAxMeta?.alias || [],
    redirect: addBSsKAlwdAxMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/manage-medicines/add.vue").then(m => m.default || m)
  },
  {
    name: index7rUt0TO4WJMeta?.name ?? "manage-medicines",
    path: index7rUt0TO4WJMeta?.path ?? "/manage-medicines",
    meta: index7rUt0TO4WJMeta || {},
    alias: index7rUt0TO4WJMeta?.alias || [],
    redirect: index7rUt0TO4WJMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/manage-medicines/index.vue").then(m => m.default || m)
  },
  {
    name: manage_45testsvCM2RUOiNkMeta?.name ?? "manage-tests",
    path: manage_45testsvCM2RUOiNkMeta?.path ?? "/manage-tests",
    meta: manage_45testsvCM2RUOiNkMeta || {},
    alias: manage_45testsvCM2RUOiNkMeta?.alias || [],
    redirect: manage_45testsvCM2RUOiNkMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/manage-tests.vue").then(m => m.default || m)
  },
  {
    name: meditationZlw83q9WmsMeta?.name ?? "meditation",
    path: meditationZlw83q9WmsMeta?.path ?? "/meditation",
    meta: meditationZlw83q9WmsMeta || {},
    alias: meditationZlw83q9WmsMeta?.alias || [],
    redirect: meditationZlw83q9WmsMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/meditation.vue").then(m => m.default || m)
  },
  {
    name: notificationsqRLlotucnMMeta?.name ?? "notifications",
    path: notificationsqRLlotucnMMeta?.path ?? "/notifications",
    meta: notificationsqRLlotucnMMeta || {},
    alias: notificationsqRLlotucnMMeta?.alias || [],
    redirect: notificationsqRLlotucnMMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: onboarding7VMdQpoA1aMeta?.name ?? "onboarding",
    path: onboarding7VMdQpoA1aMeta?.path ?? "/onboarding",
    meta: onboarding7VMdQpoA1aMeta || {},
    alias: onboarding7VMdQpoA1aMeta?.alias || [],
    redirect: onboarding7VMdQpoA1aMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93HbQpTRRxETMeta?.name ?? "page-slug",
    path: _91_46_46_46slug_93HbQpTRRxETMeta?.path ?? "/page/:slug(.*)*",
    meta: _91_46_46_46slug_93HbQpTRRxETMeta || {},
    alias: _91_46_46_46slug_93HbQpTRRxETMeta?.alias || [],
    redirect: _91_46_46_46slug_93HbQpTRRxETMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/page/[...slug].vue").then(m => m.default || m)
  },
  {
    name: pose_45testbXw8ab7OmLMeta?.name ?? "pose-test",
    path: pose_45testbXw8ab7OmLMeta?.path ?? "/pose-test",
    meta: pose_45testbXw8ab7OmLMeta || {},
    alias: pose_45testbXw8ab7OmLMeta?.alias || [],
    redirect: pose_45testbXw8ab7OmLMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/pose-test.vue").then(m => m.default || m)
  },
  {
    name: profileeO6KslGfD0Meta?.name ?? "profile",
    path: profileeO6KslGfD0Meta?.path ?? "/profile",
    meta: profileeO6KslGfD0Meta || {},
    alias: profileeO6KslGfD0Meta?.alias || [],
    redirect: profileeO6KslGfD0Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: request_45new_45testygHVWKhar6Meta?.name ?? "request-new-test",
    path: request_45new_45testygHVWKhar6Meta?.path ?? "/request-new-test",
    meta: request_45new_45testygHVWKhar6Meta || {},
    alias: request_45new_45testygHVWKhar6Meta?.alias || [],
    redirect: request_45new_45testygHVWKhar6Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/request-new-test.vue").then(m => m.default || m)
  },
  {
    name: cartyImdjhbkPuMeta?.name ?? "shop-cart",
    path: cartyImdjhbkPuMeta?.path ?? "/shop/cart",
    meta: cartyImdjhbkPuMeta || {},
    alias: cartyImdjhbkPuMeta?.alias || [],
    redirect: cartyImdjhbkPuMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/shop/cart.vue").then(m => m.default || m)
  },
  {
    name: indexsxkCDcRobDMeta?.name ?? "shop",
    path: indexsxkCDcRobDMeta?.path ?? "/shop",
    meta: indexsxkCDcRobDMeta || {},
    alias: indexsxkCDcRobDMeta?.alias || [],
    redirect: indexsxkCDcRobDMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmationi66EaOaIDoMeta?.name ?? "shop-order-confirmation",
    path: order_45confirmationi66EaOaIDoMeta?.path ?? "/shop/order-confirmation",
    meta: order_45confirmationi66EaOaIDoMeta || {},
    alias: order_45confirmationi66EaOaIDoMeta?.alias || [],
    redirect: order_45confirmationi66EaOaIDoMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/shop/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: order_45doneiihAY0pK65Meta?.name ?? "shop-order-done",
    path: order_45doneiihAY0pK65Meta?.path ?? "/shop/order-done",
    meta: order_45doneiihAY0pK65Meta || {},
    alias: order_45doneiihAY0pK65Meta?.alias || [],
    redirect: order_45doneiihAY0pK65Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/shop/order-done.vue").then(m => m.default || m)
  },
  {
    name: orderssm7m4T7z0TMeta?.name ?? "shop-orders",
    path: orderssm7m4T7z0TMeta?.path ?? "/shop/orders",
    meta: orderssm7m4T7z0TMeta || {},
    alias: orderssm7m4T7z0TMeta?.alias || [],
    redirect: orderssm7m4T7z0TMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/shop/orders.vue").then(m => m.default || m)
  },
  {
    name: paymenttiL6Vdu56zMeta?.name ?? "shop-payment",
    path: paymenttiL6Vdu56zMeta?.path ?? "/shop/payment",
    meta: paymenttiL6Vdu56zMeta || {},
    alias: paymenttiL6Vdu56zMeta?.alias || [],
    redirect: paymenttiL6Vdu56zMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/shop/payment.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93G9bl1DHCQ5Meta?.name ?? "shop-product-productId",
    path: _91productId_93G9bl1DHCQ5Meta?.path ?? "/shop/product/:productId()",
    meta: _91productId_93G9bl1DHCQ5Meta || {},
    alias: _91productId_93G9bl1DHCQ5Meta?.alias || [],
    redirect: _91productId_93G9bl1DHCQ5Meta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/shop/product/[productId].vue").then(m => m.default || m)
  },
  {
    name: indexuStpwewelpMeta?.name ?? "test",
    path: indexuStpwewelpMeta?.path ?? "/test",
    meta: indexuStpwewelpMeta || {},
    alias: indexuStpwewelpMeta?.alias || [],
    redirect: indexuStpwewelpMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: vivaioVWzQtLx4ZMeta?.name ?? "vivai",
    path: vivaioVWzQtLx4ZMeta?.path ?? "/vivai",
    meta: vivaioVWzQtLx4ZMeta || {},
    alias: vivaioVWzQtLx4ZMeta?.alias || [],
    redirect: vivaioVWzQtLx4ZMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/vivai.vue").then(m => m.default || m)
  },
  {
    name: workoutzE8TRumXUXMeta?.name ?? "workout",
    path: workoutzE8TRumXUXMeta?.path ?? "/workout",
    meta: workoutzE8TRumXUXMeta || {},
    alias: workoutzE8TRumXUXMeta?.alias || [],
    redirect: workoutzE8TRumXUXMeta?.redirect,
    component: () => import("/home/cgdev/vivai-web/pages/workout.vue").then(m => m.default || m)
  }
]